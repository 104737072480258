import React, { useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrowLeft.svg';
import { useEffect } from 'react';
import './Calender.css';
let d = new Date().getTime() / 1000;

export default function Calender({ pickedDates = [d], style = {} }) {
  const [yr, setYr] = useState(new Date().getFullYear());
  const [mn, setMn] = useState(new Date().getMonth());
  const [dataToArr, updateDataToArr] = useState([]);

  useEffect(() => {
    const tempDates = new Map();
    const temp_prevMLDate = new Date(yr, mn, 0).getDate();
    const prevMLDate = JSON.parse(temp_prevMLDate);
    const temp_curMFDay = new Date(yr, mn, 1).getDay();
    const curMFDay = JSON.parse(temp_curMFDay);
    const temp_curMLDate = new Date(yr, mn + 1, 0).getDate();
    const curMLDate = JSON.parse(temp_curMLDate);

    for (let i = 0; i < curMFDay; i++) {
      const d = prevMLDate - curMFDay + i + 1;
      let key = `${yr}-${mn - 1}-${d}`;
      tempDates.set(key, { year: yr, month: mn - 1, date: d, today: false });
    }

    for (let i = curMFDay; i < curMLDate + curMFDay; i++) {
      const d = i - curMFDay + 1;
      let key = `${yr}-${mn}-${d}`;
      tempDates.set(key, { year: yr, month: mn, date: d, today: false });
    }

    for (let i = curMLDate + curMFDay; i < 42; i++) {
      const d = i - (curMLDate + curMFDay) + 1;
      let key = `${yr}-${mn + 1}-${d}`;
      tempDates.set(key, { year: yr, month: mn + 1, date: d, today: false });
    }

    pickedDates.forEach((it, i) => {
      const today = new Date(it * 1000);
      const t_yr = today.getFullYear();
      const t_mn = today.getMonth();
      const t_date = today.getDate();
      const t_key = `${t_yr}-${t_mn}-${t_date}`;
      const preV = tempDates.get(t_key);
      tempDates.set(t_key, { ...preV, today: true });
    });

    const tempArr = [];
    for (const [key, value] of tempDates) {
      tempArr.push({ key: key, value: value });
    }
    updateDataToArr(tempArr);
  }, [yr, mn, pickedDates]);

  const handleMonthIncrement = () => {
    setMn((mn + 1) % 12);
    if (mn + 1 >= 12) {
      setYr(yr + 1);
    }
  };

  const handleMonthDecrement = () => {
    setMn(mn - 1 < 0 ? 11 : mn - 1);
    if (mn - 1 < 0) {
      setYr(yr - 1);
    }
  };

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return (
    <div style={{ width: style.width || '100%', height: style.height || '100%', margin: 'auto' }} className='custom-calender '>
      <h2 className='text-xl text-center opacity-80'>Maintenance Calender</h2>
      <div className='m-2 flex items-center justify-between'>
        <LeftArrow onClick={handleMonthDecrement} className='w-5 h-5  [&>path]:stroke-[#AEA9CF] cursor-pointer' />
        <p className='opacity-50 text-lg font-medium'>
          {months[mn]?.toUpperCase()}, {yr}
        </p>
        <LeftArrow onClick={handleMonthIncrement} className='w-5 h-8  [&>path]:stroke-[#AEA9CF] cursor-pointer transform rotate-180' />
      </div>
      <table className='mx-auto customTable border-separate border-spacing-1'>
        <thead className='text-[#656278] dark:text-[#46B9AB]'>
          <tr>
            {days.map((th, i) => (
              <th key={i}>{days[i].substring(0, 3)}</th>
            ))}
          </tr>
        </thead>
        <tbody className='text-center text-[#908CAB] dark:text-[#E8E8E8]'>
          <tr>
            {dataToArr?.slice(0, 7).map((it, i) => (
              <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
            ))}
          </tr>
          <tr>
            {dataToArr?.slice(7, 14).map((it, i) => (
              <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
            ))}
          </tr>
          <tr>
            {dataToArr?.slice(14, 21).map((it, i) => (
              <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
            ))}
          </tr>
          <tr>
            {dataToArr?.slice(21, 28).map((it, i) => (
              <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
            ))}
          </tr>
          <tr>
            {dataToArr?.slice(28, 35).map((it, i) => (
              <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
            ))}
          </tr>
          {dataToArr.slice(35, 42).length > 0 && (
            <tr>
              {dataToArr?.slice(35, 42).map((it, i) => (
                <td key={i}>{it.value.month !== mn ? <p className='opacity-50'>{it.value.date}</p> : <p className={`${it.value.today ? 'mx-auto w-8 h-8 centerXY rounded-full bg-[#a6c26e40] text-[#45DE82]' : ''}`}>{it.value.date}</p>}</td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
