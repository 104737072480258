import { createSlice } from '@reduxjs/toolkit';

const generatorModuleSlice = createSlice({
  name: 'generatorModule',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    generatorModuleInit: (state) => {
      return { ...state, isLoading: true };
    },
    generatorModuleSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    generatorModuleFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    generatorModuleDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    generatorModuleDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    generatorModuleDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { generatorModuleInit, generatorModuleSuccess, generatorModuleFail, generatorModuleDelInit, generatorModuleDelSuccess, generatorModuleDelFail } = generatorModuleSlice.actions;
export default generatorModuleSlice.reducer;
