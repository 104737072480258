import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Star from '../../../../../components/common/star';
import { useApiSlice } from '../../../../../hooks/hooks';
import DashBoardCard from '../../../../../components/common/cards/home-card';

export default function DetailSummaryFuel() {
  const params = useParams();
  const { getItems: getSummary, result } = useApiSlice({ page: 0, per_page: 2000, pg_number: params.pg_number });

  const { fuel_percentage = 0, fuel_consumed = 0, fuel_ltr = 0, last_filled = 0 } = result.data || {};

  useEffect(() => {
    getSummary({ endPoint: 'v2/device-data/latest-single-item' });
  }, [getSummary]);

  return (
    <div className='p-6 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
      <DashBoardCard className='aspect-[1/.6]'>
        <Star className='ml-0'>
          <h2>Fuel Tank</h2>
        </Star>
        <p className='position-center'>{params.pg_number || ''}</p>
      </DashBoardCard>

      <DashBoardCard className='aspect-[1/.6]'>
        <Star className='ml-0'>
          <h2>Current Fuel Level</h2>
        </Star>
        <div className='position-center text-center'>
          <p className='text-xl'>{fuel_ltr} Ltr</p>
          <p className='font-normal text-primary-600'>({fuel_percentage} %)</p>
        </div>
      </DashBoardCard>

      <DashBoardCard className='aspect-[1/.6]'>
        <Star className='ml-0'>
          <h2>Last Fuel Consumed</h2>
        </Star>
        <p className='position-center'>{fuel_consumed} (Ltr)</p>
      </DashBoardCard>

      <DashBoardCard className='aspect-[1/.6]'>
        <Star className='ml-0'>
          <h2>Last Fuel Filled</h2>
        </Star>
        <p className='position-center'>{last_filled} (Ltr)</p>
      </DashBoardCard>
    </div>
  );
}
