import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CrossIcon } from './cross.svg';
import './modal.css';

export default function Modal({ children = null, onClose = () => {}, contentStyle = {}, contentClass = '' }) {
  // const handleResize = () => {
  //   if (document.documentElement.clientWidth > 1023) {
  //     onClose();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const portal = (
    <div className='modal bg-[#21242bf0] z-50 w-screen h-screen fixed left-0 top-0 overflow-y-auto'>
      <div role='button' className='header px-16 py-8'>
        <CrossIcon onClick={onClose} className='ml-auto fill-primary-200 w-5 cursor-pointer' />
        {/* <img onClick={onClose}  src={cross} alt='' width='20px' /> */}
      </div>
      <div style={contentStyle} className={`bg-[#333f54] text-white w-max h-max mx-auto rounded-sm ${contentClass}`}>
        {children}
      </div>
    </div>
  );
  return ReactDOM.createPortal(portal, document.getElementById('root'));
}
