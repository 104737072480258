import { createSlice } from '@reduxjs/toolkit';

const fuelEntrySlice = createSlice({
  name: 'fuelEntry',
  initialState: { isLoading: true, response: { data: [] || {}, pagination: {} }, isError: false },
  reducers: {
    fuelEntryInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelEntrySuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    fuelEntryFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    fuelEntryDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelEntryDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    fuelEntryDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { fuelEntryInit, fuelEntrySuccess, fuelEntryFail, fuelEntryDelInit, fuelEntryDelSuccess, fuelEntryDelFail } = fuelEntrySlice.actions;
export default fuelEntrySlice.reducer;
