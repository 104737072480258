import React from 'react';

export default function Circle({ percentage = 90, width = 100, strokeWidth = 4, stroke = 'green', strokeBar = 'red' }) {
  const validPercentage = percentage > 100 ? 100 : percentage;
  const radius = 0.5 * width - strokeWidth * 2;
  const strokeDasharray = Math.PI * 2 * radius;
  const strokeDashoffset = strokeDasharray * (validPercentage / 100);

  return (
    <svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='50%' cy='50%' r={radius} stroke={stroke} fill='transparent' strokeWidth={`${strokeWidth}px`} />
      <circle cx='50%' cy='50%' r={radius} stroke={strokeBar} fill='transparent' strokeWidth={`${strokeWidth}px`} strokeDasharray={`${strokeDasharray}`} strokeDashoffset={`${strokeDashoffset}`} />
    </svg>
  );
}
