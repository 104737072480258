import React from 'react';
import Button from '../../basic/button';
import { ReactComponent as DownArrow } from './downLine.svg';

export default function DownloadButton({ action = () => {}, className = 'h-8 w-max' }) {
  return (
    <Button onClick={action} className={`btn-success px-2 ${className}`}>
      <div className='flex items-center h-full'>
        <span>Download</span>
        <DownArrow className='w-5 h-5 [&>path]:stroke-primary-50 [&>path]:fill-primary-50' />
      </div>
    </Button>
  );
}
