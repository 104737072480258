import React from 'react';
import { parseDateTime } from '../../utils/utils';
import PopUp from '../common/popup/PopUp';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { postApi } from '../../apis/methods';

export function FuelAlarmCardNotify({ item = {} }) {
  const brColor = returnBorderColor(item.severity_level);

  const handleClickOnOption = (op) => {
    if (op.id === 1) {
      postApi({ body: { id: item.id, clear_flag: 1 }, url: 'alert/update' });
    } else if (op.id === 2) {
      postApi({ body: { id: item.id, acknowledge_flag: 1 }, url: 'alert/update' });
    }
  };

  return (
    <div className={`notify-item w-96 border-l-4 ${brColor} bg-[#a092b0] `}>
      <div className='content relative rounded-md  shadow-light dark:shadow-dark p-4'>
        <div className='mb-2 w-52 flex items-center justify-between'>
          <div className='text-sm'>
            <p>{parseDateTime(item.start_time).date || '-'}</p>
            <p className='text-xs font-normal opacity-70'>{parseDateTime(item.start_time).time || '-'}</p>
          </div>
          <span className='w-[1.3px] h-[30px] bg-primary-400'></span>
          <p className='text-sm'>
            <span>Generator:{item.pg_number || '-'}</span>
          </p>
        </div>
        <div className='absolute top-4 right-4'>
          <PopUp clickOnOption={handleClickOnOption} itemClass='border-b border-primary-400 text-sm py-1  last:border-0' options={actions} icon={() => <CrossIcon className='cursor-pointer w-3 fill-primary-600' />}></PopUp>
        </div>
        <div className=''>
          <p className='mb-2 text-sm opacity-70'>
            {item.message?.length > 90 ? <span className='font-semibold text-primary-700 dark:text-primary-50  cursor-pointer'>{item.message.slice(0, 90)}...'See more'</span> : <span>{item.message.slice(0, 90)}</span>}
          </p>
        </div>
      </div>
    </div>
  );
}

function returnBorderColor(type) {
  if (type === 'danger') return 'border-[#ff0000]';
  if (type === 'warning') return 'border-[#ffc107]';
  return 'border-[#50d71e]';
}
function returnBgColor(type) {
  if (type === 'danger') return 'bg-[#ff0000]';
  if (type === 'warning') return 'bg-[#ffc107]';
  return 'bg-[#50d71e]';
}

const actions = [
  { id: 1, title: 'Clear' },
  { id: 2, title: 'Acknowledge' },
];
