import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    alertInit: (state) => {
      return { ...state, isLoading: true };
    },
    alertSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    alertFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    alertDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    alertDelSuccess: (state, action) => {
      let delItems = action.payload.data || [];
      let dataAfterDel = [...state.response.data]?.filter((item) => !delItems.includes(item.id));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },
    alertDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { alertInit, alertSuccess, alertFail, alertDelInit, alertDelSuccess, alertDelFail } = alertSlice.actions;
export default alertSlice.reducer;
