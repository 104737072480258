import axios from 'axios';
import { store } from '../store';
import { baseUrl } from './credentials';
import credentials from './credentials';
import { notify } from '../components/basic/notify';
const location = window.location;

function debounce(fn = () => {}, delay = 200) {
  let timer;
  let queue = [];
  return (options = null) => {
    if (options) {
      queue.push(options);
    }
    if (timer) clearTimeout(timer);
    timer = setTimeout(async () => {
      let result = await fn();
      if (result.status) {
        queue.forEach(async (options) => {
          const { method, ...restOptions } = options;
          if (method === 'get') {
            await getApi(restOptions);
          } else if (method === 'post') {
            await postApi(restOptions);
          }
        });
        queue = [];
      } else {
        if (location.pathname !== '/login') {
          location.replace('/login');
        }
      }
    }, delay);
  };
}

const debounceTokenGenerate = debounce(newTokenGenerate);

async function newTokenGenerate() {
  const { refreshToken = null } = credentials.getTokens();
  if (!refreshToken) return { status: false };
  try {
    const response = await axios.post(`${baseUrl}/auth/user/token`, { refreshToken: refreshToken });
    credentials.setTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
    return { status: true };
  } catch (error) {
    console.log(error);
  }
  return { status: false };
}

const handleError = async (error = {}, requestedOptions = {}) => {
  if (error.response) {
    const { data, config } = error.response || {};
    if (data.error === 'Token expired') {
      await debounceTokenGenerate({ ...requestedOptions, method: config.method });
    } else if (data.error === 'Refresh token expired' || data.error === 'Refresh token does not exist!') {
      location.replace('/login');
    } else {
      // handle field error
      notify({ text: `${data.error || 'unknown error'}`, styles: { box: { top: '80%' } } });
    }
  } else if (error.request) {
    if (!window.navigator.onLine) {
      notify({ text: 'Connect Your Network', styles: { box: { top: '80%' } } });
    }
  } else {
    // store.dispatch(requestedOptions.fail({ data: { type: 'unknown', message: 'unknown error' } }));
  }
};

export async function getApi({ url = '', init = () => {}, success = () => {}, fail = () => {} }) {
  store.dispatch(init());
  try {
    const response = await axios.get(`${baseUrl}/${url}`, { headers: credentials.getHeaders() });
    if (response.status === 200) {
      const { status, message, ...properties } = response.data;
      store.dispatch(success({ data: properties }));
    }
  } catch (error) {
    store.dispatch(fail({ data: { type: 'error', message: 'data fetch error' } }));
    handleError(error, { url, init, success, fail });
  }
}

export async function postApi({ url = '', body = {} || [], resolve = () => {}, reject = () => {}, isAlert = true }) {
  try {
    const response = await axios.post(`${baseUrl}/${url}`, body, { headers: credentials.getHeaders() });
    if (response.status) {
      const result = response.data;
      if (result.message && isAlert) {
        notify({ text: result.message });
      }
      resolve(result);
    }
  } catch (error) {
    reject(error);
    handleError(error, { url, body, resolve, reject });
  }
}
