import { createSlice } from '@reduxjs/toolkit';

const dashboard = createSlice({
  name: 'dashboard',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    dashboardInit: (state) => {
      return { ...state, isLoading: true };
    },
    dashboardSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    dashboardFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { dashboardInit, dashboardSuccess, dashboardFail } = dashboard.actions;
export default dashboard.reducer;
