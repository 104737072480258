import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalize, getFormattedServiceHours, parseDateTime } from '../../../../../utils/utils';
import { useFetchFun } from '../../../../../hooks/hooks';
import { getFuelEntries } from '../../../../../apis/apis';
import Suspender from '../../../suspender/Suspender';
import NoData from '../../../no-data/NoData';

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='end' fill='#666' transform='rotate(-60)'>
        {payload.value}
      </text>
    </g>
  );
};

function ChartForFuelEntries({ type = 'normal', pg_number = null }) {
  const {
    isError,
    isLoading,
    response: { data: daily_data },
  } = useSelector((state) => state.fuels);
  useFetchFun(getFuelEntries, { page: 0, per_page: 1000, pg_number: pg_number });
  const [fuelEntries, updateFuelEntries] = useState([]);

  const data = [];
  fuelEntries?.forEach((it, i) => {
    let key = parseDateTime(it.fuel_entry_date, 'DD MMM YYYY').date;
    data.push({ ...it, date: key });
  });

  useEffect(() => {
    if (type === 'all') {
      updateFuelEntries(daily_data);
    } else {
      updateFuelEntries(() => daily_data?.filter((f) => f.entry_type === type));
    }
  }, [type, daily_data]);

  return (
    <Suspender isError={isError} isLoading={isLoading}>
      {data.length ? (
        <ResponsiveContainer aspect={1 / 0.6} className='text-xs pt-6 pb-2 px-6 !min-w-[260px] min-h-[200px] '>
          <BarChart
            width='100%'
            data={data}
            barGap={0}
            barSize={8}
            barCategoryGap={2}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis dataKey='date' interval={0} height={70} tick={CustomizedAxisTick} />

            <YAxis yAxisId='fuel_amount' dataKey='fuel_amount' orientation='left' domain={[0, (dataMax) => Math.ceil(dataMax * 1.5)]}>
              <Label value='Fuel Amount (Ltr)' angle={-90} className='stroke-[#38353552] dark:stroke-[#ffffffad] ' fontSize={10} />
            </YAxis>

            <Tooltip wrapperStyle={{ outline: 'none', border: 0 }} wrapperClassName='shadow-md' labelStyle={{ color: 'black', fontWeight: '500' }} formatter={formatter} />
            <Bar yAxisId='fuel_amount' dataKey='fuel_amount' fill='#51a9c9' />

            {/* <Legend /> */}
            {/* <Bar width='5px' dataKey='run_hour' fill='#ffc107' />
            <Bar width='10px' dataKey='fuel' fill='#008021' /> */}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </Suspender>
  );
}
export default React.memo(ChartForFuelEntries);

const formatter = (value, name, props) => {
  if (name === 'temp_run_hour') return [`${getFormattedServiceHours(props.payload.run_hour)}`, `Run hour`];
  if (name === 'temp_run_kwh') return [`${props.payload.run_kwh} kWh`, `Power consumed`];
  if (name === 'fuel_amount') return [`${props.payload.fuel_amount} Ltr`, `Fuel Amount`];
  if (name === 'expected_fuel') return [`${props.payload.expected_fuel} Ltr`, `Expected Fuel Consumed`];
  return [`${value}`, `${capitalize(name).split('_').join(' ')}`];
};
