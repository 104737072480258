import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import './style.css';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function ChartForArea({ options, children, headerClass = '', className = '', onClick = () => null }) {
  return (
    <div onClick={onClick} className={`chart-card-area relative ${className}`}>
      {children && children}
      <div className='chart-container w-full'>
        <ResponsiveContainer height={options.height || 100} width='100%'>
          <AreaChart data={options.data || data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }} className='mx-auto '>
            {/* <XAxis dataKey='name' /> */}
            {/* <YAxis dataKey='uv' /> */}
            <Tooltip />
            <Area stroke='none' type='linear' dataKey='uv' style={{ fill: 'inherit', stroke: 'inherit' }} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
