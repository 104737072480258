import React, { useState } from 'react';
import { ReactComponent as FuelIcon } from '../../../../assets/icons/fuelMeter.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/icons/group.svg';
import { ReactComponent as WaterIcon } from '../../../../assets/icons/waterIcon.svg';

import { useSelector } from 'react-redux';
import { summary_options } from './dependencies/constant';
import enone from '../../../../assets/images/energy.png';
import enotwo from '../../../../assets/images/spark.svg';
import runone from '../../../../assets/images/runtime.png';
import { ReactComponent as Runtwo } from '../../../../assets/images/clock.svg';
import { useFetchFun } from '../../../../hooks/hooks';
import { getDashboardSummary } from '../../../../apis/apis';
import DashBoardCard from '../../../../components/common/cards/home-card';
import Circle from '../../../../components/basic/progress/Circle';
import { getTimeRangeByAgo, parseTimeDuration } from '../../../../utils/utils';
import Star from '../../../../components/common/star';
import TotalGenerator from './dependencies/TotalGenerator';
import SummaryTable from './dependencies/SummaryTable';
import { optionsByHour } from '../../../../dataset';

export default function HomeSummary() {
  const [showTable, setShowTable] = useState(false);
  const [showAbleStr, setShowAbleStr] = useState(null);
  const {
    response: { summary = {} },
  } = useSelector((state) => state.dashboardSummary);
  const { total_generator = 0, running_generator = 0, stopped_generator = 0, fuel_consumed = 0, sum_duration = 0, energy_consumed = 0 } = summary;
  const { start_time, end_time } = getTimeRangeByAgo(optionsByHour[1].value);
  const [query, updateQuery, resetQuery] = useFetchFun(getDashboardSummary, { start_time, end_time }, true);

  const handleClickOnBox = (str) => {
    setShowAbleStr(str);
    if (!showAbleStr) {
      setShowTable(true);
    } else {
      setShowTable((state) => (str === showAbleStr ? !state : state));
    }
  };

  return (
    <>
      <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        <DashBoardCard onClick={() => handleClickOnBox(summary_options.total_generator)} className='cursor-pointer' item={{ icon: <GroupIcon className='fill-path' />, title: 'Total Generators' }}>
          <p className='position-center'>{total_generator}</p>
          <div className='position-center transform -rotate-90'>
            <Circle stroke='#428F2E' strokeBar='#818181' strokeWidth={10} percentage={(running_generator * 100) / total_generator} />
            <label className='text-[#428F2E] absolute -top-1/4 left-[40%] transform rotate-90' htmlFor=''>
              {running_generator}
            </label>

            <label className='text-[#818181] absolute top-full left-[42%] transform rotate-90' htmlFor=''>
              {stopped_generator}
            </label>
          </div>
          <ul className='absolute left-2 bottom-2 text-[10px]'>
            <p>
              <span className='w-2 h-2 mr-1 inline-block bg-[#428F2E]'></span>Running
            </p>
            <p>
              <span className='w-2 h-2 mr-1 inline-block bg-[#818181]'></span>Stopped
            </p>
          </ul>
        </DashBoardCard>
        <DashBoardCard onClick={() => handleClickOnBox(summary_options.sum_duration)}>
          <div className='flex h-full w-full items-center justify-between cursor-pointer'>
            <img src={runone} alt='img' className='w-8 h-8' />
            <p className='text-sm text-center leading-[26px]'>
              Total Run Hour <br /> <span className='text-[28px] font-bold'>{parseTimeDuration(sum_duration, ({ hour, min }) => `${hour}h : ${min}m`)}</span>
            </p>
            <Runtwo className='w-8 h-8 dark:fill-[#c0a03f]' />
          </div>
          <ThisMonth />
        </DashBoardCard>

        <DashBoardCard onClick={() => handleClickOnBox(summary_options.energy_consumed)}>
          <div className='flex h-full w-full items-center justify-between cursor-pointer'>
            <img src={enone} alt='images' className='w-8 h-8' />
            <p className='text-sm text-center leading-[26px]'>
              Total Energy <br /> <span className='text-[28px] font-bold'>{energy_consumed}</span>
              <br />
              (kwh)
            </p>
            <img src={enotwo} alt='images' className='w-8 h-8' />
          </div>
          <ThisMonth />
        </DashBoardCard>

        <DashBoardCard onClick={() => handleClickOnBox(summary_options.fuel_consumed)}>
          <div className='flex h-full w-full items-center justify-between cursor-pointer'>
            <FuelIcon className='w-8 h-8' />
            <div className='text-sm text-center leading-[26px]'>
              <Star label='Consumed fuel ' /> <span className='text-[28px] font-bold'>{fuel_consumed}</span>
              <br />
              (Ltr)
            </div>

            <WaterIcon className='w-8 h-8' />
          </div>
          <ThisMonth />
        </DashBoardCard>
      </div>

      {showTable && showAbleStr === summary_options.total_generator && (
        <div className='mb-4 absolute left-0 top-5 lg:mt-4 lg:top-0 custom-scroll max-h-screen custom-background lg:bg-inherit lg:relative'>
          <TotalGenerator updateQuery={updateQuery} />
        </div>
      )}

      {showTable && (showAbleStr === summary_options.sum_duration || showAbleStr === summary_options.energy_consumed || showAbleStr === summary_options.fuel_consumed) && (
        <div className='mb-4 absolute left-0 top-5 lg:mt-4 lg:top-0 custom-scroll max-h-screen max-w-[70%] lg:max-w-full custom-background lg:bg-inherit lg:relative'>
          <SummaryTable updateQuery={updateQuery} resetQuery={resetQuery} type={showAbleStr} query={query} />
        </div>
      )}
    </>
  );
}

const ThisMonth = () => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let mn = new Date().getMonth();
  return <p className='absolute right-2 top-2 text-xs text-secondary-500 dark:opacity-50'>{monthNames[mn]}</p>;
};
