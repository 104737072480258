import { createSlice } from '@reduxjs/toolkit';

const countriesSlice = createSlice({
  name: 'countries',
  initialState: { isLoading: true, response: { data: [] || {}, pagination: {} }, isError: false },
  reducers: {
    countriesInit: (state) => {
      return { ...state, isLoading: true };
    },
    countriesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    countriesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { countriesInit, countriesSuccess, countriesFail } = countriesSlice.actions;
export default countriesSlice.reducer;
