import { createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { isLoading: true, user: {}, isError: false, isAuthenticated: false },
  reducers: {
    userInfoInit: (state, action) => {
      return { ...state, isLoading: true };
    },
    userInfoSuccess: (state, action) => {
      return { ...state, isLoading: false, user: action.payload.data, isAuthenticated: true };
    },
    userInfoUpdate: (state, action) => {
      return { ...state, user: { ...state.user, ...action.payload.data } };
    },
    userInfoFail: (state, action) => {
      return { ...state, isLoading: false, user: {}, isError: action.payload.data, isAuthenticated: false };
    },
    logout: (state, action) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login');
    },
  },
});

export const { userInfoFail, userInfoInit, userInfoSuccess, userInfoUpdate, logout } = userInfoSlice.actions;

export default userInfoSlice.reducer;
