import React, { useState } from 'react';
import DailyChart from '../../../../../components/common/cards/chart-cards/daily-data/DailyChart';
import MonthlyChart from '../../../../../components/common/cards/chart-cards/monthly-data/MonthlyChart';
import { daily_chart, monthly_chart } from '../../../../../constrains';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter.svg';
import { useExpandedList, useQueryParm } from '../../../../../hooks/hooks';
import Modal from '../../../../../components/basic/Modals/Modal';
import { getApiReturn } from '../../../../../apis/apis';
import InputDateRange from '../../../../../components/basic/input-date-range/InputDateRange';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Suspender from '../../../../../components/common/suspender/Suspender';
import Star from '../../../../../components/common/star';

export default function DayMonthChart({ className = '', params = {}, fuel = false, power = false, runtime = false }) {
  const { pg_number = null } = params;
  const [expandedList, handleExpandedList] = useExpandedList(1);
  const [activeChart, setActiveChart] = useState(daily_chart);
  const [modalShow, setModalShow] = useState(false);
  const [query, updateQuery] = useQueryParm({ page: 0, per_page: 2000, pg_number: pg_number });
  const [dashboardData, setDashBoardData] = useState({ isLoading: true, isError: false, response: {} });
  const {
    isError,
    isLoading,
    response: { monthly_data = [], daily_data = [] },
  } = dashboardData;

  const getData = useCallback(async (params = {}) => {
    setDashBoardData((state) => ({ ...state, isLoading: true }));
    let response = await getApiReturn(`pg-stats-data/dashboard`, { ...params, data: `monthly_stats,daily_stats` });
    if (response.status) {
      setDashBoardData((state) => ({ ...state, response: response.data?.data, isLoading: false }));
    } else {
      setDashBoardData((state) => ({ ...state, isError: 'have a error', isLoading: false }));
    }
  }, []);

  useEffect(() => {
    getData(query);
  }, [getData, query]);

  const handleCustomDuration = ({ status = true, data: dateRange = {} }) => {
    const { start_time = '', end_time = '' } = dateRange;
    setModalShow(false);
    updateQuery({ ...query, start_time: new Date(start_time).getTime() / 1000, end_time: new Date(end_time).getTime() / 1000 });
  };

  return (
    <div className={`custom-shadow h-full rounded-lg bg-white dark:bg-primary-800 relative flex flex-col justify-end ${className}`}>
      <ul role='menubar' className='list-group absolute z-10 text-[11px] font-medium sm:left-4 left-24 items-start xs:items-center top-5 flex flex-col sm:flex-row gap-2'>
        {runtime && (
          <li role='menuitem' onClick={() => handleExpandedList(1)} className={`cursor-pointer ${expandedList.includes(1) ? 'opacity-100' : 'opacity-50'}`}>
            Runtime
          </li>
        )}
        {power && (
          <li role='menuitem' onClick={() => handleExpandedList(2)} className={`cursor-pointer ${expandedList.includes(2) ? 'opacity-100' : 'opacity-50'}`}>
            Power Generation
          </li>
        )}
        {fuel && (
          <li role='menuitem' onClick={() => handleExpandedList(3)} className={`cursor-pointer ${expandedList.includes(3) ? 'opacity-100' : 'opacity-50'}`}>
            <Star label='Fuel Consumed' />
          </li>
        )}
      </ul>
      <ul role='menubar' className='list-group absolute z-10 text-[11px] font-medium right-4 top-5 items-start xs:items-center flex flex-col xs:flex-row  gap-2'>
        <li onClick={() => setActiveChart(daily_chart)} className={`cursor-pointer ${activeChart === daily_chart ? 'opacity-100' : 'opacity-50'}`}>
          <span className={`w-2 h-2 rounded-full inline-block ${activeChart === daily_chart ? 'bg-[#5D5FEF]' : 'bg-[#A8C5DA]'} `}></span> Daily Summary
        </li>
        <li onClick={() => setActiveChart(monthly_chart)} className={`cursor-pointer ${activeChart === monthly_chart ? 'opacity-100' : 'opacity-50'}`}>
          <span className={`w-2 h-2 rounded-full inline-block ${activeChart === monthly_chart ? 'bg-[#5D5FEF]' : 'bg-[#A8C5DA]'} `}></span> Monthly Summary
        </li>
      </ul>
      <ul className='absolute right-4 top-16 xs:top-10 z-10'>
        <li onClick={() => setModalShow(true)} className={`cursor-pointer`}>
          <FilterIcon className='w-6 h-6 stroke-path' />
        </li>
      </ul>
      {modalShow && (
        <Modal onClose={() => setModalShow(false)}>
          <div className='p-4 position-center bg-primary-700 rounded-lg'>
            <h2 className='mb-3'>Choose Date Range</h2>
            <InputDateRange action={handleCustomDuration} showTime={true} />
          </div>
        </Modal>
      )}
      <Suspender isError={isError} isLoading={isLoading} preRender={false}>
        {activeChart === daily_chart && <DailyChart data={daily_data} expandedList={expandedList} />}
        {activeChart === monthly_chart && <MonthlyChart data={monthly_data} expandedList={expandedList} />}
      </Suspender>
    </div>
  );
}

// const data = [
//   {
//     id: 1,
//     fuel: 0.8,
//     standard: 1,
//   },
//   {
//     id: 2,
//     fuel: 1,
//     standard: 1,
//   },
//   {
//     id: 3,
//     fuel: 5,
//     standard: 1,
//   },
//   {
//     id: 4,
//     fuel: 1.5,
//     standard: 1,
//   },
// ];
