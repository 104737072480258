import React from 'react';
import { ReactComponent as RightIcon } from '../../../../assets/icons/rightIcon.svg';
import { getInActiveTimeToString } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import Dot from '../../../../assets/icons/dot';

export default function NotificationCard({ notifications = [], contentClass = '', headerClass = '', nextUrl = '' }) {
  const navigate = useNavigate();
  return (
    <>
      <ul className={`flex w-full justify-between items-center ${headerClass}`}>
        <li className='text-lg font-semibold'>Notifications</li>
        <li onClick={() => nextUrl && navigate(nextUrl)}>
          <RightIcon className='cursor-pointer' />
        </li>
      </ul>

      <div className={`${contentClass}`}>
        {notifications?.map((item, i) => (
          <ul key={i} className='my-4 flex items-baseline text-base leading-5'>
            <li className='mr-1'>
              <Dot radius={4} fill={`${item.active ? '#439B57' : '#9B4343'}`} />
            </li>
            <li className='text-primary-700 dark:text-primary-200'>
              {item.message} <br /> <span className='text-sm opacity-40'>{getInActiveTimeToString(item.last_time)}</span>
            </li>
          </ul>
        ))}
      </div>
    </>
  );
}
