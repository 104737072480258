import { createSlice } from '@reduxjs/toolkit';

const deviceSensorsSlice = createSlice({
  name: 'deviceSensors',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    deviceSensorsInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceSensorsSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    deviceSensorsFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    deviceSensorsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceSensorsDelSuccess: (state, action) => {
      let delItems = action.payload?.data || [];
      let dataAfterDel = [...state.response.data]?.filter((item) => !delItems.includes(item.id));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },
    deviceSensorsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { deviceSensorsInit, deviceSensorsSuccess, deviceSensorsFail, deviceSensorsDelInit, deviceSensorsDelSuccess, deviceSensorsDelFail } = deviceSensorsSlice.actions;
export default deviceSensorsSlice.reducer;
