import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalize, getFormattedServiceHours } from '../../../../../utils/utils';
import './style.css';
import NoData from '../../../no-data/NoData';

export default function DailyChart({ data = [], options = {}, children, headerClass = '', className = '', onClick = () => {}, expandedList = [] }) {
  return (
    <div className='chart-container py-8 px-6 text-[8px]'>
      {data.length ? (
        <ResponsiveContainer width='100%' aspect={1 / 0.6}>
          <LineChart
            data={data?.map((it) => ({
              ...it,
              temp_run_hour: it.run_hour / 3600,
              temp_run_kwh: it.run_kwh,
              temp_fuel: it.fuel,
            }))}
            margin={{ top: 16, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} className='dark:stroke-[#f9f8f80d] stroke-[#ede6e65f]' strokeDasharray='0' />
            <XAxis dataKey='day' className='stroke-[rgba(0, 0, 0, 0.2)] dark:stroke-white' />
            {/* <YAxis dataKey='run_khw' domain={[0, 'dataMax+100']} /> */}
            {expandedList.includes(1) && <YAxis hide={!!!options.Y} yAxisId='left' dataKey='temp_run_hour' stroke='#ffc107' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} orientation='left' />}
            {expandedList.includes(2) && <YAxis hide={!!!options.Y} yAxisId='right' dataKey='temp_run_kwh' stroke='#17a2b8' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} orientation='right' />}
            {expandedList.includes(3) && <YAxis hide={!!!options.Y} yAxisId='right-fuel' dataKey='temp_fuel' stroke='#008021' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} orientation='right' />}

            <Tooltip
              wrapperStyle={{ outline: 'none', border: 0 }}
              wrapperClassName='shadow-md'
              contentStyle={{ padding: '16px', border: 0, borderRadius: '4px', overflow: 'hidden' }}
              labelStyle={{ color: 'black', fontWeight: '500' }}
              formatter={formatter}
            />

            {expandedList.includes(1) && <Line yAxisId='left' type='monotone' dataKey='temp_run_hour' stroke='#ffc107' strokeWidth={2} dot={false} />}
            {expandedList.includes(2) && <Line yAxisId='right' type='monotone' dataKey='temp_run_kwh' stroke='#17a2b8' strokeWidth={2} dot={false} />}
            {expandedList.includes(3) && <Line yAxisId='right-fuel' type='monotone' dataKey='temp_fuel' stroke='#AD83F6' strokeWidth={2} dot={false} />}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </div>
  );
}

const formatter = (value, name, props) => {
  if (name === 'temp_run_hour') return [`${getFormattedServiceHours(props.payload.run_hour)}`, `Run hour`];
  if (name === 'temp_run_kwh') return [`${props.payload.run_kwh} kWh`, `Power consumed`];
  if (name === 'temp_fuel') {
    if (props.payload.fuel === 0) return [`${props.payload.expected_fuel_consumed} Ltr`, `Expected Fuel`];
    return [`${props.payload.fuel} Ltr`, `Fuel`];
  }
  return [`${value}`, `${capitalize(name).split('_').join(' ')}`];
};

export function returnYDataKey(id = 1) {
  if (id === 3) return 'temp_fuel';
  else if (id === 2) return 'temp_run_kwh';
  return 'temp_run_hour';
}

// const data = [
//   {
//     id: 1,
//     fuel: 0.8,
//     standard: 1,
//   },
//   {
//     id: 2,
//     fuel: 1,
//     standard: 1,
//   },
//   {
//     id: 3,
//     fuel: 5,
//     standard: 1,
//   },
//   {
//     id: 4,
//     fuel: 1.5,
//     standard: 1,
//   },
// ];
