import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SUPER_ADMIN, getCompanies, getFuelEntries, getPgs } from '../../../../apis/apis';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plusIcon.svg';
import { useFetchFun, useQueryParm, useUserInfo } from '../../../../hooks/hooks';
import { checkEmpty, timeConvert } from '../../../../utils/utils';
import SelectFromOption from '../../../basic/SelectFromOption/SelectFromOption';
import Button from '../../../basic/button';
import Input from '../../../basic/Input/Input';
import { makeSubmittableData } from '../makeSubmittableData';
import { validation } from '../validator';
import './style.css';
import { postApi } from '../../../../apis/methods';

export default function AddFuelForm({ initData = {}, onClose = () => {}, fromPgList = false, role = 'add' || 'update' }) {
  const { user_type, company, id } = useUserInfo();

  const initFormData = {
    id: { value: initData.id },
    company_id: { value: initData.company_id || (user_type === SUPER_ADMIN ? '' : company.id), error: '', required: true },
    pg_number: { value: initData.pg_number || '', error: '', required: true, displayKey: 'Generator Number' },
    fuel_entry_date: { value: initData.fuel_entry_date || '', error: '', required: true },
    fuel_amount: { value: initData.fuel_amount || '', error: '', required: true },
    user_id: { value: initData.user_id || id, error: '' },
  };

  const [selDate, setSelDate] = useState({});
  const [selCompany, setSelCompany] = useState(user_type === SUPER_ADMIN ? {} : company);
  const [selPG, setSelPG] = useState({});
  const [formData, updateFormData] = useState(initFormData);
  const companies = useSelector((state) => state.companies);
  const pgs = useSelector((state) => state.pgs);
  useFetchFun(getPgs);
  const [queryObj] = useQueryParm({ per_page: 20 });

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const resetter = () => {
    updateFormData(initFormData);
    setSelCompany(user_type === SUPER_ADMIN ? {} : company);
    setSelDate({});
    setSelPG({});
    onClose();
    getFuelEntries(queryObj);
  };

  const handleClickOnAdd = async () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      const temp = data.data;
      await postApi({ body: { ...temp, fuel_entry_date: timeConvert(new Date(temp.fuel_entry_date).getTime()).second }, url: 'fuel-entry/create', resolve: resetter });
    } else {
      updateFormData({ ...data.data });
    }
  };

  const handleClickOnUpdate = async () => {
    let data = makeSubmittableData(formData);
    if (data.status) {
      const temp = data.data;
      await postApi({ body: { ...temp, fuel_entry_date: timeConvert(new Date(temp.fuel_entry_date).getTime()).second }, url: 'fuel-entry/update', resolve: resetter });
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    if (!checkEmpty(selCompany)) {
      updateFormData((prev) => ({ ...prev, company_id: { value: selCompany.id, error: false } }));
    }
  }, [selCompany]);

  useEffect(() => {
    if (!checkEmpty(selPG)) {
      updateFormData((prev) => ({ ...prev, pg_number: { value: selPG.pg_number, error: false } }));
    }
  }, [selPG]);

  useEffect(() => {
    if (!checkEmpty(selDate)) {
      updateFormData((prev) => ({ ...prev, fuel_entry_date: { value: selDate, error: false } }));
    }
  }, [selDate]);

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies({ page: 0, per_page: 1000 });
    }
  }, [user_type]);

  useEffect(() => {
    if (!checkEmpty(initData.company_id)) {
      setSelCompany(() => companies.response?.data.find((it) => it.id === initData.company_id));
    }
    if (!checkEmpty(initData.pg_number)) {
      setSelPG(() => pgs.response?.data?.find((it) => it.pg_number === initData.pg_number));
    }
    if (!checkEmpty(initData.fuel_entry_date)) {
      let d = new Date(initData.fuel_entry_date * 1000);
      let yr = d.getFullYear();
      let mn = d.getMonth() + 1;
      let day = d.getDate();
      setSelDate(`${yr}-${mn < 10 ? '0' + mn : mn}-${day < 10 ? '0' + day : day}`);
    }
  }, [companies, pgs, initData, role]);

  return (
    <div className='add-fuel-form'>
      <h2 className='mb-4'>Fuel Entry</h2>
      <div className='flex flex-col gap-4'>
        {user_type === SUPER_ADMIN && !fromPgList && (
          <div className=''>
            <label>Select Company</label>
            <SelectFromOption
              onClick={(op) => setSelCompany(op)}
              value={selCompany}
              options={companies.response?.data || []}
              displayKey='name'
              headerClass='custom-border h-10 '
              placeholder='Select Company'
              fieldError={formData.company_id?.error || false}
            />
          </div>
        )}
        {!fromPgList && (
          <div className=''>
            <label>Select Generator</label>
            <SelectFromOption onClick={(op) => setSelPG(op)} value={selPG} options={pgs.response?.data || []} displayKey='pg_number' headerClass='custom-border h-10 ' placeholder='Select Generator' fieldError={formData.pg_number?.error || false} />
          </div>
        )}
        <div className=''>
          <label>Fuel Amount (Ltr)</label>
          <Input name='fuel_amount' validation={validation.number} value={formData.fuel_amount?.value} onChange={handleChange} placeholder='Enter amount' className='custom-input' type='text' fieldError={formData.fuel_amount?.error || false} />
        </div>

        <div className=''>
          <label>Date</label>
          <Input
            name='fuel_entry_date'
            validation={validation.required}
            value={formData.fuel_entry_date?.value}
            onChange={handleChange}
            placeholder='Enter date'
            className='custom-input'
            type='date'
            fieldError={formData.fuel_entry_date?.error || false}
          />
        </div>
        <div>
          <Button onClick={role === 'add' ? handleClickOnAdd : handleClickOnUpdate} className='btn-normal cursor-pointer btmClass h-10'>
            <div className='flex items-center justify-center btn-success w-full h-10'>
              <PlusIcon /> <label className='text-base ml-1'>{role === 'add' ? 'Save' : 'Update'}</label>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
