import React, { useState } from 'react';
import { useEffect } from 'react';
import Suspender from '../components/common/suspender/Suspender';
import CustomBarChart from '../components/common/cards/chart-cards/bar/CustomBarChart';
import { useApiSlice, useUserInfo } from '../hooks/hooks';
import { useParams } from 'react-router-dom';
import { SUPER_ADMIN } from '../apis/apis';
import { getTimeRangeByAgo, parseDateTime } from '../utils/utils';
import SelectFromOption from '../components/basic/SelectFromOption/SelectFromOption';
import InputDateRange from '../components/basic/input-date-range/InputDateRange';
import { optionsWithCustomInput } from '../dataset';

export default function FuelConsumedDateChart() {
  const params = useParams();
  const { company, user_type } = useUserInfo();
  const company_id = user_type === SUPER_ADMIN ? null : company.id;

  const { start_time, end_time } = getTimeRangeByAgo();
  const { getItems, isLoading, isError, result, updateQuery } = useApiSlice({ page: 0, per_page: 10, start_time, end_time, pg_number: params.pg_number, company_id });
  const [selOp, setOption] = useState({});
  const [customDate, setCustomDate] = useState(false);

  const handleClickOnOption = React.useCallback(
    (op) => {
      setOption(op);
      if (op.value === 'custom-input') {
        setCustomDate(true);
      } else {
        const { start_time, end_time } = getTimeRangeByAgo(op.value);
        updateQuery((state) => ({ ...state, start_time, end_time }));
      }
    },
    [updateQuery]
  );

  const handleCustomSearch = React.useCallback(
    ({ data: dateRange = {} }) => {
      const { start_time = '', end_time = '' } = dateRange;
      let startTime = new Date(start_time).getTime() / 1000;
      let endTime = new Date(end_time).getTime() / 1000;
      updateQuery((state) => ({ ...state, start_time: startTime, end_time: endTime }));
      setCustomDate(false);
    },
    [updateQuery]
  );

  useEffect(() => {
    getItems({ endPoint: 'pg-stats-data/fuel-consumption-only' });
  }, [getItems]);

  return (
    <div className='mb-16 custom-shadow rounded'>
      <h2 className='mb-10 p-4'>Fuel Consumption</h2>

      <div className='relative'>
        {customDate && (
          <div className='form-wrapper w-max absolute right-4 bottom-full'>
            <InputDateRange action={handleCustomSearch} />
          </div>
        )}
        <div className='w-28 absolute top-4 right-4 z-10'>
          <SelectFromOption readOnly={true} onClick={handleClickOnOption} value={selOp} displayKey='title' options={optionsWithCustomInput} headerClass='header-class h-9' placeholder='Choose' />
        </div>
        <Suspender isError={isError} isLoading={isLoading}>
          <CustomBarChart intervalY={20} xKey='date' yKey='fuel_consumed' yLabel='Fuel Consumed (Ltr)' chartData={result.data?.map((it) => ({ ...it, date: parseDateTime(it.start_time || 0, 'DD MMM YYYY').date })) || []} />
        </Suspender>
      </div>
    </div>
  );
}
