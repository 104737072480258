import React from 'react';

import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter.svg';
import { summary_options } from './constant';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { optionsByHour } from '../../../../../dataset';
import { useFetchFun, useFilter } from '../../../../../hooks/hooks';
import { getPgs } from '../../../../../apis/apis';
import { useEffect } from 'react';
import { checkEmpty, getTimeRangeByAgo, parseDateTime, parseTimeDuration, timeConvert } from '../../../../../utils/utils';
import Modal from '../../../../../components/basic/Modals/Modal';
import SelectFromOption from '../../../../../components/basic/SelectFromOption/SelectFromOption';
import Button from '../../../../../components/basic/button';
import Suspender from '../../../../../components/common/suspender/Suspender';
import NoData from '../../../../../components/common/no-data/NoData';
import InputDateRange from '../../../../../components/basic/input-date-range/InputDateRange';
const optionsByHourWithCustom = [...optionsByHour, { title: 'Custom Input', value: 'custom' }];

export default function SummaryTable({ query = {}, updateQuery = () => {}, resetQuery = () => {}, type = '' }) {
  const { isLoading, isError, response } = useSelector((state) => state.dashboardSummary);
  const [selLastTime, updateLastTime] = useState(optionsByHourWithCustom[1]);
  const [params, addParams, resetFilter] = useFilter({});

  const [selGenerator, updateGenerator] = useState({});
  const pgList = useSelector((state) => state.pgs);
  const [customInput, setCustomInput] = useState(false);
  const navigate = useNavigate();

  useFetchFun(getPgs);
  const [, setShowExpectedFuel] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (!checkEmpty(selLastTime)) {
      const { start_time, end_time } = getTimeRangeByAgo(selLastTime.value);
      addParams({ start_time, end_time });
    }
  }, [selLastTime, addParams]);

  useEffect(() => {
    if (!checkEmpty(selGenerator)) {
      addParams({ pg_number: selGenerator.pg_number });
    }
  }, [selGenerator, addParams]);

  const applyFilter = () => {
    updateQuery((prev) => ({ ...prev, ...params }));
    setModalShow(false);
  };

  const handleReset = () => {
    updateGenerator({});
    setShowExpectedFuel(false);
    updateLastTime(optionsByHour[1]);
    resetFilter();
    resetQuery();
    setCustomInput(false);
  };

  const handleAction = (res) => {
    if (res.status) {
      const { data } = res;
      const start_time = new Date(data.start_time).getTime() / 1000;
      const end_time = new Date(data.end_time).getTime() / 1000;
      updateQuery((prev) => ({ ...prev, start_time, end_time }));
      setModalShow(false);
    }
  };

  const handleSelection = (item) => {
    if (item.value === 'custom') {
      setCustomInput(true);
    } else {
      setCustomInput(false);
    }
    updateLastTime(item);
  };

  const getDurationToString = (duration) => {
    const { hour = 0, min = 0, sec = 0 } = parseTimeDuration(duration);
    if (!hour && !min && !sec && true) return '---';
    else {
      return `${hour > 0 ? hour + ' hrs' : ''} ${min > 0 ? min + ' min ' : ''}${sec > 0 ? sec + ' sec' : ''}`;
    }
  };

  return (
    <>
      {modalShow && (
        <Modal onClose={() => setModalShow(false)}>
          <div className='p-10'>
            <div className='mb-4 w-full flex flex-col items-start gap-4'>
              <div className='flex flex-col items-start sm:flex-row sm:items-center'>
                <label className='w-36 whitespace-nowrap'>Last Time:</label>
                <div className='flex-1'>
                  <SelectFromOption headerClass='!px-4 rounded-sm custom-border h-8' options={optionsByHourWithCustom} value={selLastTime} displayKey='title' onClick={handleSelection} placeholder='Filter' />
                </div>
              </div>
              <div className='flex flex-col items-start sm:flex-row sm:items-center'>
                <label className='w-36 whitespace-nowrap'>Generator:</label>
                <div className='flex-1'>
                  <SelectFromOption onClick={(op) => updateGenerator(op)} value={selGenerator} options={pgList.response?.data || []} headerClass='!px-4 rounded-sm custom-border h-8' placeholder='Select Generator' displayKey='pg_name' />
                </div>
              </div>

              {customInput && (
                <div className='flex flex-col items-start sm:flex-row sm:items-center'>
                  <label className='w-36 whitespace-nowrap'>Custom Duration:</label>
                  <InputDateRange action={handleAction} />
                </div>
              )}
            </div>

            {!customInput && (
              <div className='mt-10 flex items-center gap-3 justify-end'>
                <Button title='Reset' onClick={handleReset} className=' w-fit px-2 py-1 btn-warning' />
                <Button title='Apply' onClick={applyFilter} className='w-fit px-4 py-1 btn-success' />
              </div>
            )}
          </div>
        </Modal>
      )}

      <div className='mb-2 ml-auto w-fit flex items-center gap-6'>
        <p className='font-medium'>
          {parseDateTime(query.start_time, 'DD, MMM').date} - {parseDateTime(query.end_time, 'DD, MMM').date}
        </p>
        <FilterIcon onClick={() => setModalShow(true)} className='w-8 h-8 stroke-path cursor-pointer' />
      </div>
      <Suspender isLoading={isLoading} isError={isError}>
        <div className='table-wrapper'>
          <table className=''>
            <thead>
              <tr>
                <th>Generator Number</th>
                <th className={`${type === summary_options.sum_duration && 'bg-secondary-500'}`}>Run Hour</th>
                <th className={`${type === summary_options.energy_consumed && 'bg-secondary-500'}`}>Energy Generated (kwh)</th>
                <th className={`${type === summary_options.fuel_consumed && 'bg-secondary-500'}`}>Fuel Consumed (Ltr)</th>
              </tr>
            </thead>
            <tbody>
              {response.data?.map((item, i) => (
                <tr key={i} className='cursor-pointer hover:opacity-70' onClick={() => (item.tank_or_generator === 2 ? navigate(`device-data/only_fuel/${item.pg_number}`) : navigate(`device-data/${item.pg_number}`))}>
                  <td>{item.pg_number || '-'}</td>
                  <td className={`${type === summary_options.sum_duration && 'bg-secondary-500'}`}>{item.tank_or_generator === 2 ? '---' : getDurationToString(item.sum_duration)}</td>
                  <td className={`${type === summary_options.energy_consumed && 'bg-secondary-500'}`}>{item.tank_or_generator === 2 ? '--' : item.energy_consumed || '-'}</td>
                  <td className={`${type === summary_options.fuel_consumed && 'bg-secondary-500'}`}>{item.fuel_consumed || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {response.data?.length === 0 && !isLoading && <NoData />}
        </div>
      </Suspender>
      {/* <div className='pagination-wrapper'>
        <PaginatedItems itemsPerPage={queryObject.per_page} onClick={(parm) => updateQueryObject({ ...queryObject, page: parm })} totalItems={response.pagination?.total} />
      </div> */}
    </>
  );
}
