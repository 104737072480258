import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { getUserInfo } from '../../apis/apis';
import Loader from '../../components/common/suspender/Loader';
import { useSelector } from 'react-redux';
import Protected from './Protected';

export default function RootElement() {
  const { isLoading, isAuthenticated, user = {} } = useSelector((state) => state.userInfo);

  useEffect(() => {
    getUserInfo();
  }, []);

  if (isLoading) return <Loader />;
  return (
    <>
      <Outlet />
      <Protected isAuthenticated={isAuthenticated} user={user} />;
    </>
  );
}
