import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { getAlerts } from '../../../apis/apis';
import { useFetchFun } from '../../../hooks/hooks';
import { checkEmpty } from '../../../utils/utils';

export default function Notification({ view: View = notifyView, duration = 1000, position = {} }) {
  const portalContainer = useRef();
  const [list, updateList] = useState([]);
  const [newIds, setNewIds] = useState([]);
  const {
    response: { data: alerts = [] },
  } = useSelector((state) => state.alerts);
  useFetchFun(getAlerts, {}, true);
  const [firstLoad, setFirstLoad] = useState(true);

  const portal = (
    <div ref={portalContainer} style={{ ...position }} className={`portals-container fixed bottom-4 right-4 z-50`}>
      {newIds?.map((it, i) => (
        <div key={i} property={it.id} className='portal p-3 m-2 '>
          <View item={it} />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    let ele = portalContainer.current?.children;
    [...Array.from(ele)]?.forEach((it, i) => {
      if (it) {
        it.style.display = 'block';
      }
    });

    [...Array.from(ele)]?.forEach((it, i) => {
      pushNotifyIdToLocalStorage(it.getAttribute('property'));
      let v = setTimeout(() => {
        if (it) {
          it.style.display = 'none';
        }
        return clearTimeout(v);
      }, (i + 1) * 10000);
    });
  }, [newIds]);

  useEffect(() => {
    if (alerts.length && firstLoad) {
      setDataToSession(
        'notifiedIds',
        alerts?.map((it) => it.id)
      );
      setFirstLoad(false);
    }
  }, [alerts, firstLoad]);

  useEffect(() => {
    if (!firstLoad) {
      updateList(alerts);
    }
  }, [alerts, firstLoad]);

  useEffect(() => {
    const cutList = filteredIdsByLocalStorage(list.length > 0 ? list : []);
    if (cutList.length > 0) {
      setNewIds(cutList);
    }
  }, [list]);

  return ReactDOM.createPortal(portal, document.getElementById('root'));
}

export const notifyView = ({ item = { id: 1, title: 'notification comes' } }) => {
  return (
    <div className='notify-card w-52 p-4 m-2 shadow-2xl bg-primary-600'>
      <h2>{item.title}</h2>
    </div>
  );
};

export function pushNotifyIdToLocalStorage(id) {
  const existId = sessionStorage.getItem('notifiedIds');
  let tepArr = JSON.parse(existId) || [];
  tepArr.push(Number(id));
  setDataToSession('notifiedIds', tepArr);
}

export function filteredIdsByLocalStorage(list = []) {
  const existId = sessionStorage.getItem('notifiedIds');
  let tepArr = JSON.parse(existId);
  let arr = list?.filter((it) => !tepArr?.includes(it.id));

  return arr;
}

export function setDataToSession(key = '', arr = []) {
  if (!checkEmpty(key)) {
    sessionStorage.setItem(key, JSON.stringify(arr));
  }
}

export function getDataFromSession(key = '') {
  if (!checkEmpty(key)) {
    let tempData = sessionStorage.getItem(key);
    return JSON.parse(tempData) || [];
  }
}
