import { createSlice } from '@reduxjs/toolkit';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: { isLoading: true, response: { data: [] || {}, pagination: {} }, isError: false },
  reducers: {
    companiesInit: (state) => {
      return { ...state, isLoading: true };
    },
    companiesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    companiesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
    companiesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    companiesDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    companiesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { companiesInit, companiesSuccess, companiesFail, companiesDelInit, companiesDelSuccess, companiesDelFail } = companiesSlice.actions;
export default companiesSlice.reducer;
