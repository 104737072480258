import PropTypes from 'prop-types';
import { useRef } from 'react';
import './search.css';
import { ReactComponent as SearchIcon } from './searchIcon.svg';

export default function Search(props) {
  const { style, placeholder, handleFocus = () => null, icon, children, type = 'text', className = '', handleClickOnIcon = () => {}, onSearch = () => {} } = props;
  const inputRef = useRef();

  const handleClick = () => {
    handleClickOnIcon(inputRef?.current?.value || '');
  };

  const debounce = (fn = () => {}, duration = 2000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, duration);
    };
  };

  const handleOnSearch = debounce((v) => {
    onSearch(v);
  }, 500);

  return (
    <div style={style} className={`search ${className}`}>
      <input onFocus={handleFocus} onKeyUp={(e) => handleOnSearch(e.target.value)} ref={inputRef} placeholder={placeholder} type={type} />
      {icon && (
        <div className='h-full w-max flex items-center bg-[inherit] pt-1 px-1 '>
          <SearchIcon onClick={handleClick} className='w-4 opacity-70' />
        </div>
      )}
      {children}
    </div>
  );
}

Search.propTypes = {
  style: PropTypes.object,
  placeholder: PropTypes.string,
  icon: PropTypes.bool,
  children: PropTypes.element,
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onSearch: PropTypes.func,
  handleFocus: PropTypes.func,
};
