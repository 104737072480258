import { capitalize } from '../../../utils/utils';

export const validation = {
  name: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  password: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 4 || value.length > 200) {
      return `${capName} must be between 4 to 20`;
    }
    return false;
  },
  email: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 4 || value.length > 200) {
      return `${capName} must be between 4 to 20`;
    }
    return false;
  },
  phone: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (value.length < 11 || value.length > 13) {
      return `${capName} must be  11 digits`;
    }
    return false;
  },
  search: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  required: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    }
    return false;
  },
  number: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (isNaN(value) || value.endsWith('.')) {
      return `${capName} must be a Number`;
    }
    return false;
  },

  featureName: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else {
      const regx = new RegExp(/^\s| {2}|\s$/g);
      if (regx.test(value)) {
        return `${capName} Remove Extra Space`;
      }
      return false;
    }
  },
  date: (name, value, displayKey = false) => {
    const capName = displayKey ? displayKey : capitalize(name).split('_').join(' ');
    if (value === '' || undefined) {
      return `${capName} is required`;
    } else if (typeof value !== 'string') {
      return `${capName} must be a string`;
    }
    return false;
  },
};

export function checkEmptyObject(object) {
  return !Object.keys(object).length;
}

export function checkEmptyArray(arr) {
  return !arr.length;
}

export function formRest() {
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i]['value'] = '';
  }
}
