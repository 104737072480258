import { capitalize } from '../../../utils/utils';

export function makeSubmittableData(formData) {
  let submittableData = {};
  let error = { ...formData };
  let status = true;
  for (const key in formData) {
    if (formData[key].required && !formData[key].value) {
      error[key].error = `${formData[key].displayKey || capitalize(key).split('_').join(' ')} is required`;
      status = false;
    } else if (formData[key].error) {
      status = false;
    } else {
      submittableData[key] = formData[key].value;
    }
  }

  return { status: status, data: status ? submittableData : error };
}
