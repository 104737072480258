import React, { useCallback, useEffect, useState } from 'react';
import { USER_VIEWS_ONLY, downloadApi, getApiReturn } from '../../../apis/apis';
import { useUserInfo } from '../../../hooks/hooks';
import DownloadButton from '../../../components/common/download/DownloadButton';
import NoData from '../../../components/common/no-data/NoData';

export default function GetReportDataTable({ query = {}, endPont = '', type = '', tableHeading = '' }) {
  const [tableData, setTableData] = useState({});
  const { headers = [], data = [] } = tableData || {};
  const { user_type = USER_VIEWS_ONLY } = useUserInfo();

  const getData = useCallback(
    async (params) => {
      let response = await getApiReturn(endPont, type === 'custom' ? { report_type: type, ...params } : { report_type: type });
      if (response.status) {
        setTableData(response.data);
      }
    },
    [type, endPont]
  );

  useEffect(() => {
    getData(query);
  }, [query, getData]);

  return (
    <div className=''>
      <div className='mb-2 flex justify-between'>
        {tableHeading && <p className='py-[2px] font-bold px-1 text-white bg-secondary-700 '>{tableHeading}</p>}
        {user_type !== USER_VIEWS_ONLY && <DownloadButton action={() => downloadApi(endPont, type === 'custom' ? { report_type: type, ...query } : { report_type: type })} />}
      </div>
      <div className='table-wrapper'>
        <table>
          <thead>
            <tr>
              {headers?.map((he, i) => (
                <th className='whitespace-break-spaces' key={i}>
                  {he.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='[&>tr:last-child]:font-bold [&>tr:last-child]:dark:bg-secondary-900 [&>tr:last-child]:bg-primary-600 '>
            {data?.map((item, i) => (
              <tr key={i}>{getTableColumns(item)}</tr>
            ))}
          </tbody>
        </table>
        {data?.length === 0 && <NoData />}
      </div>
    </div>
  );
}

function getTableColumns(item) {
  // for (const key in item) {
  //   if (key === 'run_time_sec' && item.run_time_sec) {
  //     item[key] = parseDateTime(item.run_time_sec || 100).time || '-';
  //   }
  //   if (key === 'duration') {
  //     item[key] = getFormattedTime(item.duration) || '-';
  //   }
  // }
  return (
    <>
      {Object.keys(item)?.map((key, i) => (
        <td key={i}>{item[key] || '-'}</td>
      ))}
    </>
  );
}
