import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../../layout';
import Home from '../../pages/home';
import TenantCompany from '../../pages/tenant-companies/index.js';
import UserProfileUpdate from '../../pages/user/user-update-info';
import { CUSTOMER_ADMIN, SUPER_ADMIN } from '../../apis/apis.js';
const EventStats = React.lazy(() => import('../../pages/event-stats'));
const NotificationAll = React.lazy(() => import('../../pages/notifications'));

const UserTypeDefaultFeatures = React.lazy(() => import('../../pages/user-type-default-features'));
const UserFeatures = React.lazy(() => import('../../pages/user-features'));
const Features = React.lazy(() => import('../../pages/features'));
const UserList = React.lazy(() => import('../../pages/user/user-list'));

const Details02 = React.lazy(() => import('../../pages/device/device-details/details_2/index.js'));
const PasswordChange = React.lazy(() => import('../../pages/user/password-change'));
const CompanyList = React.lazy(() => import('../../pages/company/company-list'));
const ErrorBoundary = React.lazy(() => import('../../pages/error-boundary'));
const TowerSiteList = React.lazy(() => import('../../pages/tower-site/tower-site-list'));

const CreateTowerSite = React.lazy(() => import('../../pages/tower-site/create-tower-site'));
const TowerSiteFileUpload = React.lazy(() => import('../../pages/tower-site/tower-site-file-upload'));
const CreateCompany = React.lazy(() => import('../../pages/company/create-company'));
const CreateUser = React.lazy(() => import('../../pages/user/create-user'));
const AddDevice = React.lazy(() => import('../../pages/device/add-device'));
const DeviceFileUpload = React.lazy(() => import('../../pages/device/upload-devices'));
const DeviceList = React.lazy(() => import('../../pages/device/device-list'));
const AddPG = React.lazy(() => import('../../pages/pgm/add-pg'));
const PGFileUpload = React.lazy(() => import('../../pages/pgm/upload-pg'));
const DeviceDetails = React.lazy(() => import('../../pages/device/device-details'));
const UserPGList = React.lazy(() => import('../../pages/pgm/user-pg-list'));
const ReportGenerateDefault = React.lazy(() => import('../../pages/reports/index.js'));
const ChatBoard = React.lazy(() => import('../../pages/chat-board'));

const AddZone = React.lazy(() => import('../../pages/zone/add-zone'));
const ZoneList = React.lazy(() => import('../../pages/zone/zone-list'));
const FaultTypeList = React.lazy(() => import('../../pages/fault-type/fault-types'));
const AddFaultTypeForm = React.lazy(() => import('../../components/common/forms/add-fault-fault-type-form/AddFaultTypeForm'));
const Contact = React.lazy(() => import('../../pages/contact'));
const TicketList = React.lazy(() => import('../../pages/ticket/tickets'));

const FilterOnMap = React.lazy(() => import('../../pages/filter-on-map'));
const GeneratorCompany = React.lazy(() => import('../../pages/generator-company/index'));
const GeneratorModel = React.lazy(() => import('../../pages/generator-model'));
const Fuels = React.lazy(() => import('../../pages/fuel-entry'));
const Services = React.lazy(() => import('../../pages/services'));
const DeviceModules = React.lazy(() => import('../../pages/device-modules'));

const DeviceSensors = React.lazy(() => import('../../pages/device-sensors'));
const PGAttach = React.lazy(() => import('../../pages/pgm/attach-pg'));
const AttachGeneratorModule = React.lazy(() => import('../../pages/attach-generator-module'));
const FuelTankDimensions = React.lazy(() => import('../../pages/fuel-tank-dimension'));
const GeneratorList = React.lazy(() => import('../../pages/pgm/generators/index.js'));
const Tenants = React.lazy(() => import('../../pages/tenants'));

const RoutesSuperAdmin = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='dashboard/reports' element={<ReportGenerateDefault />} />
        <Route path='dashboard/event-stats' element={<EventStats />} />

        <Route path='generator/add' element={<AddPG />} />
        <Route path='generator/list' element={<GeneratorList />} />
        <Route path='generator/list/:userId' element={<UserPGList />} />
        <Route path='generator/file-upload' element={<PGFileUpload />} />
        <Route path='generator/attach' element={<PGAttach />} />

        <Route path='device/add' element={<AddDevice />} />
        <Route path='device/list' element={<DeviceList />} />
        <Route path='device/file-upload' element={<DeviceFileUpload />} />
        <Route path='tower-site/create' element={<CreateTowerSite />} />
        <Route path='tower-site/list' element={<TowerSiteList />} />
        <Route path='tower-site/file-upload' element={<TowerSiteFileUpload />} />
        <Route path='company/create' element={<CreateCompany />} />
        <Route path='company/list' element={<CompanyList />} />
        <Route path='tenant/list' element={<Tenants />} />
        <Route path='tenant/company' element={<TenantCompany />} />
        <Route path='user/create' element={<CreateUser />} />
        <Route path='user/list' element={<UserList />} />
        <Route path='user/profile/update' element={<UserProfileUpdate />} />
        <Route path='user/profile/password/change' element={<PasswordChange />} />

        <Route path='device-data/:pg_number' element={<DeviceDetails />} />
        <Route path='device-data/only_fuel/:pg_number' element={<Details02 />} />

        <Route path='chat/board' element={<ChatBoard />} />
        <Route path='map/filter' element={<FilterOnMap />} />

        <Route path='zone/add' element={<AddZone />} />
        <Route path='zone/list' element={<ZoneList />} />
        <Route path='fault-type/add' element={<AddFaultTypeForm />} />
        <Route path='fault-type/list' element={<FaultTypeList />} />
        <Route path='ticket/board' element={<TicketList />} />

        <Route path='fuel-tank-dimensions' element={<FuelTankDimensions />} />
        <Route path='generator/company' element={<GeneratorCompany />} />
        <Route path='generator/model' element={<GeneratorModel />} />

        <Route path='fuel/list' element={<Fuels />} />
        <Route path='service/list' element={<Services />} />

        <Route path='device/modules' element={<DeviceModules />} />
        <Route path='device/sensors' element={<DeviceSensors />} />
        <Route path='module/add-to-generator' element={<AttachGeneratorModule />} />

        <Route path='features/all' element={<Features />} />
        <Route path='features/user' element={<UserFeatures />} />
        <Route path='features/user-type' element={<UserTypeDefaultFeatures />} />
        <Route path='notifications' element={<NotificationAll />} />
        <Route path='contact-us' element={<Contact />} />
      </Route>
      <Route path='*' element={<ErrorBoundary />} />
    </Routes>
  );
};
const RoutesCustomerAdmin = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='dashboard/reports' element={<ReportGenerateDefault />} />
        <Route path='dashboard/event-stats' element={<EventStats />} />
        <Route path='generator/list' element={<GeneratorList />} />
        <Route path='generator/list/:userId' element={<UserPGList />} />
        <Route path='device/list' element={<DeviceList />} />
        <Route path='tower-site/create' element={<CreateTowerSite />} />
        <Route path='tower-site/list' element={<TowerSiteList />} />
        <Route path='tower-site/file-upload' element={<TowerSiteFileUpload />} />
        <Route path='user/create' element={<CreateUser />} />
        <Route path='user/list' element={<UserList />} />
        <Route path='user/profile/update' element={<UserProfileUpdate />} />
        <Route path='user/profile/password/change' element={<PasswordChange />} />
        <Route path='device-data/:pg_number' element={<DeviceDetails />} />
        <Route path='device-data/only_fuel/:pg_number' element={<Details02 />} />

        <Route path='fuel/list' element={<Fuels />} />
        <Route path='service/list' element={<Services />} />

        <Route path='chat/board' element={<ChatBoard />} />

        <Route path='zone/add' element={<AddZone />} />
        <Route path='zone/list' element={<ZoneList />} />
        <Route path='fault-type/add' element={<AddFaultTypeForm />} />
        <Route path='fault-type/list' element={<FaultTypeList />} />
        <Route path='ticket/board' element={<TicketList />} />

        <Route path='notifications' element={<NotificationAll />} />
        <Route path='contact-us' element={<Contact />} />
      </Route>
      <Route path='*' element={<ErrorBoundary />} />
    </Routes>
  );
};
const RoutesUser = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='dashboard/reports' element={<ReportGenerateDefault />} />
        <Route path='dashboard/event-stats' element={<EventStats />} />
        <Route path='generator/list' element={<GeneratorList />} />

        <Route path='user/profile/update' element={<UserProfileUpdate />} />
        <Route path='user/profile/password/change' element={<PasswordChange />} />

        <Route path='zone/add' element={<AddZone />} />
        <Route path='zone/list' element={<ZoneList />} />
        <Route path='fault-type/add' element={<AddFaultTypeForm />} />
        <Route path='fault-type/list' element={<FaultTypeList />} />
        <Route path='ticket/board' element={<TicketList />} />
        <Route path='device-data/:pg_number' element={<DeviceDetails />} />
        <Route path='device-data/only_fuel/:pg_number' element={<Details02 />} />

        <Route path='fuel/list' element={<Fuels />} />
        <Route path='service/list' element={<Services />} />
        <Route path='contact-us' element={<Contact />} />
        <Route path='chat/board' element={<ChatBoard />} />
      </Route>
      <Route path='*' element={<ErrorBoundary />} />
    </Routes>
  );
};

export default function Protected({ isAuthenticated, user }) {
  if (!isAuthenticated) return <Navigate to='/login' replace={true} />;
  else if (user?.data?.user_type === SUPER_ADMIN) return <RoutesSuperAdmin />;
  else if (user?.data?.user_type === CUSTOMER_ADMIN) return <RoutesCustomerAdmin />;
  else return <RoutesUser />;
}
