import { createSlice } from '@reduxjs/toolkit';

const devicesSlice = createSlice({
  name: 'devices',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    devicesInit: (state) => {
      return { ...state, isLoading: true };
    },
    devicesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    devicesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    devicesDelInit: (state) => {
      return { ...state, isLoading: true };
    },

    devicesDelSuccess: (state, action) => {
      let delItems = action.payload?.data || [];
      let dataAfterDel = [...state.response.data]?.filter((item) => !delItems.includes(item.device_id));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },

    devicesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { devicesInit, devicesSuccess, devicesFail, devicesDelInit, devicesDelSuccess, devicesDelFail } = devicesSlice.actions;
export default devicesSlice.reducer;
