const theme_default = {
  '--primary-50': '#fafafa',
  '--primary-100': '#f5f5f5',
  '--primary-200': '#e5e5e5',
  '--primary-300': ' #d4d4d4',
  '--primary-400': '#a3a3a3',
  '--primary-500': '#737373',
  '--primary-600': '#525252',
  '--primary-700': '#404040',
  '--primary-800': '#262626',
  '--primary-900': '#171717',
  '--primary-950': '#080a0c',
};

const theme_jti = {
  '--primary-50': '#ffffff',
  '--primary-100': '#fbfbfb',
  '--primary-200': '#e3f5ff',
  '--primary-300': '#e5ecf6',
  '--primary-400': '#F2F6F5',
  '--primary-500': '#798baa',
  '--primary-600': '#5b6e8f',
  '--primary-700': '#475670',
  '--primary-800': '#1c222c',
  '--primary-900': '#14171C',
  '--primary-950': '#000000',
};
const themes = { theme_default, theme_jti };

export function setThemes(str = 'theme_jti') {
  const root = document.querySelector(':root');
  const themeObj = themes[str] || themes['theme_default'];
  for (const key in themeObj) {
    root.style.setProperty(key, themeObj[key]);
  }

  return { theme_default, theme_jti };
}
