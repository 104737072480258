import React from 'react';
import PropTypes from 'prop-types';
import './Input.css';

const Input = (props) => {
  const { placeholder, readOnly = false, className, type = 'text', name, onChange, validation, fieldError, value, displayKey = false } = props;
  const controlChange = (e) => {
    const { name = '', value = '' } = e.target;
    if (validation) {
      const err = validation(name, value, displayKey);
      onChange(name, { value: value, error: err });
    } else {
      onChange(name, { value: value, error: false });
    }
  };
  return (
    <>
      <div className={`input-wrapper ${className}`}>
        <input readOnly={readOnly} name={name} value={value} onChange={(e) => controlChange(e)} placeholder={placeholder} type={type} />
      </div>
      {fieldError && <p className='text-[#dc5c5c] text-sm'>{fieldError}</p>}
    </>
  );
};
export default Input;

Input.propTypes = {
  validation: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  border: PropTypes.string,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string,
  brRadius: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
};
