import React, { useState } from 'react';
import InputDateRange from '../../../components/basic/input-date-range/InputDateRange';
import GetReportDataTable from '../dependencies/GetReportDataTable';

export default function ReportGenerateDefault() {
  const [activeTab, setActiveTab] = useState(typeList[0]);
  const [customDate, setCustomDate] = useState({ start_time: 0, end_time: (new Date().getTime() / 1000).toFixed(0) });

  const handleCustomSearch = ({ data: dateRange = {} }) => {
    const { start_time = '', end_time = '' } = dateRange;
    let startTime = new Date(start_time).getTime() / 1000;
    let endTime = new Date(end_time).getTime() / 1000;
    setCustomDate((state) => ({ ...state, start_time: startTime, end_time: endTime }));
  };

  return (
    <>
      <div className='parameter-tab mb-4'>
        <ul className='flex items-center flex-wrap lg:flex-nowrap [&>*:first-child]:rounded-tl-md [&>*:last-child]:rounded-tr-md lg:justify-evenly gap-[1px]'>
          {typeList?.map((tb, i) => (
            <li key={i} onClick={() => setActiveTab(tb)} className={'text-white lg:w-full text-center whitespace-nowrap px-2 py-1 cursor-pointer ' + (tb.id === activeTab.id ? 'bg-secondary-800' : 'bg-primary-700')}>
              {tb.icon ? <div className='w-5'>{tb.icon} </div> : tb.label}
            </li>
          ))}
        </ul>
      </div>
      <div>
        {activeTab.value === 'custom' && (
          <div className='form-wrapper w-max'>
            <InputDateRange action={handleCustomSearch} />
          </div>
        )}
      </div>
      <div className='mb-4'>
        <GetReportDataTable query={customDate} endPont='report/generator-runtime-summary' tableHeading='Generator run time summary:' type={activeTab.value} />
      </div>

      <div className='mb-4'>
        <GetReportDataTable query={customDate} endPont='report/generator-date-wise-summary' tableHeading='Generator date wise summary:' type={activeTab.value} />
      </div>
    </>
  );
}

const typeList = [
  { id: 1, value: 'today', label: 'Today ' },
  { id: 2, value: 'yesterday', label: 'Yesterday ' },
  { id: 3, value: 'this_month', label: 'This Month ' },
  { id: 4, value: 'previous_month', label: 'Previous Month ' },
  { id: 5, value: 'last_3_months', label: 'Last 03 Month ' },
  { id: 6, value: 'this_year', label: 'This Year ' },
  { id: 7, value: 'previous_year', label: 'Previous Year ' },
  { id: 8, value: 'all', label: 'All ' },
  { id: 9, value: 'custom', label: 'Custom ' },
];
