import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import RootElement from '../pages/root-element/RootElement';
const Login = React.lazy(() => import('../pages/login/Login'));
const PasswordResetIndex = React.lazy(() => import('../pages/user/password-reset/index.js'));
const PasswordResetForm = React.lazy(() => import('../pages/user/password-reset/PasswordResetForm'));

export function router() {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route path='*' element={<RootElement />}>
        <Route path='user/auth/password/reset' element={<PasswordResetIndex />} />
        <Route path='user/auth/reset-password/:token/:email' element={<PasswordResetForm />} />
        <Route path='login' element={<Login />} />
      </Route>
    )
  );
  return routes;
}
