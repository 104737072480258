/* eslint-disable react/button-has-type */

import './Button.css';

export default function Button({ children, title = '', className = '', onClick = () => {} }) {
  return (
    <div tabIndex='0' role='button' aria-pressed='false' onClick={onClick} className={`button ${className}`}>
      <button>{children ? children : title}</button>
    </div>
  );
}
