import { createSlice } from '@reduxjs/toolkit';

const userTypeDefaultFeaturesSlice = createSlice({
  name: 'userTypeDefaultFeatures',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    userTypeDefaultFeaturesInit: (state) => {
      return { ...state, isLoading: true };
    },
    userTypeDefaultFeaturesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    userTypeDefaultFeaturesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    userTypeDefaultFeaturesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    userTypeDefaultFeaturesDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    userTypeDefaultFeaturesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { userTypeDefaultFeaturesInit, userTypeDefaultFeaturesSuccess, userTypeDefaultFeaturesFail, userTypeDefaultFeaturesDelInit, userTypeDefaultFeaturesDelSuccess, userTypeDefaultFeaturesDelFail } = userTypeDefaultFeaturesSlice.actions;
export default userTypeDefaultFeaturesSlice.reducer;
