import React from 'react';
import './DashBoardCard.css';

export default function DashBoardCard({ item = {}, children, className = '', onClick = () => {} }) {
  return (
    <div onClick={onClick} className={`dash-board-card ${className}`}>
      <hgroup className='flex items-center'>
        {item.icon} <h2 className='ml-2'>{item.title}</h2>
      </hgroup>
      {children}
    </div>
  );
}
