import React, { useEffect } from 'react';
import Input from '../Input/Input';
import Button from '../button/Button';
import { useFormData } from '../../../hooks/hooks';
import { validation } from '../../common/forms/validator';

export default function InputDateRange({ action = () => {}, onChange = () => {}, btnLabel = 'Search', className = '', showTime = false }) {
  const { formData, error, handleChange, handleSubmit } = useFormData({ start_time: { value: '', required: true }, end_time: { value: '', required: true } });

  if (!action) {
    onChange(formData);
  }

  return (
    <div className='full h-full'>
      <div className={`flex flex-col md:flex-row gap-4 items-baseline ${className}`}>
        <div>
          <label htmlFor=''>Start Time</label>
          <Input
            value={formData.start_time.value || ''}
            fieldError={formData.start_time?.error || false}
            validation={validation.date}
            name='start_time'
            onChange={handleChange}
            type={`${showTime ? 'datetime-local' : 'date'}`}
            className='custom-input'
          />
        </div>
        <div>
          <label htmlFor=''>End Time</label>
          <Input value={formData.end_time.value || ''} fieldError={formData.end_time?.error || false} validation={validation.date} name='end_time' onChange={handleChange} type={`${showTime ? 'datetime-local' : 'date'}`} className='custom-input' />
        </div>
      </div>
      {action && <Button onClick={() => handleSubmit(action)} title={btnLabel} className='mt-4 h-8 btn-success' />}
    </div>
  );
}
