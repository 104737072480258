import React from 'react';
import Star from '../../../../../components/common/star';
import { useSelector } from 'react-redux';
import Battery from '../../../../../assets/icons/Battery';
import ChartForArea from '../../../../../components/common/cards/chart-cards/area/ChartForArea';
import NotificationCard from '../../../../../components/common/cards/notification';
import { getFormattedTime, parseDateTime } from '../../../../../utils/utils';
import { useParams } from 'react-router-dom';
import Suspender from '../../../../../components/common/suspender/Suspender';
import Switch from '../../../../../components/basic/switch/Switch';

export default function DetailSummaryPower() {
  const params = useParams();

  const {
    response: { data: alerts = [] },
  } = useSelector((state) => state.alerts);

  const {
    isLoading,
    isError,
    response: { data: singleDevice },
  } = useSelector((state) => state.deviceSummary);

  const { fuel_percentage = 0, fuel_consumed = 0, fuel_ltr = 0, battery_voltage = 100, duration = 0, energy_consumed = 0, health: { next_maintenance = 0, health_condition = 0 } = {}, running = 0 } = singleDevice || {};

  return (
    <Suspender isError={isError} isLoading={isLoading}>
      <div className='mb-6 -mt-2 flex items-center justify-between gap-4'>
        <h3 className='opacity-70'>Generator Name- {params.pg_number || ''}</h3>
        <ul className='flex items-center w-[200px] justify-evenly'>
          <li className='font-semibold text-lg'>Status</li>
          <Switch onClick={() => null} barRatio='w-3/5' item={{ value: running }} className='w-6 h-4' />
          <li className='text-xs hidden xs:inline'>Currently {running ? 'On' : 'Off'}</li>
        </ul>
      </div>

      <div className='equal-row-height grid grid-cols-12 gap-4 '>
        <div className='col-span-12 md:col-span-6 lg:col-span-3 order-2 lg:order-1'>
          <div className='p-4 text-center flex flex-col justify-around bg-primary-200 dark:bg-primary-800 custom-shadow rounded-lg'>
            <ul>
              <Star size={8}>
                <p className='text-xs'>Fuel Status</p>
              </Star>
              <p className='text-2xl font-semibold text-[#428F2E]'>{fuel_percentage}%</p>
              <p className='text-xs'>( {fuel_ltr} ltr )</p>
              <Star size={8} className='text-center'>
                <p className='text-xs mt-3'>{running ? 'Fuel Consumed' : 'Last Fuel Consumed'}</p>
              </Star>
              <span> ( {fuel_consumed} ltr )</span>
            </ul>
            <ul>
              <p className='text-xs mb-2'>Battery Status</p>
              <ul className='mx-auto max-w-[140px] flex items-center justify-between'>
                <Battery percentage={battery_voltage} />
                <p className='text-2xl font-semibold text-[#428F2E]'>{battery_voltage.toFixed(0)}%</p>
              </ul>
            </ul>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6 lg:col-span-6 order-1 lg:order-2'>
          <div className='flex flex-col gap-4'>
            <h3 className=''>{running ? 'Current Activity' : 'Last Activity'}</h3>

            <div className='grid grid-flow-row grid-cols-12 gap-4'>
              <div className='col-span-12 lg:col-span-6'>
                <div className='relative p-4 bg-primary-50 dark:bg-primary-800 custom-shadow rounded-lg'>
                  <h3>Runtime</h3>
                  <p className='px-4 text-white rounded-md position-center text-xl bg-primary-800 bg-opacity-70 dark:bg-primary-900'>{getFormattedTime(duration)}</p>
                </div>
              </div>
              <div className='col-span-12 lg:col-span-6'>
                <ChartForArea options={{ data: tempData, height: 70 }} className='custom-shadow bg-primary-200 dark:bg-primary-800 custom-shadow rounded-lg fill-[#036B6B] dark:fill-[#19b8b8]'>
                  <div className='content px-4 py-2'>
                    <h3 className='px-3'>Power Generated</h3>
                    <p className='text-xs px-3'>{energy_consumed} kWh</p>
                  </div>
                </ChartForArea>
              </div>
            </div>
            <div className='flex-1 w-full'>
              <div className='h-full p-4 bg-primary-100 dark:bg-primary-800 custom-shadow rounded-lg'>
                <h3 className='text-center'>Generator Condition</h3>
                <div className='mt-10 text-center flex items-center justify-around flex-wrap gap-4'>
                  <ul>
                    <p className='text-xs'>Overall Health</p>
                    <p className='text-2xl font-semibold text-[#428F2E]'>{health_condition}%</p>
                  </ul>
                  <ul>
                    <p className='text-xs'>Next Maintenance Date</p>
                    <p className='text-2xl font-semibold text-[#428F2E]'>{next_maintenance ? parseDateTime(new Date(`${next_maintenance}`).getTime() / 1000, 'D/MM/YYYY').date : 'Not yet maintenance'}</p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6 lg:col-span-3 order-3'>
          <div className='p-4 rounded-lg custom-shadow bg-primary-50 dark:bg-primary-800'>
            <NotificationCard contentClass='max-h-[270px] custom-scroll' notifications={alerts?.filter((it) => it.pg_number === params.pg_number)?.slice(0, 4)} nextUrl='../notifications' />
          </div>
        </div>
      </div>
    </Suspender>
  );
}

export const tempData = [
  {
    name: 'Page A',
    uv: 5,
    pv: 10,
    amt: 2,
  },
  {
    name: 'Page B',
    uv: 8,
    pv: 2,
    amt: 7,
  },
  {
    name: 'Page C',
    uv: 10,
    pv: 7,
    amt: 5,
  },
  {
    name: 'Page D',
    uv: 16,
    pv: 3,
    amt: 2,
  },
  {
    name: 'Page E',
    uv: 20,
    pv: 4,
    amt: 2,
  },
];
