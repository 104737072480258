import React, { useEffect, useState } from 'react';

export default function Switch({ className = '', item = { value: false }, keyName = 'value', onSwitch = (parm) => {}, barRatio = 'w-2/5', onClick = null }) {
  const [switchItem, updateSwitchItem] = useState(item);

  const handleClick = () => {
    onSwitch({ ...switchItem, [keyName]: !switchItem[keyName] });
    updateSwitchItem((state) => ({ ...state, [keyName]: !state[keyName] }));
  };
  useEffect(() => {
    updateSwitchItem(item);
  }, [item]);
  return (
    <div onClick={onClick ? onClick : handleClick} className={`transition-all duration-300 p-2 relative justify-end rounded-lg bg-[#D9D9D9] ${className}`}>
      <div className={`${barRatio} absolute left-0.5 top-1/2 -translate-y-1/2 aspect-square transition-all duration-300 rounded-full ${switchItem[keyName] ? 'left-[calc(50%-2px)] bg-[#105320]' : 'bg-[#b4b2b2] '}`}></div>
    </div>
  );
}
