import { createSlice } from '@reduxjs/toolkit';

const fuelDataSlice = createSlice({
  name: 'fuelData',
  initialState: { isLoading: true, response: { data: [] || {}, pagination: {} }, isError: false },
  reducers: {
    fuelDataInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelDataSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    fuelDataFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    fuelDataDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelDataDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    fuelDataDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { fuelDataInit, fuelDataSuccess, fuelDataFail, fuelDataDelInit, fuelDataDelSuccess, fuelDataDelFail } = fuelDataSlice.actions;
export default fuelDataSlice.reducer;
