import { createSlice } from '@reduxjs/toolkit';

const serviceSlice = createSlice({
  name: 'service',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    serviceInit: (state) => {
      return { ...state, isLoading: true };
    },
    serviceSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    serviceFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    serviceDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    serviceDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    serviceDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { serviceInit, serviceSuccess, serviceFail, serviceDelInit, serviceDelSuccess, serviceDelFail } = serviceSlice.actions;
export default serviceSlice.reducer;
