import { createSlice } from '@reduxjs/toolkit';

const towerCompaniesSlice = createSlice({
  name: 'towerCompanies',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    towerCompaniesInit: (state) => {
      return { ...state, isLoading: true };
    },
    towerCompaniesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    towerCompaniesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
    towerCompaniesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    towerCompaniesDelSuccess: (state, action) => {
      return {
        ...state,
        response: state.response.filter((item) => !action.payload.data.includes(item.id)),
        isLoading: false,
      };
    },
    towerCompaniesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { towerCompaniesInit, towerCompaniesSuccess, towerCompaniesFail, towerCompaniesDelInit, towerCompaniesDelSuccess, towerCompaniesDelFail } = towerCompaniesSlice.actions;
export default towerCompaniesSlice.reducer;
