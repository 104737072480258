import React from 'react';

export default function Battery({ percentage = 70 }) {
  //   const maxBar = 6;
  const actualBar = ((6 / 100) * percentage).toFixed(0);
  const paths = [
    <path
      key={0}
      d='M11.0308 3.79224L11.0308 32.0576H10.3643C8.68999 32.0576 7.08428 31.3906 5.90039 30.2034C4.71649 29.0162 4.05138 27.4061 4.05138 25.7272L4.05138 10.1226C4.05138 8.44372 4.71649 6.83355 5.90039 5.64637C7.08428 4.45919 8.68999 3.79224 10.3643 3.79224H11.0308Z'
      fill='#75BE44'
    />,
    <path key={1} d='M20.6311 32.0651V3.79224L13.6517 3.79224L13.6517 32.0651H20.6311Z' fill='#75BE44' />,
    <path key={2} d='M30.2314 32.0651V3.79224L23.2521 3.79224V32.0651H30.2314Z' fill='#75BE44' />,
    <path key={3} d='M40.094 32.2153V3.94243L33.1146 3.94243V32.2153H40.094Z' fill='#75BE44' />,
    <path key={4} d='M 49.6943 32.2153 V 3.94243 L 42.715 3.94243 V 32.2153 H 49.6943 Z' fill='#75BE44' />,
    <path key={5} d='M 59.6943 32.2153 V 3.94243 L 52.715 3.94243 V 32.2153 H 59.6943 Z' fill='#75BE44' />,
  ];
  return (
    <svg width='70' height='36' viewBox='0 0 70 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_53_1343)'>
        <path
          d='M0 28.5357V7.50939C0 5.51777 0.788976 3.60773 2.19336 2.19945C3.59775 0.791165 5.5025 0 7.4886 0L56.1645 0C58.1506 0 60.0554 0.791165 61.4598 2.19945C62.8642 3.60773 63.6531 5.51777 63.6531 7.50939V28.5357C63.6531 29.5218 63.4594 30.4983 63.0831 31.4094C62.7068 32.3205 62.1552 33.1483 61.4598 33.8456C60.7644 34.5429 59.9389 35.0961 59.0303 35.4734C58.1217 35.8508 57.1479 36.0451 56.1645 36.0451H7.4886C5.5025 36.0451 3.59775 35.2539 2.19336 33.8456C0.788976 32.4373 0 30.5273 0 28.5357ZM61.751 7.50939C61.752 6.77415 61.6081 6.04596 61.3275 5.36669C61.0469 4.68742 60.6352 4.07045 60.1161 3.55126C59.5969 3.03206 58.9806 2.62088 58.3024 2.34134C57.6243 2.06181 56.8977 1.91943 56.1645 1.9224H7.4886C6.75666 1.92141 6.03172 2.06525 5.35531 2.34567C4.6789 2.6261 4.0643 3.03759 3.54675 3.55659C3.02919 4.07558 2.61883 4.69188 2.33918 5.37017C2.05954 6.04846 1.9161 6.77542 1.91708 7.50939V28.5357C1.92697 30.0109 2.51833 31.4224 3.56212 32.462C4.60592 33.5017 6.01741 34.0851 7.4886 34.0851H56.1645C57.6383 34.0891 59.0537 33.5074 60.1007 32.4673C61.1477 31.4272 61.7411 30.0135 61.751 28.5357V7.50939Z'
          fill='#888383'
        />
        <path
          d='M68.9925 16.6333V19.3667C68.9935 19.9101 68.8877 20.4483 68.6813 20.9507C68.4748 21.4531 68.1717 21.9098 67.7893 22.2947C67.4068 22.6796 66.9525 22.9852 66.4522 23.1941C65.952 23.4029 65.4156 23.5109 64.8738 23.5119H64.2372C63.7228 23.5119 63.2295 23.307 62.8658 22.9422C62.502 22.5775 62.2977 22.0828 62.2977 21.567V14.4481C62.2977 13.9322 62.502 13.4375 62.8658 13.0728C63.2295 12.708 63.7228 12.5031 64.2372 12.5031H64.8738C65.9681 12.5031 67.0176 12.9391 67.7915 13.715C68.5653 14.491 69 15.5434 69 16.6408L68.9925 16.6333Z'
          fill='#888383'
        />
        {paths.slice(0, actualBar).map((pa, i) => pa)}
      </g>
      <defs>
        <clipPath id='clip0_53_1343'>
          <rect width='70' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
