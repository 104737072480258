import { createSlice } from '@reduxjs/toolkit';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    reportsInit: (state) => {
      return { ...state, isLoading: true };
    },
    reportsSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    reportsFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    reportsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    reportsDelSuccess: (state, action) => {
      return {
        ...state,
        response: state.response.filter((item) => !action.payload.data.includes(item.pg_number)),
        isLoading: false,
      };
    },
    reportsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { reportsInit, reportsSuccess, reportsFail, reportsDelInit, reportsDelSuccess, reportsDelFail } = reportsSlice.actions;
export default reportsSlice.reducer;
