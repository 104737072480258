import { createSlice } from '@reduxjs/toolkit';

const pgsSlice = createSlice({
  name: 'pgs',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    pgsInit: (state) => {
      return { ...state, isLoading: true };
    },
    pgsSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    pgsFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    pgsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    pgsDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.pg_number)) },
        isLoading: false,
      };
    },
    pgsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { pgsInit, pgsSuccess, pgsFail, pgsDelInit, pgsDelSuccess, pgsDelFail } = pgsSlice.actions;
export default pgsSlice.reducer;
