import React, { useEffect, useState } from 'react';
import Parameters from './dependencies/parameters';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/doubleDown.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/icons/doubleUp.svg';
import { useSelector } from 'react-redux';
import { checkEmpty } from '../../../../../utils/utils';

export default function ParameterTabs() {
  const [activeTab, setActiveTab] = useState(paramList[0]);
  const [activeData, setActiveData] = useState({});
  const [miniMize, setMinimize] = useState(false);
  const { response: { data: advanceData } = { data: {} } } = useSelector((state) => state.deviceSummary || {});
  const { details_values = '""' } = advanceData || {};

  const jsonData = React.useMemo(() => {
    return JSON.parse(details_values || '""');
  }, [details_values]);

  useEffect(() => {
    let newData = {};
    if (activeTab.id === 2) {
      newData = {
        frequency: [{ val: advanceData['hz'], unit: 'Hz' }],
        phase_to_neutral_voltages: [
          { val: advanceData['l1_n'] || jsonData['l1_n'] || 0, unit: 'V', label: 'L1-N' },
          { val: advanceData['l2_n'] || jsonData['l2_n'] || 0, unit: 'V', label: 'L2-N' },
          { val: advanceData['l3_n'] || jsonData['l3_n'] || 0, unit: 'V', label: 'L3-N' },
        ],
        phase_to_phase_voltages: [
          { val: advanceData['l1_l2'] || jsonData['l1_l2'] || 0, unit: 'V', label: 'L1-L2' },
          { val: advanceData['l2_l3'] || jsonData['l2_l3'] || 0, unit: 'V', label: 'L2-L3' },
          { val: advanceData['l3_l1'] || jsonData['l3_l1'] || 0, unit: 'V', label: 'L3-L1' },
        ],
        current: [
          { val: advanceData['i1'] || jsonData['i1'] || 0, unit: 'A', label: 'L1' },
          { val: advanceData['i2'] || jsonData['i2'] || 0, unit: 'A', label: 'L2' },
          { val: advanceData['i3'] || jsonData['i3'] || 0, unit: 'A', label: 'L3' },
        ],
        accumulated_power_kwh: [
          { val: advanceData['p_kwh'] || jsonData['p_kwh'] || 0, unit: 'kwh', label: 'L1' },
          { val: advanceData['p_kvh'] || jsonData['p_kvh'] || 0, unit: 'kvh', label: 'L2' },
          { val: advanceData['p_kvrh'] || jsonData['p_kvrh'] || 0, unit: 'kvrh', label: 'L3' },
        ],
        power_factor: [{ val: advanceData['power_factor'] || jsonData['pf'] || 0 }],
        active_power_kw: [{ val: advanceData['p_kw'] || jsonData['p_kw'] || 0, unit: 'kw' }],
      };
    } else if (activeTab.id === 3) {
      newData = {
        frequency: [{ val: advanceData['main_hz'] || jsonData['main_hz'] || 0, unit: 'Hz' }],
        phase_to_neutral_voltages: [
          { val: advanceData['main_l1_n'] || jsonData['main_l1_n'] || 0, unit: 'V', label: 'L1-N' },
          { val: advanceData['main_l2_n'] || jsonData['main_l2_n'] || 0, unit: 'V', label: 'L2-N' },
          { val: advanceData['main_l3_n'] || jsonData['main_l3_n'] || 0, unit: 'V', label: 'L3-N' },
        ],
        phase_to_phase_voltages: [
          { val: advanceData['main_l1_l2'] || jsonData['main_l1_l2'] || 0, unit: 'V', label: 'L1-L2' },
          { val: advanceData['main_l2_l3'] || jsonData['main_l2_l3'] || 0, unit: 'V', label: 'L2-L3' },
          { val: advanceData['main_l3_l1'] || jsonData['main_l3_l1'] || 0, unit: 'V', label: 'L3-L1' },
        ],
      };
    } else {
      newData = {
        engine_temperature: [{ val: advanceData['engine_temperature'] || jsonData['eng_temp'] || 0, unit: 'deg', label: '', percentage: '' }],
        engine_rpm: [{ val: advanceData['engine_rpm'] || jsonData['eng_rpm'] || 0, unit: 'RPM', label: '', percentage: '' }],
        battery_voltage: [{ val: advanceData['bat_volt'] || jsonData['bat_volt'] || 0, unit: 'V DC', label: '', percentage: '' }],
        oil_pressure: [{ val: advanceData['oil_pressure'] || jsonData['oil_pr'] || 0, unit: 'Pa' }],
        hours_run: [{ val: advanceData['hours_run'] || jsonData['hours_run'] || 0, unit: 'hours' }],
        fuel_level: [{ val: advanceData['fuel_level'] || jsonData['fuel_level'] || 0 }],
        number_of_starts: [{ val: advanceData['number_of_starts'] || jsonData['num_of_starts'] || 0 }],
        coolant_level: [{ val: advanceData['coolant_level'] || jsonData['cool_lev'] || 0 }],
        coolant_temperature: [{ val: advanceData['coolant_temperature'] || jsonData['cool_temp'] || 0, unit: 'Deg' }],
        charge_alternator: [{ val: advanceData['charge_alternator'] || jsonData['charge_alt'] || 0, unit: 'V DC' }],
      };
    }
    setActiveData(newData);
  }, [advanceData, activeTab, jsonData]);

  return (
    <div className='parameter-tab'>
      <ul className='flex items-center [&>*:first-child]:rounded-tl-md [&>*:nth-child(3)]:rounded-tr-md '>
        {paramList?.map((tb, i) => (
          <li key={i} onClick={() => setActiveTab(tb)} className={'text-white px-2 py-1 cursor-pointer ' + (tb.id === activeTab.id ? 'bg-secondary-600' : 'bg-primary-700')}>
            {tb.icon ? <div className='w-5'>{tb.icon} </div> : tb.title}
          </li>
        ))}
        <li onClick={() => setMinimize(!miniMize)} className='ml-auto p-1 border rounded-full cursor-pointer'>
          {miniMize ? <MinusIcon className='h-4 w-4 stroke-black dark:stroke-white' /> : <PlusIcon className='h-4 w-4 stroke-black dark:stroke-white' />}
        </li>
      </ul>
      {!miniMize && (
        <div className='tab-body'>
          <Parameters pageName='' data={activeData} />
        </div>
      )}
    </div>
  );
}

// export default React.memo(ParameterTab);

const paramList = [
  {
    id: 1,
    title: 'Engine Parameter',
    heading: '',
    data: {
      engine_temperature: '',
      engine_rpm: '',
      battery_voltage: '',
      oil_pressure: '',
      hours_run: '',
      speed: '',
      fuel_level: '',
      number_of_starts: '',
      coolant_level: '',
      coolant_temperature: '',
      charge_alternator: '',
    },
  },
  {
    id: 2,
    title: 'Generator Parameter',
    heading: '',
    data: {
      frequency: '',
      phase_to_neutral_voltages: [],
      phase_to_phase_voltages: [],
      current: [],
      accumulated_power_kwh: '',
      power_factor: '',
      active_power_kw: '',
    },
  },
  {
    id: 3,
    title: 'Mains Parameter',
    heading: '',
    data: {
      frequency: '',
      phase_to_neutral_voltages: [],
      phase_to_phase_voltages: [],
    },
  },
];
