import { createSlice } from '@reduxjs/toolkit';

const deviceLogsSlice = createSlice({
  name: 'deviceLogs',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    deviceLogsInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceLogsSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    deviceLogsFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    deviceLogsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceLogsDelSuccess: (state, action) => {
      let delItems = action.payload.data || [];
      let dataAfterDel = [...state.response.data]?.filter((item) => !delItems.includes(item.pg_number));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },
    deviceLogsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { deviceLogsInit, deviceLogsSuccess, deviceLogsFail, deviceLogsDelInit, deviceLogsDelSuccess, deviceLogsDelFail } = deviceLogsSlice.actions;
export default deviceLogsSlice.reducer;
