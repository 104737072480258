import { createSlice } from '@reduxjs/toolkit';

const activeDevices = createSlice({
  name: 'activeDevices',
  initialState: { isLoading: true, response: { data: [] || {}, pagination: {} }, isError: false },
  reducers: {
    activeDevicesInit: (state) => {
      return { ...state, isLoading: true };
    },
    activeDevicesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    activeDevicesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    activeDevicesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    activeDevicesDelSuccess: (state, action) => {
      let delItems = action.payload.data || [];
      let dataAfterDel = state.response.data?.filter((item) => !delItems.includes(item.device_id));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },
    activeDevicesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { activeDevicesInit, activeDevicesSuccess, activeDevicesFail, activeDevicesDelInit, activeDevicesDelSuccess, activeDevicesDelFail } = activeDevices.actions;
export default activeDevices.reducer;
