import React from 'react';
import { PropTypes } from 'prop-types';

export function ParameterCard({ fieldName = '', values = [] }) {
  return (
    <div className='custom-border custom-shadow opacity-90 overflow-hidden'>
      <h2 className='py-1 px-2 text-base bg-primary-100 dark:bg-primary-700'>{fieldName || '---'}</h2>
      <div className='h-10 flex items-start justify-around text-sm'>
        {values?.map((value, i) => (
          <ul key={i} className='self-center text-center'>
            {value.parameter && <li>{value.parameter}</li>}
            <li>
              {value.val || '---'} {value.val ? value.unit || '' : ''}
            </li>
            {value.percentage && <li>{value.percentage}</li>}
          </ul>
        ))}
      </div>
    </div>
  );
}
ParameterCard.propTypes = {
  heading: PropTypes.string,
  values: PropTypes.array,
};

export default function Parameters({ pageName = '', data = {} }) {
  return (
    <div className='p-4 bg-primary-50 dark:bg-primary-800 font-semibold '>
      {pageName && <h2 className='mb-3 py-1 px-2 custom-border custom-shadow text-lg bg-primary-100 dark:bg-primary-700 rounded-lg'>{pageName}</h2>}
      <div className='grid grid-flow-row grid-cols-12 gap-4'>
        {Object.keys(data)?.map((key, i) => (
          <ul key={i} className='col-span-12 lg:col-span-6 xl:col-span-4'>
            <ParameterCard
              fieldName={key
                .split('_')
                .map((it) => it[0].toUpperCase() + it.slice(1))
                .join(' ')}
              values={data[key]}
            />
          </ul>
        ))}
      </div>
    </div>
  );
}
