import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DayMonthChart from '../../../../home/sections/day_month_chart_notifications/dependencies/DayMonthChart';
import { useUserInfo } from '../../../../../hooks/hooks';
import { SUPER_ADMIN } from '../../../../../apis/apis';
import AddButton from '../../../../../components/common/cards/add-button/AddButton';
import Modal from '../../../../../components/basic/Modals/Modal';
import AddFuelForm from '../../../../../components/common/forms/add-fuel-form/AddFuelForm';
import Star from '../../../../../components/common/star';
import ChartForFuelEntries from '../../../../../components/common/cards/chart-cards/bar/ChartForFuelEntry';

export default function DayMonthFuelEntryChart() {
  const [modalFormCreate, setModalFormCreate] = useState(null);
  const [activeFuelEntry, setActiveFuelEntry] = useState('normal');
  const { user_type } = useUserInfo();
  const params = useParams();

  return (
    <>
      {modalFormCreate && (
        <Modal onClose={() => setModalFormCreate(null)}>
          <div className='md:w-[400px] p-4'>
            <div className='mb-10 rounded-lg px-10 py-4 '>
              <AddFuelForm role='add' onClose={() => setModalFormCreate(null)} />
            </div>
          </div>
        </Modal>
      )}
      <div className='equal-row-height grid grid-cols-12 gap-4'>
        <div className='col-span-12 lg:col-span-6'>
          <DayMonthChart fuel={true} power runtime params={params} />
        </div>

        <div className='col-span-12 lg:col-span-6'>
          <div className='relative custom-background'>
            {user_type === SUPER_ADMIN && (
              <div className='absolute right-4 top-4 z-50 w-6 h-6'>
                <AddButton onClick={() => setModalFormCreate(true)} />
              </div>
            )}
            <ul role='menubar' className='list-group absolute z-10 text-[11px] font-medium items-start xs:items-center top-5 right-12 flex flex-col sm:flex-row gap-2'>
              <li role='menuitem' onClick={() => setActiveFuelEntry('normal')} className={`cursor-pointer ${activeFuelEntry.includes('normal') ? 'opacity-100' : 'opacity-50'}`}>
                <Star position='-left-3' label='Fuel Filled' />
              </li>
              <li role='menuitem' onClick={() => setActiveFuelEntry('anomaly')} className={`cursor-pointer ${activeFuelEntry.includes('anomaly') ? 'opacity-100' : 'opacity-50'}`}>
                Fuel Anomaly
              </li>
              <li role='menuitem' onClick={() => setActiveFuelEntry('all')} className={`cursor-pointer ${activeFuelEntry.includes('all') ? 'opacity-100' : 'opacity-50'}`}>
                All
              </li>
            </ul>
            <ChartForFuelEntries type={activeFuelEntry} pg_number={params.pg_number} />
          </div>
        </div>
      </div>
    </>
  );
}
