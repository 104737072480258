import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, Brush, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalize, checkEmpty, getDaysInMonth, getFormattedServiceHours, parseDateTime } from '../../../../../utils/utils';
import { downloadApi, downloadChartData, getHourlyChartData } from '../../../../../apis/apis';
import { useQueryParm } from '../../../../../hooks/hooks';
import Button from '../../../../basic/button';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter.svg';
import Modal from '../../../../basic/Modals/Modal';
import DateRangePicker from '../../../date-picker/DateRangePicker';
import NoData from '../../../no-data/NoData';
import DownloadButton from '../../../download/DownloadButton';
import InputDateRange from '../../../../basic/input-date-range/InputDateRange';
import { useCallback } from 'react';

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='end' fill='#666' transform='rotate(-60)'>
        {payload.value}
      </text>
    </g>
  );
};

export default function ChartForFuelInHour({ pg_number = null }) {
  const [query, updateQuery] = useQueryParm({ pg_number: pg_number });
  const [chartData, updateChartData] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const handleCustomDuration = ({ data: dateRange }) => {
    const { start_time = '', end_time = '' } = dateRange;
    setModalShow(false);
    updateQuery({ ...query, start_time: new Date(start_time).getTime() / 1000, end_time: new Date(end_time).getTime() / 1000 });
  };

  const getData = useCallback(async (options = {}) => {
    const res = await getHourlyChartData(options);
    let tempData = [];
    if (!checkEmpty(res.data.status)) {
      res.data.data?.forEach((it, i) => {
        let d = new Date(it.hour).getTime() / 1000;
        let x = parseDateTime(d, 'MMM D', 'hh a');
        tempData.push({ label_x: `${x.date}, ${x.time}`, total_time: Number(it.total_time_value), fuel_value: it.total_fuel_value });
      });
      updateChartData(tempData);
    }
  }, []);

  useEffect(() => {
    getData(query);
  }, [query, getData]);

  return (
    <div className='custom-background relative'>
      {modalShow && (
        <Modal onClose={() => setModalShow(false)}>
          <div className='p-4 position-center bg-primary-700 rounded-lg'>
            <h2 className='mb-3'>Choose Date Range</h2>
            <InputDateRange action={handleCustomDuration} />
          </div>
        </Modal>
      )}
      <ul className='flex items-center justify-end gap-4'>
        <li className='flex-1'>Run Time vs Hourly Fuel Consumed</li>
        <li onClick={() => setModalShow(true)} className={`cursor-pointer border`}>
          <FilterIcon className='w-6 h-6 stroke-path' />
        </li>
        <DownloadButton action={() => downloadApi('v2/device-data/hourly', { ...query })} />
      </ul>
      {checkEmpty(chartData) && (
        <div className='position-center'>
          <NoData />
        </div>
      )}
      <div className='w-full text-xs custom-scroll'>
        <BarChart
          className='mx-auto'
          width={900}
          height={350}
          data={chartData}
          barGap={0}
          barCategoryGap={4}
          barSize={10}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray='1 1' /> */}
          <XAxis dataKey='label_x' interval={0} height={80} tick={CustomizedAxisTick} domain={['auto', 'auto']} />
          {/* <YAxis dataKey='fuel' /> */}
          <YAxis
            label={{
              value: 'Total Time (hr)',
              angle: -90,
              position: 'insideLeft',
              fontSize: '10px',
              className: 'stroke-[#38353552] dark:stroke-[#ffffffad] ',
            }}
            yAxisId='left'
            dataKey='total_time'
            orientation='left'
            domain={[0, (dataMax) => Math.ceil(dataMax * 1.5)]}
          />
          <YAxis yAxisId='fuel_value' dataKey='fuel_value' hide orientation='right' domain={[0, (dataMax) => Math.ceil(dataMax * 1.5)]}>
            <Label style={{ right: 20 }} value='Fuel Consumed (Ltr)' position='inside' angle={-90} className='stroke-[#38353552] dark:stroke-[#ffffffad] ' fontSize={10} />
          </YAxis>
          {/* <CartesianGrid vertical={false} className='dark:stroke-[#f9f8f80d] stroke-[#ede6e65f]' strokeDasharray='0' /> */}

          <Tooltip
            cursor={{ fill: 'transparent' }}
            wrapperStyle={{ outline: 'none', border: 0 }}
            wrapperClassName='shadow-md'
            contentStyle={{ padding: '16px', border: 0, borderRadius: '4px', overflow: 'hidden' }}
            labelStyle={{ color: 'black', fontWeight: '500' }}
            formatter={formatter}
          />
          <Bar yAxisId='left' dataKey='total_time' fill='#17a2b8' />
          <Bar yAxisId='fuel_value' dataKey='fuel_value' fill='#AD83F6' orientation='right' />
          <Brush travellerWidth={10} className='fill-primary-400 dark:fill-primary-600' dataKey='label_x' height={20} />
        </BarChart>
      </div>
    </div>
  );
}

const formatter = (value, name, props) => {
  if (name === 'total_time') return [`${getFormattedServiceHours(props.payload.total_time)}`, `Total Run hour`];
  if (name === 'fuel_value') return [`${props.payload.fuel_value} Ltr`, `Fuel Consumed`];
  return [`${value}`, `${capitalize(name).split('_').join(' ')}`];
};
