import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clockIcon.svg';
import { ReactComponent as SunIcon } from '../../../assets/icons/darkIcon.svg';
import { ReactComponent as ThreeDots } from '../../../assets/icons/dots.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/downArrow.svg';
import { ReactComponent as HomeIcon } from '../../../assets/icons/homeIcon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logoutIcon.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menuIcon.svg';
import { ReactComponent as NotifyIcon } from '../../../assets/icons/notifyIcon.svg';
import { ReactComponent as ProfileSetIcon } from '../../../assets/icons/setting.svg';
import { ReactComponent as SettingIcon } from '../../../assets/icons/setting3.svg';
import { ReactComponent as Logo } from '../../../assets/icons/think.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/unlock.svg';
import userIcon from '../../../assets/icons/userIcon.png';
import { useDarkMode } from '../../../hooks/hooks';
import { fullName, parseDateTime } from '../../../utils/utils';
import DropDown from '../../basic/drop-down/DropDown';
import Search from '../../basic/search-input/Search';
import './Navbar.css';
import { logout } from '../../../slices/user-info';
import LocalSearch from '../../basic/local-search/LocalSearch';

export default function Navbar({ sideBarHandle = () => null }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, darkToggle] = useDarkMode();
  const { user } = useSelector((state) => state.userInfo);
  const { user_type, first_name, last_name } = user.data;

  const today = React.useMemo(() => {
    return new Date().getTime() / 1000;
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOnLogo = () => {
    navigate('/');
  };

  const leftIcon = () => <DownIcon className='downIcon' width='12' height='12' />;
  const rightIcon = () => (
    <div className='p-1 w-8 h-8'>
      <img className='w-full h-full rounded-full' src={userIcon} alt='profile-pic' />
    </div>
  );
  const dots = () => <ThreeDots className='w-10 fill-secondary-600 ' />;

  const sun = {
    title: 'Sun',
    icon: <SunIcon className='cursor-pointer' />,
    onClick: darkToggle,
  };

  const setting = {
    title: 'Settings',
    icon: <SettingIcon className=' stroke-path' />,
    onClick: () => null,
  };

  const clock = {
    title: 'Clock',
    icon: <ClockIcon className='cursor-pointer' />,
    onClick: () => window.location.reload(),
  };

  const notification = {
    title: 'Notification',
    icon: <NotifyIcon onClick={() => {}} className=' stroke-path cursor-pointer' />,
    onClick: () => navigate('notifications'),
  };

  const newsFeed = {
    title: 'News Feed',
    icon: <HomeIcon />,
    onClick: () => null,
  };
  const passwordChange = {
    title: 'Password Change',
    icon: <LockIcon className='stroke-path' />,
    onClick: () => navigate('user/profile/password/change'),
  };
  const profileUpdate = {
    title: 'Profile Update',
    icon: <ProfileSetIcon />,
    onClick: () => navigate('user/profile/update'),
  };
  const logout_icon = {
    title: 'Logout',
    icon: <LogoutIcon onClick={handleLogout} className='stroke-path w-8' />,
    onClick: handleLogout,
  };
  const DropDownChildrenRender = (items = [], withLevel = false) => {
    return (
      <div className='p-3 dark:bg-primary-800 bg-primary-200 w-[180px] custom-shadow'>
        <ul className='text-sm list-none'>
          {items?.map((item, i) => (
            <li onClick={item.onClick} key={i} className='cursor-pointer my-3 flex items-center '>
              <span className='mr-2 w-6 text-end '>{item.icon}</span> {withLevel && item.title}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className='navbar'>
      <div onClick={sideBarHandle} className='mx-6 inline cursor-pointer'>
        <MenuIcon className='w-7 h-auto rounded-full p-1 border stroke-secondary-600 border-secondary-600' />
      </div>
      <div className='hidden lg:inline w-[170px]'>
        <Logo onClick={handleClickOnLogo} width={80} height={30} className='fill-black dark:fill-white cursor-pointer' />
      </div>
      <div className='mr-6 hidden xl:inline'>
        <p>{parseDateTime(today, 'ddd, D MMMM, YYYY').date}</p>
      </div>
      <div className='hidden xs:inline'>
        <LocalSearch displayKey='pg_name' apiEndpoint='pg-name/all' />
      </div>
      <ul className='flex ml-auto'>
        {[sun, clock, notification, newsFeed]?.map((icon, i) => (
          <li onClick={icon.onClick} className='icon ml-2 ' key={i}>
            <span>{icon.icon}</span>
          </li>
        ))}
      </ul>
      <div className='hidden lg:inline'>
        <DropDown bodyClass='right-0' className='ml-2 text-lg mr-4 border border-primary-400 px-2 py-1 rounded-lg' item={{ id: 1, title: fullName(first_name, last_name), leftIcon: leftIcon, rightIcon: rightIcon }}>
          {DropDownChildrenRender([profileUpdate, passwordChange, notification, setting, logout_icon], true)}
        </DropDown>
      </div>
      <div className='hidden lg:inline'>
        <LogoutIcon className='w-5 ml-auto logoutIcon cursor-pointer' onClick={handleLogout} />
      </div>
      <div className='inline lg:hidden'>
        <DropDown bodyClass='right-0' item={{ rightIcon: dots }}>
          {DropDownChildrenRender([profileUpdate, passwordChange, notification, setting, logout_icon], true)}
        </DropDown>
      </div>
    </div>
  );
}
