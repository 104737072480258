import { getThisMonth, getToday } from '../utils/utils';

export const quarterData = [
  {
    id: 1,
    name: 'Jan',
    uv: 4000,
    fill: '#70BDD1',
  },
  {
    id: 2,
    name: 'Feb',
    uv: 3500,
    fill: '#b0c978',
  },
  {
    id: 3,
    name: 'Mar',
    uv: 2200,
    fill: '#f9ab6b',
  },
];

export const optionsByMonthQuarter = [
  { id: 1, title: 'Jan-Mar', value: 1 },
  { id: 2, title: 'Apr-Jul', value: 2 },
  { id: 3, title: 'Aug-Sep', value: 3 },
  { id: 4, title: 'Oct-Dec', value: 4 },
];
export const optionsByYear = [
  { id: 11, title: '2030 ', value: 2030 },
  { id: 12, title: '2029 ', value: 2029 },
  { id: 13, title: '2028', value: 2028 },
  { id: 14, title: '2027', value: 2027 },
  { id: 15, title: '2026', value: 2026 },
  { id: 16, title: '2025', value: 2025 },
  { id: 17, title: '2024', value: 2024 },
  { id: 18, title: '2023', value: 2023 },
  { id: 19, title: '2022', value: 2022 },
  { id: 20, title: '2021', value: 2021 },
  { id: 21, title: ' 2020', value: 2020 },
];

export const optionsByDay = [
  { id: 2, title: 'Last 1 day', value: 24 * 60 * 60 },
  { id: 3, title: 'Last 15 day', value: 15 * 24 * 60 * 60 },
  { id: 4, title: 'Last 30 day', value: 30 * 24 * 60 * 60 },
  { id: 5, title: 'Last 3 month', value: 90 * 24 * 60 * 60 },
  { id: 6, title: 'Last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 9, title: 'Last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 10, title: 'All', value: new Date().getTime() / 1000 },
];

export const daySummary = [
  {
    name: 'Page A',
    uv: 5,
    pv: 10,
    amt: 2,
  },
  {
    name: 'Page B',
    uv: 4,
    pv: 2,
    amt: 7,
  },
  {
    name: 'Page C',
    uv: 3,
    pv: 7,
    amt: 5,
  },
  {
    name: 'Page D',
    uv: 6,
    pv: 3,
    amt: 2,
  },
  {
    name: 'Page E',
    uv: 9,
    pv: 4,
    amt: 2,
  },
  {
    name: 'Page F',
    uv: 6,
    pv: 3,
    amt: 2,
  },
  {
    name: 'Page G',
    uv: 4,
    pv: 3,
    amt: 1,
  },
];
export const optionsByHour = [
  { id: 1, title: 'Today', value: (getToday() / 1000) | 0 },
  { id: 2, title: 'This Month', value: (getThisMonth() / 1000) | 0 },
  { id: 3, title: 'Last 1 hr', value: 60 * 60 },
  { id: 4, title: 'Last 6 hrs', value: 6 * 60 * 60 },
  { id: 5, title: 'Last 24 hrs', value: 24 * 60 * 60 },
  { id: 6, title: 'Last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 7, title: 'Last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 8, title: 'Last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 9, title: 'Last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 10, title: 'Last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 11, title: 'All', value: (new Date().getTime() / 1000) | 0 },
];
export const optionsWithCustomInput = [
  { id: 1, title: 'last 1 hour', value: 60 * 60 },
  { id: 2, title: 'last 6 hours', value: 6 * 60 * 60 },
  { id: 3, title: 'last 24 hours', value: 24 * 60 * 60 },
  { id: 4, title: 'last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 5, title: 'last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 6, title: 'last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 7, title: 'last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 8, title: 'last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 9, title: 'all', value: 5 * 365 * 24 * 60 * 60 },
  { id: 10, title: 'Choose Date', value: 'custom-input' },
];
