import React from 'react';
import { ReactComponent as GroupIcon } from '../../../../assets/icons/group.svg';
import { useSelector } from 'react-redux';
import DayMonthChart from './dependencies/DayMonthChart';
import NotificationCard from '../../../../components/common/cards/notification';
import { getInActiveTimeToString } from '../../../../utils/utils';
import Search from '../../../../components/basic/search-input/Search';

export default function DayMothChartNotificationsPower() {
  const {
    response: { data },
  } = useSelector((state) => state.dashboard);
  const { current_activity = [] } = data || {};
  const {
    response: { data: alerts = [] },
  } = useSelector((state) => state.alerts);

  return (
    <div className='grid grid-cols-12 grid-flow-row gap-4'>
      <div className='col-span-12 xl:col-span-6'>
        <div className='h-full'>
          <DayMonthChart fuel={true} power={true} />
        </div>
      </div>
      <div className='col-span-12 sm:col-span-6 xl:col-span-3'>
        <div className='h-full text-primary-700 dark:text-primary-100 custom-background relative'>
          <NotificationCard contentClass='max-h-[310px] custom-scroll' notifications={alerts?.slice(0, 4)} nextUrl='notifications' />
        </div>
      </div>
      <div className='col-span-12 sm:col-span-6 xl:col-span-3'>
        <div className='h-full custom-shadow text-primary-700 dark:text-primary-100 custom-background relative'>
          <Search icon placeholder='Search here' className=' bg-primary-50 dark:bg-primary-700 rounded-lg h-9 flex-row-reverse [&>input]:!pl-0' />

          <div className='max-h-[280px] custom-scroll'>
            {current_activity?.map((item, i) => (
              <ul key={i} className='my-4 flex items-start text-primary-700 dark:text-primary-200 text-md'>
                <li>
                  <GroupIcon className='w-4 mr-3 fill-path' />
                </li>
                <li className='flex-1'>
                  {item.pg_number} <br /> <span className='text-sm opacity-40'>{getInActiveTimeToString(item.end_time)}</span>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
