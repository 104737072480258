import { createSlice } from '@reduxjs/toolkit';

const generatorCompanySlice = createSlice({
  name: 'generatorCompany',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    generatorCompanyInit: (state) => {
      return { ...state, isLoading: true };
    },
    generatorCompanySuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    generatorCompanyFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    generatorCompanyDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    generatorCompanyDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    generatorCompanyDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { generatorCompanyInit, generatorCompanySuccess, generatorCompanyFail, generatorCompanyDelInit, generatorCompanyDelSuccess, generatorCompanyDelFail } = generatorCompanySlice.actions;
export default generatorCompanySlice.reducer;
