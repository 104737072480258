import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SUPER_ADMIN, USER_VIEWS_ONLY, downloadApi, getCompanies, getPgs, getReports } from '../../../apis/apis';
import { useFetchFun, useUserInfo } from '../../../hooks/hooks';
import { checkEmpty, getFormattedTime, makeFormObjectFromArray, parseDateTime, timeConvert } from '../../../utils/utils';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import Modal from '../../../components/basic/Modals/Modal';
import InputDateRange from '../../../components/basic/input-date-range/InputDateRange';
import SelectFromOption from '../../../components/basic/SelectFromOption/SelectFromOption';
import { optionsWithCustomInput } from '../../../dataset';
import Button from '../../../components/basic/button';
import DownloadButton from '../../../components/common/download/DownloadButton';
import Suspender from '../../../components/common/suspender/Suspender';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';

export const options = [
  { id: 1, title: 'last 1 hour', value: 60 * 60 },
  { id: 2, title: 'last 6 hours', value: 6 * 60 * 60 },
  { id: 3, title: 'last 24 hours', value: 24 * 60 * 60 },
  { id: 4, title: 'last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 5, title: 'last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 6, title: 'last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 7, title: 'last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 8, title: 'last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 9, title: 'all', value: 5 * 365 * 24 * 60 * 60 },
  { id: 10, title: 'Choose Date', value: 'custom-input' },
];

export default function EventStatsDefault() {
  const { user_type = USER_VIEWS_ONLY, company } = useUserInfo();

  const companyList = useSelector((state) => state.companies);
  const pgList = useSelector((state) => state.pgs);
  const [selCompany, setSelCompany] = useState(user_type === SUPER_ADMIN ? {} : company);
  const [selPg, setSelPg] = useState({});

  const [filterObj, updateFilterObj] = useState({});
  const [queryObj, updateQueryObject] = useFetchFun(getReports, { page: 0, per_page: 20 });
  const [filterModal, setFilterModal] = useState(false);
  const { isLoading, isError, response: data = [] } = useSelector((state) => state.reports);
  const [formData, updateFormData] = useState(
    makeFormObjectFromArray([
      { fieldName: 'company_id', value: '', required: true },
      { fieldName: 'pg_number', value: '' },
      { fieldName: 'start_time', value: '' },
      { fieldName: 'end_time', value: '' },
    ])
  );
  useFetchFun(getCompanies);
  useFetchFun(getPgs);

  const onSearch = () => {
    const data = makeSubmittableData({ ...formData });
    if (data.status) {
      updateQueryObject({ ...queryObj, ...data.data });
    } else {
      updateFormData(data.data);
    }
  };

  const handleDownload = () => {
    const data = makeSubmittableData(formData);
    if (data.status) {
      downloadApi('pg-stats-data/all', queryObj);
    } else {
      updateFormData(data.data);
    }
  };

  useEffect(() => {
    if (filterObj.value === 'custom-input') {
      setFilterModal(true);
    } else if (filterObj.value) {
      const endTime = timeConvert().second;
      const startTime = endTime - filterObj.value;
      updateFormData((prev) => ({ ...prev, start_time: { value: startTime, error: false }, end_time: { value: endTime, error: false } }));
    }
  }, [filterObj]);

  useEffect(() => {
    if (!checkEmpty(selCompany)) {
      updateFormData((prev) => ({ ...prev, company_id: { value: selCompany.id, error: false } }));
      setSelPg({});
    }
  }, [selCompany]);

  useEffect(() => {
    if (!checkEmpty(selPg)) {
      updateFormData((prev) => ({ ...prev, pg_number: { value: selPg.pg_number, error: false } }));
    }
  }, [selPg]);

  const applyFilter = ({ status = true, data: dateData = {} }) => {
    const { start_time = '', end_time = '' } = dateData;
    const endTime = new Date(end_time).getTime() / 1000;
    const startTime = new Date(start_time).getTime() / 1000;
    setFilterModal(false);
    updateFormData((prev) => ({ ...prev, start_time: { value: startTime, error: false }, end_time: { value: endTime, error: false } }));
    updateFilterObj((state) => ({ ...state, title: `${start_time}-${end_time}`, value: null }));
  };

  return (
    <>
      {filterModal && (
        <Modal onClose={() => setFilterModal(false)}>
          <div className='mx-auto w-max p-4 rounded-md '>
            <InputDateRange action={applyFilter} btnLabel='Confirm' />
          </div>
        </Modal>
      )}
      <div className='mb-10 form-wrapper !max-w-[730px] flex flex-col md:flex-row items-baseline gap-8 '>
        {user_type === SUPER_ADMIN && (
          <SelectFromOption onClick={(op) => setSelCompany(op)} value={selCompany} options={companyList.response?.data || []} headerClass='custom-input' placeholder='Select Company' fieldError={formData.company_id?.error || false} />
        )}
        <SelectFromOption onClick={(op) => setSelPg(op)} value={selPg} options={pgList.response?.data || []} headerClass='custom-input' placeholder='Select Generator' displayKey='pg_name' fieldError={formData.pg_number?.error || false} />
        <SelectFromOption headerClass='custom-input' options={optionsWithCustomInput} value={filterObj} displayKey='title' onClick={(item) => updateFilterObj(item)} placeholder='Filter' />
        <Button onClick={onSearch} title='Search' className='btn-success h-8 px-2' />
      </div>
      <div className='mb-2 flex items-center justify-between'>
        <h2>Event Logs:</h2>
        {user_type !== USER_VIEWS_ONLY && <DownloadButton action={handleDownload} />}
      </div>
      <Suspender isLoading={isLoading} isError={isError}>
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>Generator Number</th>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Energy Consumed(kWh)</th>
                <th>Energy Reading (kWh)</th>
                <th>Fuel Level</th>
                <th>Fuel Consumed</th>
                <th>THiNK Device Id</th>
                <th>Data Type</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((item) => (
                <tr key={item.id}>
                  <td className='px-4'>{item?.pg_number || '-'}</td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.start_time).date || '-'}</pre>
                  </td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.start_time).time || '-'}</pre>
                  </td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.end_time).time || '-'}</pre>
                  </td>
                  <td>{(item?.duration && getFormattedTime(item?.duration)) || '-'}</td>
                  <td>{item?.energy_consumed || '0'}</td>
                  <td>{item?.last_energy_meter || '0'}</td>
                  <td>{item?.last_fuel_meter || '0'}</td>
                  <td>{item?.fuel_consumed || '0'}</td>
                  <td>{item?.device_id || '-'}</td>
                  <td>{item.data_type || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.data?.length === 0 && !isLoading && <NoData />}
        </div>
      </Suspender>
      <div className='pagination-wrapper'>
        <PaginatedItems itemsPerPage={queryObj.per_page} onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })} totalItems={data.pagination?.total} />
      </div>
    </>
  );
}
