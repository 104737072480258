import { configureStore } from '@reduxjs/toolkit';
import activeDevices from '../slices/active-devices';
import alert from '../slices/alert';
import companiesSlice from '../slices/companies';
import countriesSlice from '../slices/countries';
import deviceLogs from '../slices/device-logs';
import deviceModule from '../slices/device-module';
import deviceSensors from '../slices/device-sensors';
import devices from '../slices/devices';
import faultTypes from '../slices/fault-types';
import features from '../slices/features';
import fuels from '../slices/fuel-entries';
import fuelTankDimension from '../slices/fuel-tank-dimension';
import generatorCompanies from '../slices/generator-companies';
import generatorModel from '../slices/generator-model';
import generatorModule from '../slices/generator-module';
import dashboard from '../slices/home-dash';
import pgs from '../slices/pgs';
import reports from '../slices/reports';
import services from '../slices/services';
import tenantCompany from '../slices/tenant-company';
import tenants from '../slices/tenants';
import tickets from '../slices/tickets';
import towerCompaniesSlice from '../slices/tower-companies';
import towerSites from '../slices/tower-sites';
import userFeatures from '../slices/user-features';
import userPgs from '../slices/user-pgs';
import userTypeDefaultFeature from '../slices/user-type-default-feature';
import userTypeSlice from '../slices/user-types';
import usersSlice from '../slices/users';
import zones from '../slices/zones';
import fuelData from '../slices/fuel-data';
import deviceSummary from '../slices/device-summary';
import userInfo from '../slices/user-info';
import dashboardSummary from '../slices/dashboard-summary';
import fuelWithdraw from '../slices/fuel-withdraw';

export const store = configureStore({
  reducer: {
    fuelData: fuelData,
    userTypeDefaultFeatures: userTypeDefaultFeature,
    userFeatures: userFeatures,
    features: features,
    tenantCompanies: tenantCompany,
    tenants: tenants,
    generatorModules: generatorModule,
    deviceSensors: deviceSensors,
    deviceModules: deviceModule,
    alerts: alert,
    services: services,
    fuels: fuels,
    fuelWithdraws: fuelWithdraw,
    generatorModels: generatorModel,
    generatorCompany: generatorCompanies,
    fuelTankDimension: fuelTankDimension,
    tickets: tickets,
    zones: zones,
    faultTypes: faultTypes,
    activeDevices: activeDevices,
    dashboardSummary: dashboardSummary,
    dashboard: dashboard,
    reports: reports,
    deviceSummary: deviceSummary,
    deviceLogs: deviceLogs,
    userPgs: userPgs,
    pgs: pgs,
    devices: devices,
    countries: countriesSlice,
    towerCompanies: towerCompaniesSlice,
    users: usersSlice,
    companies: companiesSlice,
    towerSites,
    userTypes: userTypeSlice,
    userInfo: userInfo,
  },

  devTools: process.env.NODE_ENV === 'development',
});
