import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrowLeft.svg';
import { useLocation, useNavigate } from 'react-router-dom';

export default function HomeTile({ label, children: Children }) {
  const [icon, setIcon] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIcon(location.pathname.split('/').length > 2);
  }, [location]);

  return (
    <div className='flex items-center'>
      {icon && <LeftArrow onClick={() => navigate(location.pathname.split('/').slice(0, 2).join('/'))} className='w-4 cursor-pointer stroke-path' />}
      {Children ? Children : <h4 className='ml-1 flex-1'>{label}</h4>}
    </div>
  );
}

HomeTile.propTypes = {
  label: PropTypes.string,
};
