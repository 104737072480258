import React from 'react';
import Search from '../search-input/Search';
import { useApiSlice } from '../../../hooks/hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { checkEmpty, isMatchWith } from '../../../utils/utils';
import { useRef } from 'react';
import './LocalSearch.css';
import { useNavigate } from 'react-router-dom';

export default function LocalSearch({ apiEndpoint = null, contentClass = 'min-h-[100px]', className = '', displayKey = '' }) {
  const { getItems, isError, isLoading, result } = useApiSlice({ page: 0, per_page: 2000 });
  const [tempOptions, updateTempOptions] = useState([]);
  const [listShow, setListShow] = useState(false);
  const divRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (apiEndpoint) {
      getItems({ endPoint: apiEndpoint });
    }
  }, [getItems, apiEndpoint]);

  useEffect(() => {
    if (!isError && !isLoading) {
      updateTempOptions(result.data);
    }
  }, [result, isError, isLoading]);

  const handleSearchInput = (value) => {
    const tempList = result.data?.filter((op) => isMatchWith(op[displayKey], value) !== null);
    updateTempOptions(tempList);
  };

  const handleClickOnOption = (op) => {
    setListShow(false);

    op.tank_or_generator === 2 ? navigate(`device-data/only_fuel/${op.pg_number}`) : navigate(`device-data/${op.pg_number}`);
  };

  const handleListener = (e) => {
    if (!divRef.current?.contains(e.target)) {
      setListShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleListener);
    return () => window.removeEventListener('click', handleListener);
  }, []);

  return (
    <div ref={divRef} className='relative'>
      <Search handleFocus={() => setListShow(true)} onSearch={handleSearchInput} icon placeholder='Search here' className={`mx-2 custom-search hidden lg:inline ${className}`} />
      <div className='searched-box'>
        <ul className={`shadow-lg text-sm text-primary-700 dark:text-primary-100 bg-primary-300 dark:bg-primary-800 ${contentClass} ${listShow ? 'block' : 'hidden'}`}>
          {checkEmpty(tempOptions) ? (
            <h2 className='pt-10 text-center'>Not Matched</h2>
          ) : (
            tempOptions?.map((op, i) => (
              <li key={i} onClick={() => handleClickOnOption(op)} className='list-none hover:opacity-75 w-full cursor-pointer px-2 my-1 py-1'>
                {op[displayKey]}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
