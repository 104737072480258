import React from 'react';
import { useSelector } from 'react-redux';
import { getDashboardData, getServices } from '../../apis/apis';
import Suspender from '../../components/common/suspender/Suspender';
import { useFetchFun, useUserInfo } from '../../hooks/hooks';
import { currentQuadrant } from '../../utils/utils';
import './home.css';
import GetSection from '../../sections';
export default function Home() {
  const { feature = [] } = useUserInfo();
  const { isLoading, isError } = useSelector((state) => state.dashboard);
  useFetchFun(
    getDashboardData,
    {
      data: ['day_summary', 'currently_active', 'monthly_kva', 'monthly_kwh', 'monthly_stats', 'daily_stats', 'total_device', 'quarter_summary', 'top_site,current_activity', 'fuel_summary', 'number_of_generator'],
      quarter_date: 2023,
      quarter: currentQuadrant(),
    },
    true
  );
  useFetchFun(getServices, { page: 0, per_page: 200 });

  const sections = feature
    ?.filter((it) => it.page_name === 'home')
    ?.sort((a, b) => a.priority - b.priority)
    ?.map((it) => it.name);

  return (
    <Suspender isError={isError} isLoading={isLoading} onlyFirstRender={true}>
      <>
        {sections?.map((sec, i) => (
          <section key={i} className='mb-4'>
            <GetSection compName={sec} />
          </section>
        ))}
      </>
    </Suspender>
  );
}
