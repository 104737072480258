import { useEffect, useRef, useState } from 'react';
import { ReactComponent as DownIcon } from '../../../assets/icons/caretDown.svg';
import { checkEmpty, isMatchWith } from '../../../utils/utils';
import './Selected.css';

export default function SelectFromOption({ placeholder = '', options = [], onClick = () => {}, displayKey = 'name', headerClass = 'header-class', contentClass = 'content-class', readOnly = true, fieldError = '', disableOption = false, value = {} }) {
  const [tempOptions, updateTempOptions] = useState([]);
  const [listShow, setListShow] = useState(false);
  const [tempValue, updateTempValue] = useState('');
  const divRef = useRef();

  useEffect(() => {
    updateTempValue(value[displayKey] || '');
  }, [value, displayKey]);

  useEffect(() => {
    updateTempOptions(options);
  }, [options]);

  const searchInput = (value) => {
    updateTempValue(value);
    const tempList = options.filter((op) => isMatchWith(op[displayKey], value) !== null);
    updateTempOptions(tempList);
  };

  const updateInputValue = (op) => {
    onClick(op);
    setListShow(false);
  };

  const handleListener = (e) => {
    if (!divRef.current?.contains(e.target)) {
      setListShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleListener);
    return () => window.removeEventListener('click', handleListener);
  }, []);

  return (
    <div ref={divRef} className='select-from-option'>
      <label onClick={() => !disableOption && setListShow(true)} className={`w-full px-2 flex items-center cursor-pointer ${headerClass}`}>
        <input readOnly={readOnly} placeholder={placeholder} onChange={(e) => searchInput(e.target.value)} className='px-1 w-full outline-none cursor-pointer' type='text' value={tempValue} />
        <DownIcon width='10px' height='10px' className='text-xl [&>g]:fill-[#353574]' />
      </label>
      {fieldError && <p className='text-[#dd5a5a] text-sm'>{fieldError}</p>}

      <ul className={`listContainer ${contentClass} ${listShow ? 'block' : 'hidden'}`}>
        {checkEmpty(tempOptions) && <h3 className='text-center mt-5 opacity-50'>No Option</h3>}
        {tempOptions?.map((op, i) => (
          <li key={i} onClick={() => updateInputValue(op)} className='list-none w-full cursor-pointer px-2 my-1 py-1 '>
            {op[displayKey]}
          </li>
        ))}
      </ul>
    </div>
  );
}

// options must be as like as
// const options = [
//     { id: 1, title: "English", value: "english" },
//     { id: 2, title: "Bangla", value: "bangla" },
//     { id: 3, title: "Arabic", value: "arabic" },
//   ];

//onClick e listContainer close && setValue
