import React from 'react';
import { ReactComponent as StarIcon } from './star.svg';
export default function Star({ children = null, label = '', size = 10, position = '-right-3', className = '' }) {
  return (
    <div className={`relative w-fit mx-auto ${className}`}>
      <StarIcon width={size} height={size} className={`fill-secondary-600 absolute top-0 ${position}`} />
      {children ? children : label}
    </div>
  );
}
