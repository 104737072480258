import { createSlice } from '@reduxjs/toolkit';

const fuelTankDimensionSlice = createSlice({
  name: 'fuelTankDimension',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    fuelTankDimensionInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelTankDimensionSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    fuelTankDimensionFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    fuelTankDimensionDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    fuelTankDimensionDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    fuelTankDimensionDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { fuelTankDimensionInit, fuelTankDimensionSuccess, fuelTankDimensionFail, fuelTankDimensionDelInit, fuelTankDimensionDelSuccess, fuelTankDimensionDelFail } = fuelTankDimensionSlice.actions;
export default fuelTankDimensionSlice.reducer;
