import React from 'react';
import { useSelector } from 'react-redux';
import Dot from '../../../../assets/icons/dot';
import { getFormattedTime, parseDateTime } from '../../../../utils/utils';
import Calender from '../../../../components/common/calender/Calender';

export default function RuntimeCalendar() {
  const {
    response: { data },
  } = useSelector((state) => state.dashboard);
  const { total_run_time = 0 } = data || {};
  const {
    response: { data: services = [] },
  } = useSelector((state) => state.services);

  const maintenanceDates = services?.map((service) => service.maintenance_date);
  const upcomingMaintenanceDates = services.filter((it) => it.next_service_at)?.map((service) => new Date(`${service.next_service_at}`).getTime() / 1000);
  const upcomingMaintenance = services.filter((it) => it.next_service_at);

  return (
    <div className='grid grid-cols-12 grid-flow-row gap-4'>
      <div className='col-span-12 lg:col-span-6'>
        <div className='w-full h-full aspect-[1/.4] relative custom-background'>
          <div className='position-center max-w-sm text-center'>
            <p className='text-xl font-semibold'>Total Runtime of the day</p>
            <p className='mt-2 text-[#428F2E] text-[40px] font-extrabold'>{total_run_time ? getFormattedTime(total_run_time) : '00:00:00'}</p>
          </div>
        </div>
      </div>
      <div className='col-span-12 lg:col-span-6'>
        <div className='w-full h-full text-sm flex flex-col sm:flex-row gap-2 custom-background'>
          <div className='sm:mt-6 sm:w-[164px]'>
            <hgroup className='mb-3 text-start opacity-50'>
              <h3>UPCOMING</h3>
              <h3>Maintenance</h3>
            </hgroup>
            <div className='text-xs max-h-[240px] custom-scroll'>
              {upcomingMaintenance?.slice(0, 3)?.map((item, i) => (
                <ul key={i} className='mb-3 flex items-baseline text-sm opacity-75'>
                  <li className='mr-1'>
                    <Dot radius={4} fill={`${item.active ? '#439B57' : '#9B4343'}`} />
                  </li>
                  <li className='flex-1'>
                    <span className='underline'>{item?.pg_number || ''}</span> will be maintained on <br /> <span className='text xs opacity-60'>{parseDateTime(new Date(item.next_service_at).getTime() / 1000).date}</span>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <div className='flex-1 px-3 overflow-auto border-l border-primary-300 dark:border-primary-700'>
            <Calender pickedDates={[...maintenanceDates, ...upcomingMaintenanceDates]} />
          </div>
        </div>
      </div>
    </div>
  );
}
