import { createSlice } from '@reduxjs/toolkit';

const deviceSummarySlice = createSlice({
  name: 'deviceSummary',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    deviceSummaryInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceSummarySuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    deviceSummaryFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    deviceSummaryDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceSummaryDelSuccess: (state, action) => {
      return {
        ...state,
        response: state.response.data.filter((item) => !action.payload.data.includes(item.pg_number)),
        isLoading: false,
      };
    },
    deviceSummaryDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { deviceSummaryInit, deviceSummarySuccess, deviceSummaryFail, deviceSummaryDelInit, deviceSummaryDelSuccess, deviceSummaryDelFail } = deviceSummarySlice.actions;
export default deviceSummarySlice.reducer;
