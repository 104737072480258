import { createSlice } from '@reduxjs/toolkit';

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    tenantInit: (state) => {
      return { ...state, isLoading: true };
    },
    tenantSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    tenantFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    tenantDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    tenantDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    tenantDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { tenantInit, tenantSuccess, tenantFail, tenantDelInit, tenantDelSuccess, tenantDelFail } = tenantSlice.actions;
export default tenantSlice.reducer;
