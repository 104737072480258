import credentials, { baseUrl } from './credentials';
import { getApi, postApi } from './methods';
import axios from 'axios';
import { formRest } from '../components/common/forms/validator';
import { activeDevicesFail, activeDevicesInit, activeDevicesSuccess } from '../slices/active-devices';
import { alertDelFail, alertDelInit, alertDelSuccess, alertFail, alertInit, alertSuccess } from '../slices/alert';
import { userInfoFail, userInfoInit, userInfoSuccess, userInfoUpdate } from '../slices/user-info';
import { companiesDelFail, companiesDelInit, companiesDelSuccess, companiesFail, companiesInit, companiesSuccess } from '../slices/companies';
import { countriesFail, countriesInit, countriesSuccess } from '../slices/countries';
import { deviceLogsFail, deviceLogsInit, deviceLogsSuccess } from '../slices/device-logs';
import { deviceModuleDelFail, deviceModuleDelInit, deviceModuleDelSuccess, deviceModuleFail, deviceModuleInit, deviceModuleSuccess } from '../slices/device-module';
import { deviceSensorsDelFail, deviceSensorsDelInit, deviceSensorsDelSuccess, deviceSensorsFail, deviceSensorsInit, deviceSensorsSuccess } from '../slices/device-sensors';
import { devicesDelFail, devicesDelInit, devicesDelSuccess, devicesFail, devicesInit, devicesSuccess } from '../slices/devices';
import { faultTypesDelFail, faultTypesDelInit, faultTypesDelSuccess, faultTypesFail, faultTypesInit, faultTypesSuccess } from '../slices/fault-types';
import { featuresDelFail, featuresDelInit, featuresDelSuccess, featuresFail, featuresInit, featuresSuccess } from '../slices/features';
import { fuelEntryDelFail, fuelEntryDelInit, fuelEntryDelSuccess, fuelEntryFail, fuelEntryInit, fuelEntrySuccess } from '../slices/fuel-entries';
import { fuelTankDimensionDelFail, fuelTankDimensionDelInit, fuelTankDimensionDelSuccess, fuelTankDimensionFail, fuelTankDimensionInit, fuelTankDimensionSuccess } from '../slices/fuel-tank-dimension';
import { generatorCompanyDelFail, generatorCompanyDelInit, generatorCompanyDelSuccess, generatorCompanyFail, generatorCompanyInit, generatorCompanySuccess } from '../slices/generator-companies';
import { generatorModelDelFail, generatorModelDelInit, generatorModelDelSuccess, generatorModelFail, generatorModelInit, generatorModelSuccess } from '../slices/generator-model';
import { generatorModuleDelFail, generatorModuleDelInit, generatorModuleDelSuccess, generatorModuleFail, generatorModuleInit, generatorModuleSuccess } from '../slices/generator-module';
import { dashboardFail, dashboardInit, dashboardSuccess } from '../slices/home-dash';
import { pgsDelFail, pgsDelInit, pgsDelSuccess, pgsFail, pgsInit, pgsSuccess } from '../slices/pgs';
import { reportsFail, reportsInit, reportsSuccess } from '../slices/reports';
import { serviceDelFail, serviceDelInit, serviceDelSuccess, serviceFail, serviceInit, serviceSuccess } from '../slices/services';
import { tenantCompanyDelFail, tenantCompanyDelInit, tenantCompanyDelSuccess, tenantCompanyFail, tenantCompanyInit, tenantCompanySuccess } from '../slices/tenant-company';
import { tenantDelFail, tenantDelInit, tenantDelSuccess, tenantFail, tenantInit, tenantSuccess } from '../slices/tenants';
import { ticketsFail, ticketsInit, ticketsSuccess } from '../slices/tickets';
import { towerCompaniesDelFail, towerCompaniesDelInit, towerCompaniesDelSuccess, towerCompaniesFail, towerCompaniesInit, towerCompaniesSuccess } from '../slices/tower-companies';
import { towerSitesDelFail, towerSitesDelInit, towerSitesDelSuccess, towerSitesFail, towerSitesInit, towerSitesSuccess } from '../slices/tower-sites';
import { userFeaturesDelFail, userFeaturesDelInit, userFeaturesDelSuccess, userFeaturesFail, userFeaturesInit, userFeaturesSuccess } from '../slices/user-features';
import { userPgsDelFail, userPgsDelInit, userPgsDelSuccess, userPgsFail, userPgsInit, userPgsSuccess } from '../slices/user-pgs';
import { userTypeDefaultFeaturesDelFail, userTypeDefaultFeaturesDelInit, userTypeDefaultFeaturesDelSuccess, userTypeDefaultFeaturesFail, userTypeDefaultFeaturesInit, userTypeDefaultFeaturesSuccess } from '../slices/user-type-default-feature';
import { userTypesFail, userTypesInit, userTypesSuccess } from '../slices/user-types';
import { usersDelFail, usersDelInit, usersDelSuccess, usersFail, usersInit, usersSuccess, usersUpdateFail, usersUpdateSuccess } from '../slices/users';
import { zonesDelFail, zonesDelInit, zonesDelSuccess, zonesFail, zonesInit, zonesSuccess } from '../slices/zones';
import { store } from '../store';
import { makeObjectAsGroupFromArray } from '../utils/utils';
import { fuelDataFail, fuelDataInit, fuelDataSuccess } from '../slices/fuel-data';
import { deviceSummaryFail, deviceSummaryInit, deviceSummarySuccess } from '../slices/device-summary';
import { dashboardSummaryFail, dashboardSummaryInit, dashboardSummarySuccess } from '../slices/dashboard-summary';
import { notify } from '../components/basic/notify';
import { fuelWithdrawDelFail, fuelWithdrawDelInit, fuelWithdrawDelSuccess, fuelWithdrawFail, fuelWithdrawInit, fuelWithdrawSuccess } from '../slices/fuel-withdraw';
const location = window.location;

export const SUPER_ADMIN = 1;
export const CUSTOMER_ADMIN = 2;
export const CUSTOMER_USER = 3;
export const USER_VIEWS_ONLY = 5;
export const refreshTime = 1000 * 25;

export async function userPasswordChange(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/user/change-password`, postData, {
      headers: credentials.getHeaders(),
    });
    const { status, token, refreshToken } = response.data;
    if (status) {
    }
    credentials.setTokens({ token, refreshToken });
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    return { data: { data, status, statusText } };
  }
}

export async function userPasswordResetGetLink(postData = {}) {
  try {
    const response = await axios.get(`${baseUrl}/auth/reset-password?email=${postData.email}`);
    if (response.data.status === true) notify(`${response.data.message}`);
  } catch (error) {
    notify({ text: `${error.response.data.error}` });
  }
  return null;
}

export async function userPasswordReset(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/auth/user/reset-password-update`, postData);
    if (response.data.status === true) {
      credentials.setTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
      notify({ text: `Password reset Successfully` });
      return location.replace('/login');
    } else {
      notify({ text: `{response.data.data.error}` });
    }
  } catch (error) {
    notify({ text: `${error.response.data.error}` });
  }
  return null;
}

// export async function newTokenGenerate() {
//   const { refreshToken = null } = credentials.getTokens();
//   if (!refreshToken) return { status: false };
//   try {
//     const response = await axios.post(`${baseUrl}/auth/user/token`, { refreshToken: refreshToken });
//     credentials.setTokens({ token: response.data.token, refreshToken: response.data.refreshToken });
//     return { status: true };
//   } catch (error) {
//     console.log(error);
//   }
//   return { status: false };

//   // location.replace('/');
// }

export async function loginByToken(postData) {
  const resolve = (response) => {
    const { token = '', refreshToken = '' } = response;
    credentials.setTokens({ token, refreshToken });
    location.replace('/');
  };

  return postApi({ url: 'auth/user/login', body: postData, resolve: resolve });
}

// export async function getUser() {
//   const { token = null } = credentials.getTokens();
//   let status = false;
//   if (token !== null) {
//     try {
//       const response = await axios.get(`${baseUrl}/user/myinfo`, { headers: credentials.getHeaders() });
//       store.dispatch(userInfoSuccess({ data: response.data.data }));
//       status = true;
//     } catch (error) {
//       if (error.response) {
//         if (error.response.data.error === 'jwt expired') {
//           const res = await newTokenGenerate();
//           console.log('response====', res);
//           if (res.status) {
//             status = true;
//           } else {
//             status = false;
//           }
//         }
//       }
//     }
//   }
//   return status;
// }

export async function getUserInfo() {
  await getApi({
    url: `user/myinfo`,
    init: userInfoInit,
    success: userInfoSuccess,
    fail: userInfoFail,
  });
}

export function deleteUser(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }
  deleteApiCall(
    {
      url: `user/delete/${ids.join(',')}`,
      init: usersDelInit,
      success: usersDelSuccess,
      fail: usersDelFail,
    },
    ids
  );
}

//update User form has slight deferent
export async function userFormUpdate(postData = {}) {
  try {
    const response = await axios.post(`${baseUrl}/user/profile-update`, postData, {
      headers: credentials.getHeaders(),
    });
    // const { status = null, message = '', ...properties } = response.data;
    // store.dispatch(userInfoUpdate({ data: properties }));
    const { status = null, message = '', ...properties } = response.data;
    store.dispatch(userInfoUpdate({ data: properties }));
    return { data: response.data };
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    return { data: { data, status, statusText } };
  }
}

export async function updateUserFromUsers(postData = {}) {
  updateApiCall(
    {
      url: `admin/user/account-active`,
      init: null,
      success: usersUpdateSuccess,
      fail: usersUpdateFail,
    },
    postData
  );
}

export async function deleteApiCall({ url = '', init = () => {}, success = () => {}, fail = () => {} }, arr = [] || {}) {
  store.dispatch(init());
  try {
    await axios.delete(`${baseUrl}/${url}`, { headers: credentials.getHeaders() });
    store.dispatch(success({ data: arr }));
  } catch (error) {
    console.log('errr==', error);
    const { data, status, statusText } = error.response || {};
    store.dispatch(fail());
  }
}

//inprogress
export async function updateApiCall({ url = '', init = () => {}, success = () => {}, fail = () => {} }, payload = {}) {
  if (init) {
    store.dispatch(init());
  }
  try {
    await axios.post(`${baseUrl}/${url}`, payload, { headers: credentials.getHeaders() });
    store.dispatch(success({ data: payload || {} }));
  } catch (error) {
    console.log('errr==', error);
    store.dispatch(fail());
  }
}

//Xlsx File upload
export async function postDataFromFile(postData = [], url = '') {
  let groupSize = 100;
  const objectOfGroup = makeObjectAsGroupFromArray([...postData], groupSize);
  let duplicate = [];
  let err = false;
  let newCreate = [];
  for (const key in objectOfGroup) {
    try {
      const response = await axios.post(`${baseUrl}/${url}`, objectOfGroup[key], { headers: credentials.getHeaders() });
      if (response.data.status === true) {
        if (response.data.duplicateEntries) {
          duplicate.push(response.data.duplicateEntries);
        }
        if (response.data.data.length > 0) {
          newCreate.push(response.data.data);
        }
        formRest();
      }
    } catch (error) {
      err = error.response.data.error;
      notify({ text: err });
      formRest();
    }
  }
  return { duplicate: duplicate.flat(1), newCreate: newCreate.flat(1), err };
}

//for getUser has different response Data for pagination
export async function getUsers(options = {}) {
  store.dispatch(usersInit());
  try {
    const response = await axios.get(`${baseUrl}/users?${joinQueryParam(options)}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(usersSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    store.dispatch(usersFail({ data: { data, status, statusText } }));
  }
}

//for userType
export async function getUserTypes() {
  getApi({ url: 'common-api/user-types', init: userTypesInit, success: userTypesSuccess, fail: userTypesFail });
}

// for country
export async function getCountries(options = {}) {
  await getApi({
    url: `common-api/countries?${joinQueryParam(options)}`,
    init: countriesInit,
    success: countriesSuccess,
    fail: countriesFail,
  });
}

//for company
export async function getCompanies(options = {}) {
  getApi({ url: `company/all?${joinQueryParam(options)}`, init: companiesInit, success: companiesSuccess, fail: companiesFail });
}

export function deleteCompanies(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `company/delete/${ids.join(',')}`,
      init: companiesDelInit,
      success: companiesDelSuccess,
      fail: companiesDelFail,
    },
    ids
  );
}

// for tower company
export async function getTowerCompanies() {
  getApi({
    url: 'tower-company/all',
    init: towerCompaniesInit,
    success: towerCompaniesSuccess,
    fail: towerCompaniesFail,
  });
}

export function deleteTowerCompanies(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }
  deleteApiCall(
    {
      url: `tower-company/delete/${ids.join(',')}`,
      init: towerCompaniesDelInit,
      success: towerCompaniesDelSuccess,
      fail: towerCompaniesDelFail,
    },
    ids
  );
}

//for tower sites
export function getTowerSites(options = {}) {
  getApi(
    {
      url: `tower-sites/all?${joinQueryParam(options)}`,
      init: towerSitesInit,
      success: towerSitesSuccess,
      fail: towerSitesFail,
    },
    true
  );
}

export function deleteTowerSites(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `tower-sites/delete/${ids.join(',')}`,
      init: towerSitesDelInit,
      success: towerSitesDelSuccess,
      fail: towerSitesDelFail,
    },
    ids
  );
}

//for devices
export async function getDevices(options = {}) {
  getApi(
    {
      url: `think-device/all?${joinQueryParam(options)}`,
      init: devicesInit,
      success: devicesSuccess,
      fail: devicesFail,
    },
    true
  );
}

export function deleteDevices(idOrIds = []) {
  let ids = [];
  console.log(idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `think-device/delete/${ids.join(',')}`,
      init: devicesDelInit,
      success: devicesDelSuccess,
      fail: devicesDelFail,
    },
    ids
  );
}

export function joinQueryParam(obj = {}) {
  let stringArray = Object.keys(obj).map((item, key) => `${item}=${obj[item]}`);
  return stringArray.join('&');
}
//for pgs
export async function getPgs(options = {}) {
  getApi(
    {
      url: `pg-name/all?${joinQueryParam(options)}`,
      init: pgsInit,
      success: pgsSuccess,
      fail: pgsFail,
    },
    true
  );
}

export function deletePgs(idOrIds = []) {
  let ids = [];
  console.log(typeof idOrIds);
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `pg-name/delete/${ids.join(',')}`,
      init: pgsDelInit,
      success: pgsDelSuccess,
      fail: pgsDelFail,
    },
    ids
  );
}

export async function getUserPgs(options = {}) {
  getApi({
    url: `user-pg-name/all?${joinQueryParam(options)}`,
    init: userPgsInit,
    success: userPgsSuccess,
    fail: userPgsFail,
  });
}

export function detachUserPg(options = {}) {
  const user_id = options.user_id || null;
  const pg_number = options.pg_number || null;

  deleteApiCall(
    {
      url: `user-pg-name/delete/${user_id}/${pg_number}`,
      init: userPgsDelInit,
      success: userPgsDelSuccess,
      fail: userPgsDelFail,
    },
    options
  );
}

export async function getActiveDeviceLogs(options = {}) {
  store.dispatch(deviceLogsInit());
  try {
    const response = await axios.get(`${baseUrl}/pg-log-data/all?${joinQueryParam(options)}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(deviceLogsSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    store.dispatch(deviceLogsFail({ data: { data, status, statusText } }));
  }
}

export async function getReports(options = {}) {
  store.dispatch(reportsInit());
  try {
    const response = await axios.get(`${baseUrl}/pg-stats-data/all?${joinQueryParam(options)}`, { headers: credentials.getHeaders() });
    store.dispatch(reportsSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    store.dispatch(reportsFail({ data: { data, status, statusText } }));
  }
}

export async function downloadReport(options = {}) {
  try {
    axios.get(`${baseUrl}/pg-stats-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, {
      headers: credentials.getHeaders(),
    });
    window.open(`${baseUrl}/pg-stats-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, '_blank');
  } catch (error) {
    const { data, status, statusText, headers } = error.response || {};
  }
}

export async function downloadDeviceLogs(options = {}) {
  try {
    axios.get(`${baseUrl}/pg-log-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, {
      headers: credentials.getHeaders(),
    });
    window.open(`${baseUrl}/pg-log-data/all?${joinQueryParam({ ...options, download: 'yes' })}`, '_blank');
  } catch (error) {
    const { data, status, statusText, headers } = error.response || {};
  }
}
//for home page

export async function getDashboardData(options = {}) {
  let dataArray = [];
  if (typeof (options.data || {}) === 'object') {
    dataArray.push(options.data);
  } else {
    dataArray = options.data;
  }
  let modifyOptions = {};
  if (options.data) {
    for (let key in options) {
      if (key !== 'data') {
        modifyOptions[key] = options[key];
      }
    }
  } else {
    modifyOptions = options;
  }
  getApi({
    url: `pg-stats-data/dashboard?data=${dataArray.join(',')}&${joinQueryParam(modifyOptions)}`,
    init: dashboardInit,
    success: dashboardSuccess,
    fail: dashboardFail,
  });
}

export async function getDashboardSummary(options = {}) {
  getApi(
    {
      url: `pg-stats-data/top-4-dashboard?${joinQueryParam(options)}`,
      init: dashboardSummaryInit,
      success: dashboardSummarySuccess,
      fail: dashboardSummaryFail,
    },
    true
  );
}
export async function getActiveDevices(options = {}) {
  getApi(
    {
      url: `pg-stats-data/active?${joinQueryParam(options)}`,
      init: activeDevicesInit,
      success: activeDevicesSuccess,
      fail: activeDevicesFail,
    },
    true
  );
}

export async function getChatMessages(options = {}) {
  try {
    const response = await axios.get(`${baseUrl}/chat-log/all?${joinQueryParam({ ...options })}`, {
      headers: credentials.getHeaders(),
    });
    return { data: response.data };
  } catch (error) {
    console.log(error);
    // const { data, status, statusText, headers } = error.response||{};
    return { data: { status: false } };
  }
}

export async function getChatAllUser(options = {}) {
  try {
    const response = await axios.get(`${baseUrl}/chat-user/all?${joinQueryParam({ ...options })}`, {
      headers: credentials.getHeaders(),
    });
    return { data: response.data };
  } catch (error) {
    console.log(error);
    // const { data, status, statusText, headers } = error.response||{};
    return { data: { status: false } };
  }
}

export async function getHomeUrl() {
  try {
    const response = await axios.get(`${baseUrl}/user/myinfo`, { headers: credentials.getHeaders() });
    console.log(response);
  } catch (error) {
    return;
  }
}

//faulty category

export async function getFaultTypes(options) {
  getApi(
    {
      url: `fault-type/all?${joinQueryParam(options)}`,
      init: faultTypesInit,
      success: faultTypesSuccess,
      fail: faultTypesFail,
    },
    true
  );
}

export function deleteFaultTypes(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `fault-type/delete/${ids.join(',')}`,
      init: faultTypesDelInit,
      success: faultTypesDelSuccess,
      fail: faultTypesDelFail,
    },
    ids
  );
}

//zone api

export async function getZones(options) {
  getApi({ url: `zone/all?${joinQueryParam(options)}`, init: zonesInit, success: zonesSuccess, fail: zonesFail }, true);
}

export function deleteZones(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `zone/delete/${ids.join(',')}`,
      init: zonesDelInit,
      success: zonesDelSuccess,
      fail: zonesDelFail,
    },
    ids
  );
}

// ticket api

export async function getTickets(options) {
  getApi({ url: `support-case/all?${joinQueryParam(options)}`, init: ticketsInit, success: ticketsSuccess, fail: ticketsFail }, true);
}

export function deleteTickets(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `zone/delete/${ids.join(',')}`,
      init: zonesDelInit,
      success: zonesDelSuccess,
      fail: zonesDelFail,
    },
    ids
  );
}

// export async function getActiveStatus(options = {}) {
//   try {
//     const response = await axios.get(`${baseUrl}/pg-stats-data/dashboard?data=single_device_status&${joinQueryParam(options)}`, {
//       headers: credentials.getHeaders(),
//     });
//     return await response.data;
//   } catch (error) {
//     const { data, status, statusText, headers } = error.response || {};
//     return await { data, status, statusText, headers };
//   }
// }

//fuel tank dimension

export async function getFuelTankDimensions(options) {
  getApi(
    {
      url: `fuel-tank-dimensions/all?${joinQueryParam(options)}`,
      init: fuelTankDimensionInit,
      success: fuelTankDimensionSuccess,
      fail: fuelTankDimensionFail,
    },
    true
  );
}

export function deleteFuelTankDimension(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `fuel-tank-dimensions/delete/${ids.join(',')}`,
      init: fuelTankDimensionDelInit,
      success: fuelTankDimensionDelSuccess,
      fail: fuelTankDimensionDelFail,
    },
    ids
  );
}

//generator company

export async function getGeneratorCompany(options) {
  getApi(
    {
      url: `generator-company-name/all?${joinQueryParam(options)}`,
      init: generatorCompanyInit,
      success: generatorCompanySuccess,
      fail: generatorCompanyFail,
    },
    true
  );
}

export function deleteGeneratorCompany(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `generator-company-name/delete/${ids.join(',')}`,
      init: generatorCompanyDelInit,
      success: generatorCompanyDelSuccess,
      fail: generatorCompanyDelFail,
    },
    ids
  );
}

//generator model

export async function getGeneratorModels(options) {
  getApi(
    {
      url: `generator-model/all?${joinQueryParam(options)}`,
      init: generatorModelInit,
      success: generatorModelSuccess,
      fail: generatorModelFail,
    },
    true
  );
}

export function deleteGeneratorModel(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `generator-model/delete/${ids.join(',')}`,
      init: generatorModelDelInit,
      success: generatorModelDelSuccess,
      fail: generatorModelDelFail,
    },
    ids
  );
}

// service

export async function getServices(options) {
  getApi(
    {
      url: `maintenance-supports?${joinQueryParam(options)}`,
      init: serviceInit,
      success: serviceSuccess,
      fail: serviceFail,
    },
    true
  );
}

export function deleteServices(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `maintenance-support/delete/${ids.join(',')}`,
      init: serviceDelInit,
      success: serviceDelSuccess,
      fail: serviceDelFail,
    },
    ids
  );
}

// fuel entry

export async function getFuelEntries(options) {
  getApi(
    {
      url: `fuel-entry/all?${joinQueryParam(options)}`,
      init: fuelEntryInit,
      success: fuelEntrySuccess,
      fail: fuelEntryFail,
    },
    true
  );
}

export function deleteFuelEntry(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `fuel-entry/delete/${ids.join(',')}`,
      init: fuelEntryDelInit,
      success: fuelEntryDelSuccess,
      fail: fuelEntryDelFail,
    },
    ids
  );
}

//fuel withdraw

export async function getFuelWithdraw(options) {
  getApi(
    {
      url: `fuel-withdraw/all?${joinQueryParam(options)}`,
      init: fuelWithdrawInit,
      success: fuelWithdrawSuccess,
      fail: fuelWithdrawFail,
    },
    true
  );
}

export function deleteFuelWithdraw(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `fuel-withdraw/delete/${ids.join(',')}`,
      init: fuelWithdrawDelInit,
      success: fuelWithdrawDelSuccess,
      fail: fuelWithdrawDelFail,
    },
    ids
  );
}

//for alerts

export async function getAlerts(options) {
  getApi(
    {
      url: `alert/all?${joinQueryParam(options)}`,
      init: alertInit,
      success: alertSuccess,
      fail: alertFail,
    },
    true
  );
}

export function deleteAlert(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `alert/delete/${ids.join(',')}`,
      init: alertDelInit,
      success: alertDelSuccess,
      fail: alertDelFail,
    },
    ids
  );
}

export async function getTotalAlerts(options) {
  try {
    const response = await axios.get(`${baseUrl}/alert/all?${joinQueryParam(options)}`, { headers: credentials.getHeaders() });
    if (response.status === 200) return response.data.data;
    else {
      return { data: false };
    }
  } catch (error) {
    notify({ text: `${error.response.data.error}` });
  }
}

//for device modules

export async function getDeviceModules(options) {
  getApi(
    {
      url: `v2/device-extra-module?${joinQueryParam(options)}`,
      init: deviceModuleInit,
      success: deviceModuleSuccess,
      fail: deviceModuleFail,
    },
    true
  );
}

export function deleteDeviceModule(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `v2/device-extra-module/${ids.join(',')}`,
      init: deviceModuleDelInit,
      success: deviceModuleDelSuccess,
      fail: deviceModuleDelFail,
    },
    ids
  );
}

export function getUrlParamForUser(user) {
  const { id, user_type, company } = user || {};

  if (user_type === SUPER_ADMIN) {
    return {};
  } else if (user_type === CUSTOMER_ADMIN) {
    return { company_id: company.id };
  }
  return {
    company_id: company.id,
    user_id: id,
  };
}

// device sensor

export async function getDeviceSensors(options) {
  getApi(
    {
      url: `sensor-device/all?${joinQueryParam(options)}`,
      init: deviceSensorsInit,
      success: deviceSensorsSuccess,
      fail: deviceSensorsFail,
    },
    true
  );
}

export function deleteDeviceSensor(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `sensor-device/delete/${ids.join(',')}`,
      init: deviceSensorsDelInit,
      success: deviceSensorsDelSuccess,
      fail: deviceSensorsDelFail,
    },
    ids
  );
}

//generator module

export async function getGeneratorModules(options) {
  getApi(
    {
      url: `generator-modules/all?${joinQueryParam(options)}`,
      init: generatorModuleInit,
      success: generatorModuleSuccess,
      fail: generatorModuleFail,
    },
    true
  );
}

export function deleteGeneratorModule(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `generator-modules/delete/${ids.join(',')}`,
      init: generatorModuleDelInit,
      success: generatorModuleDelSuccess,
      fail: generatorModuleDelFail,
    },
    ids
  );
}

export async function getFuelAndDurationData(options = {}) {
  try {
    const res = await axios.get(`${baseUrl}/pg-log-data/duration-fuel-comparison?${joinQueryParam(options)}`, { headers: credentials.getHeaders });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
}

//tenants

export async function getTenants(options) {
  getApi(
    {
      url: `tenant/all?${joinQueryParam(options)}`,
      init: tenantInit,
      success: tenantSuccess,
      fail: tenantFail,
    },
    true
  );
}

export function deleteTenant(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `tenant/delete/${ids.join(',')}`,
      init: tenantDelInit,
      success: tenantDelSuccess,
      fail: tenantDelFail,
    },
    ids
  );
}

//tenants company

export async function getTenantCompanies(options) {
  getApi(
    {
      url: `tenant-company/all?${joinQueryParam(options)}`,
      init: tenantCompanyInit,
      success: tenantCompanySuccess,
      fail: tenantCompanyFail,
    },
    true
  );
}

export function deleteTenantCompany(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `tenant-company/delete/${ids.join(',')}`,
      init: tenantCompanyDelInit,
      success: tenantCompanyDelSuccess,
      fail: tenantCompanyDelFail,
    },
    ids
  );
}

//features

export async function getFeatures(options) {
  getApi(
    {
      url: `features/all?${joinQueryParam(options)}`,
      init: featuresInit,
      success: featuresSuccess,
      fail: featuresFail,
    },
    true
  );
}

export function deleteFeatures(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `features/delete/${ids.join(',')}`,
      init: featuresDelInit,
      success: featuresDelSuccess,
      fail: featuresDelFail,
    },
    ids
  );
}

//user features
export async function getUserFeatures(options) {
  getApi(
    {
      url: `user-features/all?${joinQueryParam(options)}`,
      init: userFeaturesInit,
      success: userFeaturesSuccess,
      fail: userFeaturesFail,
    },
    true
  );
}

export function deleteUserFeatures(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `user-features/delete/${ids.join(',')}`,
      init: userFeaturesDelInit,
      success: userFeaturesDelSuccess,
      fail: userFeaturesDelFail,
    },
    ids
  );
}

//user type default features
export async function getUserTypeDefaultFeatures(options) {
  getApi(
    {
      url: `user-type-default-feature/all?${joinQueryParam(options)}`,
      init: userTypeDefaultFeaturesInit,
      success: userTypeDefaultFeaturesSuccess,
      fail: userTypeDefaultFeaturesFail,
    },
    true
  );
}

export function deleteUserTypeDefaultFeatures(idOrIds = []) {
  let ids = [];
  if (typeof idOrIds === 'number') {
    ids.push(idOrIds);
  } else {
    ids = idOrIds;
  }

  deleteApiCall(
    {
      url: `user-type-default-feature/delete/${ids.join(',')}`,
      init: userTypeDefaultFeaturesDelInit,
      success: userTypeDefaultFeaturesDelSuccess,
      fail: userTypeDefaultFeaturesDelFail,
    },
    ids
  );
}

//get Fuel Data

export async function getFuelData(options) {
  getApi(
    {
      url: `pg-stats-fuel-data/all?${joinQueryParam(options)}`,
      init: fuelDataInit,
      success: fuelDataSuccess,
      fail: fuelDataFail,
    },
    true
  );
}

// export function deleteData(idOrIds = Data
//   let ids = [];
//   if (typeof idOrIds === 'number') {
//     ids.push(idOrIds);
//   } else {
//     ids = idOrIds;
//   }

//   deleteApiCall(
//     {
//       url: `fuel-entry/delete/${ids.join(',')}`,
//       init: fuelDataDelInit,
//       success: fuelDataDelSuccess,
//       fail: fuelDataDelFail,
//     },
//     ids
//   );
// }

// from here version2

export async function getDeviceLogs(options) {
  store.dispatch(deviceLogsInit());
  try {
    const response = await axios.get(`${baseUrl}/v2/device-data?${joinQueryParam(options)}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(deviceLogsSuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    store.dispatch(deviceLogsFail({ data: { data, status, statusText } }));
  }
}

export async function getDeviceSummary(options) {
  store.dispatch(deviceSummaryInit());
  try {
    const response = await axios.get(`${baseUrl}/v2/device-data/latest-single-item?${joinQueryParam(options)}`, {
      headers: credentials.getHeaders(),
    });
    store.dispatch(deviceSummarySuccess({ data: response.data }));
  } catch (error) {
    const { data, status, statusText } = error.response || {};
    store.dispatch(deviceSummaryFail({ data: { data, status, statusText } }));
  }
}

export async function getHourlyChartData(options = {}) {
  try {
    const response = await axios.get(`${baseUrl}/v2/device-data/hourly?${joinQueryParam(options)}`, { headers: credentials.getHeaders() });
    return { data: response.data };
  } catch (error) {
    const { data = {} } = error.response || {};
    return { data: { status: false, error: data.error || 'error' } };
  }
}
export async function downloadChartData(options = {}) {
  window.open(`${baseUrl}/v2/device-data/hourly?${joinQueryParam({ ...options, download: 'yes' })}`);
}

const saveData = (data, fileName) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';

  const blob = new Blob([data], { type: 'octet/stream' }),
    url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export async function downloadApi(endPoint = '', options = {}) {
  const makeUrl = `${baseUrl}/${endPoint}?${joinQueryParam({ ...options, download: 'yes' })}`;
  try {
    const response = await axios.get(makeUrl, { headers: credentials.getHeaders() });
    if (response.status) {
      const fileName = `report_${options.report_type || ''}.csv`;
      saveData(response.data, fileName);
    } else {
      throw Error({ error: { response: { data: { massage: 'custom-error' } } } });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getApiReturn(endPoint = '', options = {}) {
  const makeUrl = `${baseUrl}/${endPoint}?${joinQueryParam(options)}`;
  try {
    const response = await axios.get(makeUrl, { headers: credentials.getHeaders() });
    if (response.status) return { status: true, data: response.data };
    throw Error({ error: { massage: 'custom-error' } });
  } catch (error) {
    return { status: false, error: error.message };
  }
}
export async function postApiReturn(endPoint = '', postData = {}, options = {}) {
  const makeUrl = `${baseUrl}/${endPoint}?${joinQueryParam(options)}`;
  try {
    const response = await axios.post(makeUrl, postData, { headers: credentials.getHeaders() });
    if (response.status && response.data) return { status: true, data: response.data };
    throw Error({ error: { massage: 'custom-error' } });
  } catch (error) {
    return { status: false, error: error.message };
  }
}
