export default function Dot({ radius = 10, fill = '#000000' }) {
  const width = `${radius * 2}px`;
  const height = `${radius * 2}px`;
  const viewBox = `0 0 ${radius * 2} ${radius * 2}`;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <circle cx='50%' cy='50%' r={radius} fill={fill} />
    </svg>
  );
}
