import { createSlice } from '@reduxjs/toolkit';

const deviceModuleSlice = createSlice({
  name: 'deviceModule',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    deviceModuleInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceModuleSuccess: (state, action) => {
      return { ...state, response: { ...state.response, ...action.payload.data }, isLoading: false };
    },
    deviceModuleFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    deviceModuleDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    deviceModuleDelSuccess: (state, action) => {
      let delItems = action.payload?.data || [];
      let dataAfterDel = [...state.response.data]?.filter((item) => !delItems.includes(item.id));
      return {
        ...state,
        response: { ...state.response, data: dataAfterDel },
        isLoading: false,
      };
    },
    deviceModuleDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { deviceModuleInit, deviceModuleSuccess, deviceModuleFail, deviceModuleDelInit, deviceModuleDelSuccess, deviceModuleDelFail } = deviceModuleSlice.actions;
export default deviceModuleSlice.reducer;
