import PropTypes from 'prop-types';
import React from 'react';
import { getFormattedTime, parseDateTime } from '../../../../utils/utils';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as Explanation } from '../../../../assets/icons/exclamation.svg';
import PopUp from '../../popup/PopUp';

export default function DeviceCard({ item = {}, onClick = () => {} }) {
  return (
    <div className='w-full aspect-[1/.7] relative p-6 custom-background'>
      <div className='absolute right-3 top-3'>
        <PopUp positionClass='bottom-6 right-full' icon={<Explanation className='w-4 h-4 cursor-pointer' />} headerClass='ml-auto'>
          <p className='text-center w-[200px] p-2 text-sm'>
            The generator <span className='font-bold'>{item.pg_number}</span> is connected with device <span className='font-bold'>{item.device_id}</span>
          </p>
        </PopUp>
      </div>
      <div onClick={() => onClick(item)} className='cursor-pointer'>
        <div className='mb-4 mt-2'>
          <h3>Runtime</h3>
          <p className='relative px-4 py-2 text-center text-white rounded-md text-lg bg-primary-700'>
            {getFormattedTime(item.duration)}
            {item.running === 1 ? <span className='w-4 h-4 rounded-full absolute right-3 top-4 bg-secondary-500'></span> : <span className='w-4 h-4 rounded-full absolute right-3 top-4 bg-primary-400'></span>}
          </p>
        </div>

        <ul className='mb-4 opacity-70 text-sm'>
          <li className='flex items-center'>
            <ClockIcon className='w-5 h-5 mr-2' strokeWidth={1} />
            <span className='font-semibold mr-1'>Start Time: </span> {parseDateTime(item.start_time).time}
          </li>
          <li className='flex items-center'>
            <ClockIcon className='w-5 h-5 mr-2' strokeWidth={1} />
            <span className='font-semibold mr-1'>End Time: </span> {parseDateTime(item.end_time).time}
          </li>
        </ul>
        <ul className='mb-4 opacity-70 text-sm'>
          <h3>Generator Name:</h3>
          <p className='px-4 py-1 custom-border w-max'>{item.pg_number || ''}</p>
        </ul>
      </div>
    </div>
  );
}

DeviceCard.propTypes = {
  item: PropTypes.object,
};
