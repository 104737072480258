import React from 'react';
import { ReactComponent as PlusIcon } from './plusIcon.svg';

export default function AddButton({ icon = false, className = '', iconClass = '', onClick = () => {}, label = '' }) {
  return (
    <div onClick={onClick} className={`w-full h-full cursor-pointer flex items-center justify-center border rounded-lg custom-shadow border-primary-200 dark:border-primary-400 ${className}`}>
      {icon ? icon : <PlusIcon className={`w-5 h-5 ${iconClass}`} />} <span>{label}</span>
    </div>
  );
}
