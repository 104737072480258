import dayjs from 'dayjs';
import * as xlsx from 'xlsx';

export function isMatchWith(test1, text2) {
  return test1.toLowerCase().match(text2.toLowerCase());
}

export function fullName(firstName, lastName) {
  return firstName + ' ' + lastName;
}

export function setFormValueToEmpty(formData) {
  for (const key in formData) {
    formData[key].value = '';
  }
  return formData;
}

export function getUserType(id = 3) {
  if (id === 1) return 'super-admin';
  else if (id === 2) return 'customer-admin';
  return 'customer-user';
}

export function makeObjectAsGroupFromArray(arr = [], groupSize = 100) {
  const arrLength = arr.length;
  const numberOfArr = Math.ceil(arrLength / groupSize);

  let groupObj = {};
  for (let i = 0; i < numberOfArr; i++) {
    let startIndex = i * groupSize;
    let endIndex = startIndex + groupSize;
    let temp = arr.slice(startIndex, endIndex);
    groupObj[i] = temp;
  }

  return groupObj;
}

export function capitalize(str) {
  let newStr = str.slice(0, 1).toUpperCase() + str.slice(1);
  return newStr;
}

export function makeFormObjectFromArray(arr = [{ fieldName: '', required: true }]) {
  let obj = {};
  arr.forEach((e, i) => {
    obj[e.fieldName] = { value: e.value || '', required: e.required || false, displayKey: e.displayKey || null };
  });
  return obj;
}

export const makeHomeUrl = (user_type_id) => {
  if (user_type_id === 1) return '/super-admin';
  else if (user_type_id === 2) return '/customer-admin';
  else return '/customer-user';
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
export function parseDateTime(seconds, dateFormat = 'MMM D, YYYY', timeFormat = 'hh:mm a') {
  let tem = dayjs(seconds * 1000);
  if (dayjs(tem)['$d'] === 'Invalid Date') {
    return { date: false, time: false };
  }
  let parseDate = {
    date: dayjs(tem).format(dateFormat),
    time: dayjs(tem).format(timeFormat),
  };

  return parseDate;
}

export function timeConvert(timeInMimSec = null) {
  const currentTime = timeInMimSec || new Date().getTime();
  return {
    mmSecond: currentTime,
    second: Math.ceil(currentTime / 1000),
    hour: Math.ceil(currentTime / (1000 * 3600)),
  };
}

export function getTimeRangeByAgo(startTimeInSecAgo = null, endTimeInSecAgo = 0) {
  const nowTime = (new Date() / 1000) | 0;
  const end_time = nowTime - endTimeInSecAgo;
  const start_time = nowTime - (startTimeInSecAgo || nowTime);
  return { start_time, end_time };
}

export function getToday() {
  const t_now = new Date();
  return t_now.getTime() - t_now.setHours(0, 0, 0, 0);
}
export function getThisMonth() {
  const monthStart = new Date();
  monthStart.setDate(1);
  monthStart.setHours(0, 0, 0);
  return new Date().getTime() - monthStart.getTime();
}
export function currentQuadrant() {
  const month = new Date().getMonth();
  if ([0, 1, 2].includes(month)) return 1;
  else if ([3, 4, 5].includes(month)) return 2;
  else if ([6, 7, 8].includes(month)) return 3;
  else if ([9, 10, 11].includes(month)) return 4;
  else return 1;
}

export const getFormattedServiceHours = (second) => {
  let formatHour;
  if (second < 60) {
    formatHour = second + ' Seconds';
  } else {
    const seconds = second % 60;
    const minutes = Math.floor(second / 60);
    if (minutes < 60) {
      formatHour = minutes + ' min ' + seconds + ' sec';
    } else {
      const remainingMinutes = minutes % 60;
      const hours = Math.floor(minutes / 60);
      formatHour = hours + ' h ' + remainingMinutes + ' m ' + seconds + ' s';
    }
  }
  return formatHour;
};

export function fillByColor(index) {
  if (index === 0) return { color: '#0C9EE1', bgColor: '#D2E8F6' };
  else if (index === 1) return { color: '#A67EF8', bgColor: '#EFE8F9' };
  else if (index === 2) return { color: '#EFBC26', bgColor: '#FCF2D5' };
  return { color: '#7FA63C', bgColor: '#E8EBD6' };
}

export function getMaxFromArrayOfObject(arr = [], key) {
  let values = arr.map((item) => item[key]);
  return Math.max(...values);
}

export function getInActiveTimeToString(timeInSeconds) {
  const preTime = new Date(timeInSeconds * 1000);
  const currentTime = new Date();
  const year = currentTime.getFullYear() - preTime.getFullYear();
  if (year > 0) return `${year} yr ago`;
  const month = currentTime.getMonth() - preTime.getMonth();
  if (month > 0) return `${month} month ago`;
  const date = currentTime.getDate() - preTime.getDate();
  if (date > 0) return `${date} day ago`;
  const hours = currentTime.getHours() - preTime.getHours();
  if (hours > 0) return `${hours} hours ago`;

  const minutes = currentTime.getMinutes() - preTime.getMinutes();
  if (minutes > 0) return `${minutes} min ago`;

  const seconds = currentTime.getSeconds() - preTime.getSeconds();
  if (seconds > 0) return `${seconds} sec ago`;
}

export function makeScrollTo(ref, position: 'top') {
  if (position === 'bottom') {
    const { scrollHeight, clientHeight } = ref.current;
    ref.current.scrollTop = scrollHeight - clientHeight;
  } else {
    ref.current.scrollTop = 0;
  }
}

export function getTineString(value) {
  if (value < 10) return `0${value}`;
  else return value;
}

export const getFormattedTime = (second) => {
  let formatHour;
  if (second < 60) {
    formatHour = '00:00:' + getTineString(second);
  } else {
    const seconds = second % 60;
    const minutes = Math.floor(second / 60);
    if (minutes < 60) {
      formatHour = '00:' + getTineString(minutes) + ':' + getTineString(seconds);
    } else {
      const remainingMinutes = minutes % 60;
      const hours = Math.floor(minutes / 60);
      formatHour = getTineString(hours) + ':' + getTineString(remainingMinutes) + ':' + getTineString(seconds);
    }
  }
  return formatHour;
};

export function checkEmpty(data = '' || [] || {}) {
  let convertToStr;
  if (typeof data === 'number') {
    convertToStr = JSON.stringify(`${data}`);
  } else {
    convertToStr = JSON.stringify(data) || '';
  }
  return convertToStr.length > 2 ? false : true;
}

export function sumOf(...params) {
  let sum = 0;
  for (const param of params) {
    sum = sum + (param || 0);
  }
  return sum;
}

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export function arrayOfObjToObject(arr = [], key = 'name') {
  let obj = {};
  arr?.forEach((it) => {
    obj[it[key]] = it;
  });
  return obj;
}

export function arrayToObject(arr = [], key = 'id') {
  const map = {};
  for (const item of arr) {
    map[item[key]] = item;
  }
  return map;
}

export async function xlsxFileReaderDynamicHeader(file) {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    const fn = () => {
      const data = reader.result;
      const workbook = xlsx.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_row_object_array(worksheet);
      return json;
    };

    reader.onload = () => resolve(fn());
    reader.onerror = () => reject(() => console.log('rejected'));
  });
  return promise;
}

export function getFullName(f_name = '', l_name = '') {
  return f_name[0].toUpperCase() + f_name.slice(1) + ' ' + l_name[0].toUpperCase() + l_name.slice(1);
}

export function returnTimeStamp(timeInSeconds) {
  const d = new Date(timeInSeconds * 1000);
  const formatObj = {
    hr: d.getHours() || '0',
    min: d.getMinutes() || '0',
    sec: d.getSeconds() || '0',
  };
  const str = `${formatObj.hr > 9 ? formatObj.hr : '0' + formatObj.hr}:${formatObj.min > 9 ? formatObj.min : '0' + formatObj.min}:${formatObj.sec > 9 ? formatObj.sec : '0' + formatObj.sec}`;
  return str;
}

export function parseTimeDuration(duration = 0, callback = null) {
  let hour = (duration / 3600) | 0;
  let min = ((duration - hour * 3600) / 60) | 0;
  let sec = (duration - (hour * 3600 + min * 60)) | 0;
  if (callback) {
    return callback({ hour, min, sec });
  } else return { hour, min, sec };
}
