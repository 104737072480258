import { createSlice } from '@reduxjs/toolkit';

const dashboardSummary = createSlice({
  name: 'dashboardSummary',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    dashboardSummaryInit: (state) => {
      return { ...state, isLoading: true };
    },
    dashboardSummarySuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    dashboardSummaryFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { dashboardSummaryInit, dashboardSummarySuccess, dashboardSummaryFail } = dashboardSummary.actions;
export default dashboardSummary.reducer;
