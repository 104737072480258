import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalize, getFormattedServiceHours } from '../../../../../utils/utils';
import './style.css';
import NoData from '../../../no-data/NoData';

export default function MonthlyChart({ data = [], options = {}, children, headerClass = '', className = '', onClick = () => {}, expandedList = [] }) {
  return (
    <div className='chart-container py-8 px-6 text-[8px] flex flex-col justify-end'>
      {data.length ? (
        <ResponsiveContainer width='100%' aspect={1 / 0.6}>
          <LineChart
            data={data?.map((it) => ({
              ...it,
              temp_run_hour: it.run_hour / 3600,
              temp_run_kwh: it.run_kwh,
              temp_fuel: it.fuel,
            }))}
            margin={{ top: 16, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} className='dark:stroke-[#f9f8f80d] stroke-[#ede6e65f]' strokeDasharray='0' />
            <XAxis dataKey='month' className='stroke-[rgba(0, 0, 0, 0.2)] dark:stroke-white' />
            {/* <YAxis dataKey='run_khw' domain={[0, 'dataMax+100']} /> */}
            {expandedList.includes(1) && <YAxis hide={!!!options.Y} dataKey='temp_run_hour' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} yAxisId='left' orientation='left' stroke='#ffc107' />}
            {expandedList.includes(2) && <YAxis hide={!!!options.Y} dataKey='temp_run_kwh' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} yAxisId='right' orientation='right' stroke='#17a2b8' />}
            {expandedList.includes(3) && <YAxis hide={!!!options.Y} dataKey='temp_fuel' domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} yAxisId='left-fuel' orientation='right' stroke='#008021' />}

            <Tooltip
              wrapperStyle={{ outline: 'none', border: 0 }}
              wrapperClassName='shadow-md'
              contentStyle={{ padding: '16px', border: 0, borderRadius: '4px', overflow: 'hidden' }}
              labelStyle={{ color: 'black', fontWeight: '500' }}
              formatter={formatter}
            />

            {expandedList.includes(1) && <Line yAxisId='left' type='monotone' dataKey='temp_run_hour' strokeWidth={2} stroke='#ffc107' dot={false} />}
            {expandedList.includes(2) && <Line yAxisId='right' type='monotone' dataKey='temp_run_kwh' strokeWidth={2} stroke='#17a2b8' dot={false} />}
            {expandedList.includes(3) && <Line yAxisId='left-fuel' type='monotone' dataKey='temp_fuel' strokeWidth={2} stroke='#AD83F6' dot={false} />}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </div>
  );
}

const formatter = (value, name, props) => {
  if (name === 'temp_run_hour') return [`${getFormattedServiceHours(props.payload.run_hour)}`, `Run hour`];
  if (name === 'temp_run_kwh') return [`${props.payload.run_kwh} kWh`, `Power consumed`];
  if (name === 'temp_fuel') {
    if (props.payload.fuel === 0) return [`${props.payload.expected_fuel_consumed} Ltr`, `Expected Fuel`];
    return [`${props.payload.fuel} Ltr`, `Fuel`];
  }

  return [`${value}`, `${capitalize(name).split('_').join(' ')}`];
};

export function returnYDataKey(id = 1) {
  if (id === 3) return 'temp_fuel';
  else if (id === 2) return 'temp_run_kwh';
  return 'temp_run_hour';
}

// const CustomizedLabel = (props) => {
//   const { x = 40, y = 100, fill = 'green', value = 'Run hour' } = props;
//   return (
//     <text x={x} y={y} dy={-4} fontSize='16' fontFamily='sans-serif' fill={fill} textAnchor='middle'>
//       {value}
//     </text>
//   );
// };

// const CustomTooltip = ({ active, payload, label }) => {
//   console.log(active, payload, payload.length);
//   if (active && payload && payload.length) {
//     return (
//       <div className='custom-tooltip'>
//         <p className='label'>{`${label} : ${payload[0].value}`}</p>
//         <p className='desc'>Anything you want can be displayed here.</p>
//       </div>
//     );
//   }

//   return null;
// };

// export const ToolTipContent = () => {
//   return (
//     <div className='custom-tooltip'>
//       <p className='label'>sdsd</p>
//       <p className='desc'>Anything you want can be displayed here.</p>
//     </div>
//   );
// };

// const data = [
//   {
//     id: 1,
//     fuel: 0.8,
//     standard: 1,
//   },
//   {
//     id: 2,
//     fuel: 1,
//     standard: 1,
//   },
//   {
//     id: 3,
//     fuel: 5,
//     standard: 1,
//   },
//   {
//     id: 4,
//     fuel: 1.5,
//     standard: 1,
//   },
// ];
