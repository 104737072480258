import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchFun, useUserInfo } from '../../../../../hooks/hooks';
import { SUPER_ADMIN, getCompanies } from '../../../../../apis/apis';
import { useSelector } from 'react-redux';
import { checkEmpty } from '../../../../../utils/utils';
import Suspender from '../../../../../components/common/suspender/Suspender';
import SelectFromOption from '../../../../../components/basic/SelectFromOption/SelectFromOption';
import Switch from '../../../../../components/basic/switch/Switch';
import LMap from '../../../../../components/common/leaflet-map/LMap';
import NoData from '../../../../../components/common/no-data/NoData';
import DeviceCardFuel from '../../../../../components/common/cards/device_cards/DeviceCardFuel';
import DeviceCard from '../../../../../components/common/cards/device_cards/DeviceCard';

export default function TotalGenerator({ updateQuery = () => {} }) {
  const { user_type, company } = useUserInfo();
  const navigate = useNavigate();
  const [selCompany, setSelCompany] = useState(user_type === SUPER_ADMIN ? {} : company);
  const companyList = useSelector((state) => state.companies);
  const { isLoading, isError, response } = useSelector((state) => state.dashboardSummary);
  const deviceList = response.data;
  useFetchFun(getCompanies);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (!checkEmpty(selCompany)) {
      updateQuery((prev) => ({ ...prev, company_id: selCompany.id }));
    }
  }, [selCompany, updateQuery]);

  return (
    <>
      <Suspender isError={isError} isLoading={isLoading}>
        <div className='mt-5 mb-2 w-full flex items-center'>
          {user_type === SUPER_ADMIN && (
            <div className='w-[150px] '>
              <SelectFromOption onClick={(op) => setSelCompany(op)} value={selCompany} options={companyList.response?.data || []} headerClass='header-class' placeholder='Select Company' />
            </div>
          )}

          <div className='flex items-center ml-auto'>
            <label>Map:</label>
            <Switch item={{ value: showMap }} keyName='value' onSwitch={(item) => setShowMap(item.value)} barRatio='w-2/5' className='ml-2 h-6 w-10' />
          </div>
        </div>
      </Suspender>
      {showMap ? (
        <LMap data={deviceList?.filter((item) => !!item.latitude && !!item.longitude)} />
      ) : (
        <div className='active-devices'>
          <div className='grid grid-flow-row grid-cols-12 gap-2'>
            {deviceList?.map((item, i) => (
              <div key={i} className='col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3'>
                {item.tank_or_generator === 2 ? <DeviceCardFuel item={item} onClick={(item) => navigate(`device-data/only_fuel/${item.pg_number}`)} /> : <DeviceCard item={item} onClick={(item) => navigate(`device-data/${item.pg_number}`)} />}
              </div>
            ))}
          </div>

          {deviceList?.length === 0 && !isLoading && <NoData />}
        </div>
      )}
    </>
  );
}
