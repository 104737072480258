import DayMonthChart from './day_month_chart_notifications/dependencies/DayMonthChart';
import RuntimeCalendar from './run_time_calendar/RuntimeCalendar';
import HomeSummary from './summaries/HomeSummary';
import HomeSummaryFuel from './summaries/HomeSummaryFuel';
import HomeSummaryPower from './summaries/HomeSummaryPower';
import DayMothChartNotificationsFuel from './day_month_chart_notifications/DayMonthChartNotificationsFuel';
import DayMothChartNotificationsPower from './day_month_chart_notifications/DayMonthChartNotificationsPower';
import DayMothChartNotifications from './day_month_chart_notifications/DayMonthChartNotifications';

export const home_sections = {
  home_summary_fuel: HomeSummaryFuel,
  home_summary_power: HomeSummaryPower,
  home_summary: HomeSummary,
  day_month_chart_notifications: DayMothChartNotifications,
  day_month_chart_notifications_fuel: DayMothChartNotificationsFuel,
  day_month_chart_notifications_power: DayMothChartNotificationsPower,
  runtime_calendar: RuntimeCalendar,
  day_month_chart: DayMonthChart,
};

// for future
// const sections = { summary_01:['col-1','çon-2'], summary_02:[],  day_month_notifications:[], runtime_calendar:[] };
