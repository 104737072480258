import DayMonthFuelEntryChart from './day_month_fuel_entry_chart/DayMonthFuelEntryChart';
import DayMonthFuelEntryChartFuel from './day_month_fuel_entry_chart/DayMonthFuelEntryChartFuel';
import DayMonthFuelEntryChartPower from './day_month_fuel_entry_chart/DayMonthFuelEntryChartPower';
import ParameterTabs from './parameter-tabs/ParameterTabs';
import RuntimeFuelConsumedHourly from './run_time_fuel_consumed_hourly/RuntimeFuelConsumedHourly';
import DetailSummary from './summaries/DetailSummary';
import DetailSummaryFuel from './summaries/DetailSummaryFuel';
import DetailSummaryPower from './summaries/DetailSummaryPower';

export const detail_sections = {
  parameter_tabs: ParameterTabs,
  detail_summary_fuel: DetailSummaryFuel,
  detail_summary_power: DetailSummaryPower,
  detail_summary: DetailSummary,
  day_month_fuel_entry_chart: DayMonthFuelEntryChart,
  day_month_fuel_entry_chart_fuel: DayMonthFuelEntryChartFuel,
  day_month_fuel_entry_chart_power: DayMonthFuelEntryChartPower,
  runtime_fuel_consumed_hourly: RuntimeFuelConsumedHourly,
};

// for future
// const sections = { detail_summary_01:['col-1','çon-2'], detail_summary_02:[],  day_month_notifications:[], runtime_calendar:[] };
