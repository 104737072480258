import React from 'react';
import RenderTab from '../render-tab/RenderTab';
import './Sidebar.css';

export default function Sidebar() {
  return (
    <div className='sidebar custom-scroll max-h-[70vh]'>
      <RenderTab />
    </div>
  );
}
