import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Notification from '../components/basic/notification/Notification';
import HomeTile from '../components/common/home-title/HomeTile';
import Navbar from '../components/common/navbar/Navbar';
import Sidebar from '../components/common/side-bar/Sidebar';
import { FuelAlarmCardNotify } from '../components/fuel-alerts/FuelAlarmCardNotify';
import './layout.css';
import { setThemes } from '../themeSetter';
import { useDimension } from '../hooks/hooks';
import Star from '../components/common/star';

export default function Layout() {
  const [expand, setExpand] = useState(true);

  const { x } = useDimension();

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') document.documentElement.classList.add('dark');
    if (window.screen.width < 1024) {
      setExpand(false);
    }
  }, []);

  useEffect(() => {
    setThemes('theme_jti');
  }, []);

  const handleOutsideClick = () => {
    if (x < 1024 && expand) {
      setExpand(false);
    }
  };

  return (
    <>
      <div className='layout lg:container mx-auto'>
        <Notification view={(props) => <FuelAlarmCardNotify {...props} />} />
        <Navbar sideBarHandle={() => setExpand(!expand)} />
        <div className='main-section'>
          <div className={`left-part absolute visible lg:relative  ${expand ? 'ml-0 lg:opacity-100' : '-ml-[250px] lg:opacity-0'}`}>
            <div className='sticky top-0'>
              <Sidebar />
            </div>
          </div>
          <div onClick={handleOutsideClick} className={`right-part flex-1 custom-scroll`}>
            <div className='pb-4'>
              <HomeTile>
                <p className='ml-2 text-md'>Dashboard</p>
              </HomeTile>
            </div>
            {<Outlet />}
          </div>
        </div>
        <div className='py-4 bg-primary-400 dark:bg-primary-900 w-full max-auto flex justify-center text-primary-900 dark:text-primary-50'>
          <Star className='!m-0' position='-left-4 top-1/3' /> <span>Approximate value</span>
        </div>
      </div>
    </>
  );
}
