import { createSlice } from '@reduxjs/toolkit';

const towerSitesSlice = createSlice({
  name: 'towerSites',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    towerSitesInit: (state) => {
      return { ...state, isLoading: true };
    },
    towerSitesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    towerSitesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    towerSitesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    towerSitesDelSuccess: (state, action) => {
      console.log(action.payload, state.response);
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    towerSitesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { towerSitesInit, towerSitesSuccess, towerSitesFail, towerSitesDelInit, towerSitesDelSuccess, towerSitesDelFail } = towerSitesSlice.actions;
export default towerSitesSlice.reducer;
