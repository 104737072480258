import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { deleteTenantCompany, getCompanies, getTenantCompanies, getTenants } from '../../apis/apis';
import Modal from '../../components/basic/Modals/Modal';
import SelectFromOption from '../../components/basic/SelectFromOption/SelectFromOption';
import Button from '../../components/basic/button';
import { makeSubmittableData } from '../../components/common/forms/makeSubmittableData';
import ModalDelConfirmation from '../../components/common/modal-del-confirmation';
import NoData from '../../components/common/no-data/NoData';
import { PaginatedItems } from '../../components/common/pagination/Paginate';
import Suspender from '../../components/common/suspender/Suspender';
import { useFetchFun } from '../../hooks/hooks';
import { checkEmpty, makeFormObjectFromArray } from '../../utils/utils';
import './style.css';
import AddButton from '../../components/common/cards/add-button/AddButton';
import { postApi } from '../../apis/methods';

export default function TenantCompany() {
  const tenants = useSelector((state) => state.tenants);
  const companies = useSelector((state) => state.companies);
  const [selTenant, setSelTenant] = useState({});
  const [selCompany, setSelCompany] = useState({});
  useFetchFun(getTenants);
  useFetchFun(getCompanies);

  const initialState = makeFormObjectFromArray([
    { fieldName: 'tenant_id', required: true },
    { fieldName: 'company_id', required: true },
  ]);
  const { isLoading, isError, response } = useSelector((state) => state.tenantCompanies);
  const [queryObj, updateQueryObject] = useFetchFun(getTenantCompanies, { page: 0, per_page: 20 });
  const [modalItem, setModalItem] = useState(null);
  const [modalFormCreate, setModalFormCreate] = useState(null);

  const [formData, updateFormData] = useState(initialState);

  const resetter = () => {
    updateFormData(initialState);
    setSelTenant({});
    setSelCompany({});
    getTenantCompanies(queryObj);
    setModalFormCreate(false);
  };

  const handleConfirm = () => {
    deleteTenantCompany([modalItem.id]);
    setModalItem(null);
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      await postApi({ url: 'tenant-company/create', body: data.data, resolve: resetter });
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    if (!checkEmpty(selTenant)) {
      updateFormData((prev) => ({ ...prev, tenant_id: { value: selTenant.id, error: false } }));
    }
  }, [selTenant]);

  useEffect(() => {
    if (!checkEmpty(selCompany)) {
      updateFormData((prev) => ({ ...prev, company_id: { value: selCompany.id, error: false } }));
    }
  }, [selCompany]);

  return (
    <>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <ModalDelConfirmation onCancel={() => setModalItem(null)} onConfirm={handleConfirm} />
        </Modal>
      )}
      {modalFormCreate && (
        <Modal onClose={() => setModalFormCreate(null)}>
          <div className='md:w-[400px] p-4'>
            <h2 className=''>Add Tenant Company</h2>
            <div className='my-3'>
              <label>Select Tenant:</label>
              <SelectFromOption
                onClick={(op) => setSelTenant(op)}
                displayKey='name'
                keyValue='id'
                value={selTenant}
                options={tenants.response?.data || []}
                headerClass='custom-border h-10 text-[13px]'
                placeholder='Select Tenant'
                fieldError={formData.tenant_id?.error || false}
                readOnly={false}
              />
            </div>
            <div className='my-3'>
              <label>Select Company:</label>
              <SelectFromOption
                onClick={(op) => setSelCompany(op)}
                value={selCompany}
                displayKey='name'
                keyValue='id'
                options={companies.response?.data || []}
                headerClass='custom-border h-10 text-[13px]'
                placeholder='Select Company'
                fieldError={formData.company_id?.error || false}
              />
            </div>
            <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Save' />
          </div>
        </Modal>
      )}
      <div className='mb-2 w-28 h-8 ml-auto'>
        <AddButton label='Add New' onClick={() => setModalFormCreate(true)} />
      </div>
      <Suspender isLoading={isLoading} isError={isError}>
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th className='text-start'>
                  <pre>Tenant Name</pre>
                </th>
                <th className='text-start'>
                  <pre>Company Name</pre>
                </th>
                <th>
                  <pre>Action</pre>
                </th>
              </tr>
            </thead>
            <tbody>
              {response.data?.map((item) => (
                <tr key={item.id}>
                  <td>{item.name || '-'}</td>
                  <td>{item.company?.name || '-'}</td>
                  <td>
                    <div className='flex items-center justify-center gap-1 h-8'>
                      <h4 onClick={() => setModalItem(item)} className='w-12 h-full btn-delete'>
                        delete
                      </h4>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {response.data?.length === 0 && !isLoading && <NoData />}
        </div>
      </Suspender>
      <div className='pagination-wrapper'>
        <PaginatedItems itemsPerPage={queryObj.per_page} onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })} totalItems={response.pagination?.total} />
      </div>
    </>
  );
}
