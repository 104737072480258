const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV;

function getTokens() {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('token');
  return { token, refreshToken };
}

function setTokens(option = {}) {
  for (const key in option) {
    localStorage.setItem(key, option[key]);
  }
}

function getHeaders() {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${getTokens().token}`,
  };
  return headers;
}

module.exports = { baseUrl, getHeaders, getTokens, setTokens };
