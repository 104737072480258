import { ReactComponent as CreateCompany } from '../../../assets/icons/createCompany.svg';
import { ReactComponent as CreateUser } from '../../../assets/icons/createUser.svg';
import { ReactComponent as DesktopIcon } from '../../../assets/icons/desktopIcon.svg';
import { ReactComponent as DeviceIcon } from '../../../assets/icons/deviceIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Open } from '../../../assets/icons/open.svg';
import { ReactComponent as Setting } from '../../../assets/icons/setting.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/supportIcon.svg';

import { CUSTOMER_ADMIN, SUPER_ADMIN } from '../../../apis/apis';

export const tabListForSuperAdmin = [
  {
    id: 1,
    label: 'Menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Report Generate', link: 'dashboard/reports' },
          { id: 3, label: 'Event Stats', link: 'dashboard/event-stats' },
          // { id: 3, label: 'Schedule', link: 'schedule' },
        ],
      },
      {
        id: 2,
        label: 'User Management',
        icon: <CreateUser />,
        subItem: [
          {
            id: 1,
            label: 'Create User',
            link: 'user/create',
          },
          {
            id: 2,
            label: 'User List',
            link: 'user/list',
          },
        ],
      },
      {
        id: 3,
        label: 'Company Management',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Create Company',
            link: 'company/create',
          },
          {
            id: 2,
            label: 'Company List',
            link: 'company/list',
          },
          {
            id: 3,
            label: 'Tenants',
            link: 'tenant/list',
          },
          {
            id: 4,
            label: 'Tenants Company',
            link: 'tenant/company',
          },
        ],
      },
      {
        id: 4,
        label: 'Device Management',
        icon: <DeviceIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Device',
            link: 'device/add',
          },

          {
            id: 2,
            label: 'Device List',
            link: 'device/list',
          },
          {
            id: 3,
            label: 'Upload Devices',
            link: 'device/file-upload',
          },
        ],
      },
      {
        id: 5,
        label: 'Generator Management',
        icon: <DesktopIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Generator',
            link: 'generator/add',
          },

          {
            id: 2,
            label: 'Generator List',
            link: 'generator/list',
          },
          {
            id: 3,
            label: 'Upload Generators',
            link: 'generator/file-upload',
          },
          {
            id: 4,
            label: 'Generator Attach',
            link: 'generator/attach',
          },
        ],
      },
      {
        id: 6,
        label: 'Generator Model',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Model List',
            link: 'generator/model',
          },
          {
            id: 2,
            label: 'Fuel Tank Dimension',
            link: 'fuel-tank-dimensions',
          },

          {
            id: 3,
            label: 'Company List',
            link: 'generator/company',
          },
        ],
      },
      {
        id: 7,
        label: 'Device Modules',
        icon: <CreateCompany />,
        subItem: [
          { id: 1, label: 'Modules', link: 'device/modules' },
          // { id: 2, label: 'Sensor devices', link: 'device/sensors' },
          // { id: 3, label: 'Add Module To Generator', link: 'module/add-to-generator' },
        ],
      },
      {
        id: 8,
        label: 'Extras',
        icon: <Open />,
        subItem: [
          {
            id: 1,
            label: 'Fuels',
            link: 'fuel/list',
          },
          {
            id: 2,
            label: 'Services',
            link: 'service/list',
          },
        ],
      },
      {
        id: 9,
        label: 'Features Management',
        icon: <SupportIcon />,
        subItem: [
          {
            id: 1,
            label: 'Features',
            link: 'features/all',
          },
          {
            id: 2,
            label: 'User Features',
            link: 'features/user',
          },
          {
            id: 3,
            label: 'User Type Features',
            link: 'features/user-type',
          },
        ],
      },
      {
        id: 10,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          // {
          //   id: 1,
          //   label: 'Chat',
          //   link: 'chat/board',
          // },

          // {
          //   id: 2,
          //   label: 'Tickets',
          //   link: 'ticket/board',
          // },
          {
            id: 3,
            label: 'Contact Us',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  // { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Settings',
    icon: <Setting />,
    // subItem: [
    //   {
    //     id: 2,
    //     label: 'Fault Type Management',
    //     icon: <ErrorIcon />,
    //     subItem: [
    //       {
    //         id: 1,
    //         label: 'Add Fault Type',
    //         link: 'fault-type/add',
    //       },
    //       // {
    //       //   id: 2,
    //       //   label: 'Fault Type List',
    //       //   link: 'fault-type/list',
    //       // },
    //     ],
    //   },
    // ],
  },
];

export const tabListForCustomerAdmin = [
  {
    id: 1,
    label: 'Menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Event Stats', link: 'dashboard/event-stats' },
          { id: 3, label: 'Report Generate', link: 'dashboard/reports' },
        ],
      },
      {
        id: 2,
        label: 'User Management',
        icon: <CreateUser />,
        subItem: [
          {
            id: 1,
            label: 'Create User',
            link: 'user/create',
          },
          {
            id: 2,
            label: 'User List',
            link: 'user/list',
          },
        ],
      },
      {
        id: 4,
        label: 'Device Management',
        icon: <DeviceIcon />,
        subItem: [
          {
            id: 1,
            label: 'Device List',
            link: 'device/list',
          },
        ],
      },
      {
        id: 5,
        label: 'Generator Management',
        icon: <DesktopIcon />,
        subItem: [
          // {
          //   id: 1,
          //   label: 'Add Generator',
          //   link: 'generator/add',
          // },

          {
            id: 2,
            label: 'Generator List',
            link: 'generator/list',
          },
          // {
          //   id: 3,
          //   label: 'Upload Generators',
          //   link: 'generator/file-upload',
          // },
          // {
          //   id: 4,
          //   label: 'Generator Attach',
          //   link: 'generator/attach',
          // },
        ],
      },
      {
        id: 8,
        label: 'Extras',
        icon: <Open />,
        subItem: [
          {
            id: 1,
            label: 'Fuels',
            link: 'fuel/list',
          },
          {
            id: 2,
            label: 'Services',
            link: 'service/list',
          },
        ],
      },
      {
        id: 10,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          // {
          //   id: 1,
          //   label: 'Chat',
          //   link: 'chat/board',
          // },

          // {
          //   id: 2,
          //   label: 'Tickets',
          //   link: 'ticket/board',
          // },
          {
            id: 3,
            label: 'Contact Us ',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  // { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Setting',
    icon: <Setting />,
    subItem: [
      {
        id: 2,
        label: 'Fault Type Management',
        icon: <ErrorIcon />,
        // subItem: [
        //   {
        //     id: 1,
        //     label: 'Add Fault Type',
        //     link: 'fault-type/add',
        //   },
        //   // {
        //   //   id: 2,
        //   //   label: 'Fault Type List',
        //   //   link: 'fault-type/list',
        //   // },
        // ],
      },
    ],
  },
];

export const tabListForCustomerUser = [
  {
    id: 1,
    label: 'Menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Event Stats', link: 'dashboard/event-stats' },
          { id: 3, label: 'Report Generate', link: 'dashboard/reports' },
        ],
      },

      {
        id: 5,
        label: 'Generator Management',
        icon: <DesktopIcon />,
        subItem: [
          {
            id: 1,
            label: 'Generator List',
            link: 'generator/list',
          },
          // {
          //   id: 2,
          //   label: 'Generator Attach',
          //   link: 'generator/attach',
          // },
        ],
      },
      {
        id: 8,
        label: 'Extras',
        icon: <Open />,
        subItem: [
          {
            id: 1,
            label: 'Fuels',
            link: 'fuel/list',
          },
          {
            id: 2,
            label: 'Services',
            link: 'service/list',
          },
        ],
      },
      {
        id: 10,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          // {
          //   id: 1,
          //   label: 'Chat',
          //   link: 'chat/board',
          // },

          // {
          //   id: 2,
          //   label: 'Tickets',
          //   link: 'ticket/board',
          // },
          {
            id: 3,
            label: 'Contact Us',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  // { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Settings',
    icon: <Setting />,
    // subItem: [
    //   {
    //     id: 2,
    //     label: 'Fault Type Management',
    //     icon: <ErrorIcon />,
    //     subItem: [
    //       {
    //         id: 1,
    //         label: 'Add Fault Type',
    //         link: 'fault-type/add',
    //       },
    //       // {
    //       //   id: 2,
    //       //   label: 'Fault Type List',
    //       //   link: 'fault-type/list',
    //       // },
    //     ],
    //   },
    // ],
  },
];

export function getSidebarData(user_type = 3) {
  if (user_type === SUPER_ADMIN) return tabListForSuperAdmin;
  else if (user_type === CUSTOMER_ADMIN) return tabListForCustomerAdmin;
  else return tabListForCustomerUser;
}

// function makeLabel(ar=[], comp={}){
//   let finalArr=[]
// ar.forEach((it1)=>{
//   if(comp[labelToL(it1.label)]){

//   }
// })
// }

// function makeLabel(arr = []) {
//   let map = new Map()
//   arr.forEach((it) => {
//     let l1 = `${labelToL(it.label)}`;
//     map.set(l1,it);

//     if (it.subItem) {
//       it.subItem.forEach((it2) => {
//         let l2 = `${labelToL(it2.label)}`;
//         map.set(`${l1 + '_' + l2}`, [...it,...it2]);
//         if (it2.subItem) {
//           it2.subItem.forEach((it3) => {
//             let l3 = `${labelToL(it3.label)}`;
//             map.set(`${l1 + '_' + l2 + '_' + l3}`,[it]);
//           });
//         }
//       });
//     }
//   });
//   return map;
// }

export function labelToL(lv = '') {
  return lv.split(' ').join('_').toLowerCase();
}
