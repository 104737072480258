import { createSlice } from '@reduxjs/toolkit';

const tenantCompanySlice = createSlice({
  name: 'tenantCompany',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    tenantCompanyInit: (state) => {
      return { ...state, isLoading: true };
    },
    tenantCompanySuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    tenantCompanyFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    tenantCompanyDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    tenantCompanyDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    tenantCompanyDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { tenantCompanyInit, tenantCompanySuccess, tenantCompanyFail, tenantCompanyDelInit, tenantCompanyDelSuccess, tenantCompanyDelFail } = tenantCompanySlice.actions;
export default tenantCompanySlice.reducer;
