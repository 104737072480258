import React from 'react';
import { Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalize, getFormattedServiceHours } from '../../../../../utils/utils';
import NoData from '../../../no-data/NoData';

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload, style = {} } = props;

  return (
    <g transform={`translate(${x},${y})`} style={{ ...style }}>
      <text x={0} y={0} dy={16} textAnchor='end' fill='#666' transform='rotate(-60)'>
        {payload.value}
      </text>
    </g>
  );
};

function CustomLineChart({ chartData = [], xKey = 'date', yKey = 'fuel_amount', intervalX = 0, intervalY = 0, yLabel = 'Fuel Amount (Ltr)', xTickStyle = { fontSize: '9px' } }) {
  return (
    <div className='w-full overflow-auto'>
      {chartData.length ? (
        <ResponsiveContainer width={900} aspect={1 / 0.4} className='mx-auto text-xs pt-6 pb-2 px-6 !min-w-[260px] min-h-[200px] '>
          <LineChart
            width='100%'
            data={chartData}
            barGap={0}
            barSize={1}
            barCategoryGap={2}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis dataKey={xKey} interval={intervalX} height={70} tick={(props) => <CustomizedAxisTick {...props} style={xTickStyle} />} />

            <YAxis interval={intervalY} yAxisId={yKey} dataKey={yKey} orientation='left' domain={[0, (dataMax) => Math.ceil(dataMax * 1.5)]}>
              <Label value={yLabel} angle={-90} className='stroke-[#38353552] dark:stroke-[#ffffffad] ' fontSize={10} />
            </YAxis>

            <Tooltip wrapperStyle={{ outline: 'none', border: 0 }} wrapperClassName='shadow-md' labelStyle={{ color: 'black', fontWeight: '500' }} formatter={formatter} />
            <Line yAxisId={yKey} dataKey={yKey} type='linear' stroke='#51a9c9' strokeWidth={2} dot={false} />

            {/* <Legend /> */}
            {/* <Bar width='5px' dataKey='run_hour' fill='#ffc107' />
            <Bar width='10px' dataKey='fuel' fill='#008021' /> */}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoData />
      )}
    </div>
  );
}
export default React.memo(CustomLineChart);

const formatter = (value, name, props) => {
  if (name === 'temp_run_hour') return [`${getFormattedServiceHours(props.payload.run_hour)}`, `Run hour`];
  if (name === 'temp_run_kwh') return [`${props.payload.run_kwh} kWh`, `Power consumed`];
  if (name === 'fuel_amount') return [`${props.payload.fuel_amount} Ltr`, `Fuel Amount`];
  if (name === 'withdraw_fuel_amount') return [`${props.payload.withdraw_fuel_amount} Ltr`, `Fuel Amount`];
  if (name === 'expected_fuel') return [`${props.payload.expected_fuel} Ltr`, `Expected Fuel Consumed`];
  if (name === 'fuel_level') return [`${props.payload.fuel_level} Ltr`, `Fuel Level`];
  return [`${value}`, `${capitalize(name).split('_').join(' ')}`];
};
