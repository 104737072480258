import { detail_sections } from '../pages/device/device-details/sections';
import { home_sections } from '../pages/home/sections';
import { report_generate_sections } from '../pages/reports/sections/index';
import { event_stats_sections } from '../pages/event-stats/sections/index';
import FuelConsumedDateChart from './FuelConsumedDateChart';
import FuelFillDateChart from './FuelFillDateChart';
import FuelLevelDateChart from './FuelLevelDateChart';
import LogsData from './LogsData';

const home_page = home_sections;
const detail_page = detail_sections;
const NameError = ({ field = '' }) => (
  <h2 className='text-center text-[gray] '>
    <span className='text-2xl'>{`"${field}"`}</span> Component Name Invalid
  </h2>
);

const common_page = {
  fuel_consumed_date_chart: FuelConsumedDateChart,
  fuel_level_date_chart: FuelLevelDateChart,
  fuel_fill_date_chart: FuelFillDateChart,
  logs_data: LogsData,
};
const sections = { ...home_page, ...detail_page, ...common_page, ...report_generate_sections, ...event_stats_sections };

export default function GetSection({ compName = '' }) {
  const err = () => <NameError field={compName} />;
  const Comp = sections[compName] || err;
  return <>{Comp()}</>;
}

// for future
// const sections = { summary_01:['col-1','çon-2'], summary_02:[],  day_month_notifications:[], runtime_calendar:[] };
