import { createSlice } from '@reduxjs/toolkit';

const userFeaturesSlice = createSlice({
  name: 'userFeatures',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    userFeaturesInit: (state) => {
      return { ...state, isLoading: true };
    },
    userFeaturesSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    userFeaturesFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    userFeaturesDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    userFeaturesDelSuccess: (state, action) => {
      return {
        ...state,
        response: { ...state.response, data: state.response.data.filter((item) => !action.payload.data.includes(item.id)) },
        isLoading: false,
      };
    },
    userFeaturesDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { userFeaturesInit, userFeaturesSuccess, userFeaturesFail, userFeaturesDelInit, userFeaturesDelSuccess, userFeaturesDelFail } = userFeaturesSlice.actions;
export default userFeaturesSlice.reducer;
