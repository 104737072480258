import { useEffect, useState } from 'react';
import { useFetchFun, useUserInfo } from '../hooks/hooks';
import { USER_VIEWS_ONLY, downloadApi, getDeviceLogs } from '../apis/apis';
import { useSelector } from 'react-redux';
import { checkEmpty, parseDateTime, timeConvert } from '../utils/utils';
import Modal from '../components/basic/Modals/Modal';
import InputDateRange from '../components/basic/input-date-range/InputDateRange';
import DownloadButton from '../components/common/download/DownloadButton';
import { optionsWithCustomInput } from '../dataset';
import SelectFromOption from '../components/basic/SelectFromOption/SelectFromOption';
import Suspender from '../components/common/suspender/Suspender';
import PopUp from '../components/common/popup/PopUp';
import NoData from '../components/common/no-data/NoData';
import { PaginatedItems } from '../components/common/pagination/Paginate';
import { useParams } from 'react-router-dom';

export default function LogsData() {
  const params = useParams();
  const { pg_number = null, device_id = null } = params;

  const [query, updateQuery] = useFetchFun(getDeviceLogs, { page: 0, per_page: 20, pg_number: pg_number });
  const [selTime, setSelTime] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const { user_type = USER_VIEWS_ONLY } = useUserInfo();

  const {
    isLoading,
    isError,
    response: { data: logs, pagination = {} },
  } = useSelector((state) => state.deviceLogs);

  useEffect(() => {
    if (selTime.value === 'custom-input') {
      setModalShow(true);
      setSelTime({});
    } else if (selTime.value) {
      const endTime = timeConvert().second;
      const startTime = endTime - selTime.value;
      updateQuery((prev) => ({ ...prev, start_time: startTime, end_time: endTime }));
    }
  }, [selTime, updateQuery]);

  const applyFilter = ({ status = true, data: dateData }) => {
    const { start_time = '', end_time = '' } = dateData;
    setModalShow(false);
    const endTime = new Date(end_time).getTime() / 1000;
    const startTime = new Date(start_time).getTime() / 1000;
    updateQuery({ ...query, start_time: startTime, end_time: endTime });
  };

  return (
    <div className='logs-data'>
      {modalShow && (
        <Modal onClose={() => setModalShow(false)}>
          <div className='mx-auto w-max p-4 rounded-md '>
            <InputDateRange action={applyFilter} />
          </div>
        </Modal>
      )}
      <div className='mb-4 device-info max-w-lg text-start'>
        <div className='custom-border p-4'>
          <h3>
            Generator Number: <span className='text-sm opacity-80'>{pg_number || ''}</span>
          </h3>
          <h3>
            Device Id: <span className='text-sm opacity-80'>{device_id || ''}</span>
          </h3>
        </div>
      </div>
      <div className='mb-2 flex items-center gap-4'>
        <h2 className='flex-1'>Device Logs</h2>
        {user_type !== USER_VIEWS_ONLY && <DownloadButton action={() => downloadApi('v2/device-data', query)} />}
        <div className='w-[130px]'>
          <SelectFromOption headerClass='header-class h-8' options={optionsWithCustomInput} value={selTime} displayKey='title' onClick={(item) => setSelTime(item)} placeholder='Filter' />
        </div>
      </div>
      <Suspender isError={isError} isLoading={isLoading}>
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>
                  Time <br />
                  Date
                </th>
                <th>
                  Duration <br /> (hours)
                </th>
                <th>
                  Voltage (V)
                  <p className='font-normal'>(l1,l2,l3)</p>
                </th>
                <th>
                  Current (A)
                  <p className='font-normal'>(i1,i2,i3)</p>
                </th>
                <th>
                  Power meter <br /> (kWh)
                </th>
                <th>
                  Power <br /> (kW)
                </th>
                <th>Power Factor</th>
                <th>
                  Fuel meter <br /> (L)
                </th>
                <th>
                  Frequency <br /> (Hz)
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {logs?.map((item) => (
                <tr key={item.id}>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.ts).time || '-'}</pre>
                    <pre className='font-semibold'>{parseDateTime(item.ts).date || '-'}</pre>
                  </td>
                  <td>{item.hours_run || '-'}</td>
                  <td>
                    {item?.l1_n || '0'}, {item.l2_n || '0'}, {item.l3_n || '0'}
                  </td>
                  <td>
                    {item?.i1 || '0'}, {item.i2 || '0'}, {item.i3 || '0'},
                  </td>
                  <td>{item.p_kwh || '-'}</td>
                  <td>{item.p_kw || '-'}</td>
                  <td>{item.pf || '-'}</td>
                  <td>{item.fuel_level_ltr || '-'}</td>
                  <td>{item.hz || '0'}</td>
                  <td>
                    <PopUp>
                      <KeyValueWrapper
                        caption='User Info:'
                        item={JSON.parse(item.details_values)?.ownerInfo}
                        headers={[
                          { key: 'device_id', label: 'Device ID', unit: '' },
                          { key: 'pg_number', label: 'Generator Number', unit: '' },
                          { key: 'company_id', label: 'Company ID', unit: '' },
                          { key: 'user_id', label: 'User ID', unit: '' },
                        ]}
                      />
                      <KeyValueWrapper
                        caption='Device Info:'
                        item={JSON.parse(item.details_values)}
                        headers={[
                          { key: 'd_id', label: 'Device ID', unit: '' },
                          { key: 'd_type', label: 'Device Type', unit: '' },
                          { key: 'num_of_starts', label: 'Number Of Starts', unit: '' },
                          { key: 'active', label: 'Online', unit: '' },
                        ]}
                      />
                    </PopUp>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {logs?.length === 0 && !isLoading && <NoData />}
        </div>
      </Suspender>
      <div className='pagination-wrapper'>
        <PaginatedItems itemsPerPage={query.per_page} onClick={(parm) => updateQuery({ ...query, page: parm })} totalItems={pagination?.total} />
      </div>
    </div>
  );
}

function KeyValueWrapper({ item = {}, headers = [], caption = '' }) {
  if (checkEmpty(item)) return null;
  return (
    <ul className='text-start p-4'>
      <header className='mb-2 font-extrabold'>{caption}</header>
      {headers?.map((header, i) => {
        const { key, label } = header;
        return (
          item[key] && (
            <li key={i} className='font-light'>
              <span className='mr-2 font-medium'>{label}:</span> {item[key]}
            </li>
          )
        );
      })}
    </ul>
  );
}
