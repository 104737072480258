import { createSlice } from '@reduxjs/toolkit';

const userPgsSlice = createSlice({
  name: 'userPgs',
  initialState: { isLoading: true, response: { data: [], pagination: {} }, isError: false },
  reducers: {
    userPgsInit: (state) => {
      return { ...state, isLoading: true };
    },
    userPgsSuccess: (state, action) => {
      return { ...state, response: action.payload.data, isLoading: false };
    },
    userPgsFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },

    userPgsDelInit: (state) => {
      return { ...state, isLoading: true };
    },
    userPgsDelSuccess: (state, action) => {
      return {
        ...state,
        response: state.response.data.filter((item) => action.payload.data.pg_number !== item.pg_number),
        isLoading: false,
      };
    },
    userPgsDelFail: (state, action) => {
      return { ...state, isLoading: false, isError: action.payload.data };
    },
  },
});

export const { userPgsInit, userPgsSuccess, userPgsFail, userPgsDelInit, userPgsDelSuccess, userPgsDelFail } = userPgsSlice.actions;
export default userPgsSlice.reducer;
