import React, { useState } from 'react';
import { userFormUpdate } from '../../../apis/apis';
import Button from '../../../components/basic/button/Button';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { validation } from '../../../components/common/forms/validator';
import Input from '../../../components/basic/Input/Input';
import { useUserInfo } from '../../../hooks/hooks';

export default function UserProfileUpdate() {
  const { first_name, last_name, description } = useUserInfo();
  const [formData, updateFormData] = useState({
    first_name: { value: first_name || '', required: true },
    last_name: { value: last_name || '' },
    description: { value: description || '' },
  });

  //   const navigate = useNavigate();

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      const response = await userFormUpdate(data.data);
      if (response.data.status === true) {
        alert(`${response.data.message}`);
      } else alert(`${response.data.data.error}`);
    } else {
      updateFormData({ ...data.data });
    }
  };

  //for dropdown to catch changed value

  return (
    <div className='user-update form-wrapper'>
      <div className='mb-5'>
        <Input name='first_name' value={formData.first_name.value} onChange={handleChange} validation={validation.name} placeholder='Your First Name' className='custom-input' type='text' fieldError={formData.first_name?.error || false} />
      </div>
      <div className='mb-5'>
        <Input name='last_name' value={formData.last_name.value} onChange={handleChange} placeholder='Your Last Name' className='custom-input' type='text' />
      </div>

      <div className='mb-5'>
        <Input name='description' value={formData.description.value} onChange={handleChange} placeholder='Description' className='custom-input' type='text' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass btn-normal h-10' title='Update' />
      </div>
    </div>
  );
}
